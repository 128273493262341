import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forgotPassword } from '../services/services';
import { ForgotPasswordInterface } from '../models/forgotPasswordInterface';

interface LoginHook {
  handleSubmit: (data: ForgotPasswordInterface) => void;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const useForgotPassword = (): LoginHook => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(
    async (data: ForgotPasswordInterface) => {
      try {
        setLoading(true);
        setError(null);
        await forgotPassword(data);
        setLoading(false);
        setSuccess(true);
      } catch (error) {
        setLoading(false);
        setError(t('messages.errorTryLater'));
      }
    },
    [t],
  );

  return {
    handleSubmit,
    loading,
    error,
    success,
  };
};

export default useForgotPassword;
