export enum MembersEnum {
  TRUCKING = 'TRUCKING',
  WAREHOUSING = 'WAREHOUSING',
  CUSTOMS_CLEARANCE = 'CUSTOMS_CLEARANCE',
  FORWARDER = 'FORWARDER',
}

export enum PartnersEnum {
  TERMINAL_PORTS = 'TERMINAL_PORTS',
  OPERATORS = 'OPERATORS',
  CONSULTANCY = 'CONSULTANCY',
  OTHER = 'OTHER',
}
