import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/pageTitle/pageTitle';
import InvoiceForm from './invoiceForm/invoiceForm';

const AddInvoice = (): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <div>
      <PageTitle title={t('pageTitles.addInvoice')} />
      <InvoiceForm />
    </div>
  );
};

export default AddInvoice;
