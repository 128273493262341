import React, { ReactElement, useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  editorAllowedTags,
  editorImageUploadHandler,
  stripTags,
  stripTagsAttrs,
  tabToSpace,
} from '../../utils/editor';
import { AppCertificateEnum } from '../../consts/AppCertificateEnum';
import config from '../../config';

interface TextEditorInterface {
  onEditorChange: (value: string) => void;
  textareaName: string;
  value: string;
}

interface FontImportsType {
  default_font: string;
  font_family_formats: string;
  font_imports: string[];
  button_background?: string;
}

const TextEditor = ({ onEditorChange, textareaName, value }: TextEditorInterface): ReactElement => {
  const handleEditorChange = (value: string): void => {
    onEditorChange(value);
  };

  const handlePaste = (event: any, editor: any): void => {
    const text = event.clipboardData.getData();
    const cleanText = stripTags(tabToSpace(stripTagsAttrs(text)).trim(), editorAllowedTags).trim();
    editor.insertContent(cleanText);
    event.preventDefault();
  };

  const editorFontFamilies = useMemo((): FontImportsType => {
    const data: FontImportsType = {
      default_font: '',
      font_family_formats:
        'Roboto=Roboto, sans-serif;Open Sans="Open Sans", sans-serif;PT Serif="PT Serif", serif;',
      font_imports: [
        'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
      ],
    };

    switch (process.env.REACT_APP_CERTIFICATE) {
      case AppCertificateEnum.NEW_SILK_ROAD:
        data.default_font = '"Unbounded", sans-serif';
        data.font_family_formats = 'Unbounded=Unbounded, sans-serif;' + data.font_family_formats;
        data.button_background = `background-color: background-image: linear-gradient(86.31deg, #642b73, #692d71 5.24%, #a64857 96.09%, #b14c57)!important;`;
        data.font_imports.push(
          'https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap',
        );
        break;

      case AppCertificateEnum.MOTION_LOGISTICS:
        data.default_font += 'Kanit, sans-serif';
        data.font_family_formats = 'Kanit=Kanit, sans-serif;' + data.font_family_formats;
        data.button_background = `background-color: ${
          config.theme.token?.colorPrimary ?? '#f9423a'
        };`;
        data.font_imports.push(
          'https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        );
        break;

      case AppCertificateEnum.PROJECT_LOGISTICS:
        data.default_font += 'Mulish, sans-serif';
        data.font_family_formats = 'Mulish=Mulish, sans-serif;' + data.font_family_formats;
        data.button_background = `background-color: ${
          config.theme.token?.colorPrimary ?? '#a33343'
        };`;
        data.font_imports.push(
          'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap',
        );
        break;

      case AppCertificateEnum.PULP_NETWORK:
        data.default_font += 'Inter, sans-serif';
        data.font_family_formats = 'Inter=Inter, sans-serif;' + data.font_family_formats;
        data.button_background = `background-color: ${
          config.theme.token?.colorPrimary ?? '#3C8F7C'
        };`;
        data.font_imports.push(
          'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
        );
        break;

      default:
        data.button_background =
          'background-image: linear-gradient(86.31deg, #642b73, #692d71 5.24%, #a64857 96.09%, #b14c57)!important;';
        data.default_font += 'Helvetica,Arial,sans-serif';
        break;
    }

    return data;
  }, []);

  return (
    <>
      {editorFontFamilies.font_imports.map((fontLink) => (
        <link key={fontLink} href={fontLink} rel='stylesheet'></link>
      ))}
      <Editor
        textareaName={textareaName}
        onEditorChange={handleEditorChange}
        value={value}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        init={{
          images_upload_handler: editorImageUploadHandler,
          height: 500,
          width: '100%',
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'media',
            'table',
            'code',
            'wordcount',
          ],
          toolbar:
            'blocks fontfamily fontsizeinput | ' +
            'bold italic | alignleft aligncenter ' +
            'alignright | bullist numlist outdent indent | ' +
            'link image media | ' +
            'removeformat | undo redo',
          content_style:
            editorFontFamilies.font_imports.flatMap((url) => `@import url('${url}');`).join('') +
            `body { font-family: ${editorFontFamilies.default_font}; font-size:14px } a.button{display: inline-block; ${editorFontFamilies.button_background} color: #ffffff;border-radius: 5px;padding: 10px 20px;text-decoration: none;}`,
          link_class_list: [
            { title: 'Link', value: '' },
            { title: 'Button', value: 'button' },
          ],
          font_family_formats: editorFontFamilies.font_family_formats,
          setup: (editor) => {
            editor.on('paste', function (event) {
              handlePaste(event, editor);
            });
          },
        }}
      />
    </>
  );
};

export default TextEditor;
