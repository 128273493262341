import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import config from '../config';
import { useUserContext } from '../context/user.context';
import { UserInterface } from '../models/userInterface';
import { LocalStorageEnum } from '../consts/LocalStorageEnum';
import { LoginProps } from '../models/loginInterface';
import { loginUser } from '../services/services';

interface LoginHook {
  login: (data: LoginProps) => void;
  loading: boolean;
  error: string | null;
  logOut: () => void;
}

const useLogin = (): LoginHook => {
  const { setUser } = useUserContext();
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const setAccessToken = useCallback(
    (generalToken: string, fileToken: string, remember: boolean): void => {
      if (remember !== undefined && remember) {
        localStorage.setItem(LocalStorageEnum.ACCESS_TOKEN, generalToken);
        localStorage.setItem(LocalStorageEnum.FILE_ACCESS_TOKEN, fileToken);
      } else {
        sessionStorage.setItem(LocalStorageEnum.ACCESS_TOKEN, generalToken);
        sessionStorage.setItem(LocalStorageEnum.FILE_ACCESS_TOKEN, fileToken);
      }
    },
    [],
  );

  const login = useCallback(
    async (data: LoginProps) => {
      try {
        setLoading(true);
        setError(null);
        const loginResponse = await loginUser(data);
        if (loginResponse.data.access_token !== undefined) {
          setLoading(false);
          setAccessToken(
            loginResponse.data.access_token,
            loginResponse.data.file_access_token,
            data.remember,
          );
          if (
            typeof loginResponse.data.access_token === 'string' &&
            loginResponse.data.access_token !== ''
          ) {
            const decodedToken: UserInterface = jwt_decode(loginResponse.data.access_token);
            setUser({
              role: decodedToken.role,
              name: decodedToken.name,
              username: decodedToken.username,
              id: decodedToken.sub,
            });
          }
        }
      } catch (error) {
        setLoading(false);
        if (error instanceof AxiosError && error.response?.status === 400) {
          setError(t('messages.wrongCredentials'));
        } else {
          setError(t('messages.errorTryLater'));
        }
      }
    },
    [setAccessToken, setUser, t],
  );

  const logOut = useCallback((): void => {
    localStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
    sessionStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
    sessionStorage.removeItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
    setUser({});
    navigate(config.routes.login);
  }, [navigate, setUser]);

  return {
    login,
    loading,
    error,
    logOut,
  };
};

export default useLogin;
