import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const FormForwarder = ({disabledFields} : {disabledFields?: boolean}): ReactElement => {
  const { t } = useTranslation('common');

  const { TextArea } = Input;

  return (
    <div>
      <Form.Item
        label={t('misc.forwarderMainAreasOfBusinessCargoType')}
        name='forwarderMainAreasOfBusinessCargoType'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.forwarderMainAreasOfBusinessTransportMode')}
        name='forwarderMainAreasOfBusinessTransportMode'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.forwarderHaveYouHandledCargoViaRailBefore')}
        name='forwarderHaveYouHandledCargoViaRailBefore'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item label={t('misc.forwarderCoreStrengths')} name='forwarderCoreStrengths'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.forwarderGeographicalAreasAlongTheBeltRoadInitiative')}
        name='forwarderGeographicalAreasAlongTheBeltRoadInitiative'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.forwarderAddOnServicesConnectedToTheBeltRoadInitiative')}
        name='forwarderAddOnServicesConnectedToTheBeltRoadInitiative'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
    </div>
  );
};

export default FormForwarder;
