import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FileAddOutlined, ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/pageTitle/pageTitle';
import ListInvoices from './listInvoices';
import config from '../../config';
import { useUserContext } from '../../context/user.context';
import { UserRole } from '../../consts/UserRoleEnum';
import useDownloadFile from '../../hooks/useDownloadFile';
import styles from './index.module.css';

const Invoices = (): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();
  const { downloadFile, loading } = useDownloadFile();

  const exportInvoices = (): void => {
    downloadFile(
      config.apiRoutes.exportInvoices,
      `${t('misc.export')} ${t('pageTitles.invoices')}.csv`,
    );
  };

  const TitleButtons = (): ReactElement => {
    if (user.role === UserRole.MEMBER)
      return (
        <Link to={config.routes.addInvoice}>
          <Button icon={<FileAddOutlined />} type='primary'>
            {t('buttons.addNew')}
          </Button>
        </Link>
      );
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN)
      return (
        <Button icon={<ExportOutlined />} onClick={exportInvoices} loading={loading}>
          {t('buttons.exportAll')}
        </Button>
      );
    return <></>;
  };

  return (
    <div>
      <PageTitle title={t('pageTitles.invoices')} buttons={TitleButtons()} />
      {config.features.invoicesPdf && (
        <a
          target='_blank'
          href={config.invoicePdfUrl}
          className={styles.invoicesPdf}
          rel='noreferrer'
        >
          {t('misc.invoicesPdf')}
        </a>
      )}
      <ListInvoices />
    </div>
  );
};

export default Invoices;
