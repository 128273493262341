import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const FormTerminalPorts = ({disabledFields} : {disabledFields?: boolean}): ReactElement => {
  const { t } = useTranslation('common');

  const { TextArea } = Input;

  return (
    <div>
      <Form.Item
        label={t('misc.terminalPortsParticularTypesOfCargo')}
        name='terminalPortsParticularTypesOfCargo'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.terminalPortsUniqueSellingPoint')}
        name='terminalPortsUniqueSellingPoint'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.terminalPortsAvailableTransportLinks')}
        name='terminalPortsAvailableTransportLinks'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.terminalPortsWhenShouldAMemberContactYou')}
        name='terminalPortsWhenShouldAMemberContactYou'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
    </div>
  );
};

export default FormTerminalPorts;
