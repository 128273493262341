import React, { ReactElement } from 'react';
import Sider from 'antd/es/layout/Sider';
import { Button } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';
import Navigation from '../navigation/navigation';
import styles from './sideBar.module.css';
import useLogin from '../../hooks/useLogin';
import Logo from '../logo/logo';
import useResponsiveLayout from '../../hooks/useResponsiveLayout';

const SideBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const { logOut } = useLogin();
  const { t } = useTranslation('common');
  const { isMobileLayout } = useResponsiveLayout();

  return (
    <div className={styles.root}>
      <Sider
        collapsedWidth={0}
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={cx(styles.sidebar, isMobileLayout && styles.mobile)}
        width={isMobileLayout ? '100%' : 200}
      >
        <div className={styles.inner}>
          {!isMobileLayout && (
            <div className={styles.logo}>
              <Logo width={140} />
            </div>
          )}
          <Navigation setCollapsed={setCollapsed} />
          <div className={cx(styles.bottom, collapsed && styles.collapsed)}>
            <Button
              icon={<LogoutOutlined />}
              type='text'
              className={styles.button}
              onClick={logOut}
            >
              {!collapsed && t('sidebar.logOut')}
            </Button>
          </div>
        </div>
      </Sider>
    </div>
  );
};

export default SideBar;
