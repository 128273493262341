enum TabKeys {
  ACCOUNT = 'account',
  ABOUT = 'about',
  SERVICES = 'services',
  TEAM = 'team',
  CERTIFICATES = 'certificates',
  SUBMIT = 'submit',
}

export default TabKeys;
