import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { ReactElement, ReactNode } from 'react';
import config from '../../config';
import styles from './publicPage.module.css';

const PublicPage = ({ children }: { children: ReactNode }): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <Row justify='center' align='middle' className={styles.root}>
      <div className={styles.innerWrapper}>{children}</div>
      <div className={styles.footer}>
        <Row className={styles.policyLinks}>
          <a href={config.policiesLinks.imprint}>{t('login.imprint')}</a>
          <a href={config.policiesLinks.privacyPolicy}>{t('login.privacyPolicy')}</a>
        </Row>
        <Row justify='center'>
          Copyright ©{new Date().getFullYear()}, {config.projectName}
        </Row>
      </div>
    </Row>
  );
};

export default PublicPage;
