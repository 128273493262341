import React, { ReactElement } from 'react';
import styles from './filters.module.css';
import { AdminsFilterParamsType } from '../../types';
import NameFilter from '../../../../components/Filters/nameFilter';

const Filters = ({
  setParams,
}: {
  setParams: (newParams: AdminsFilterParamsType) => void;
}): ReactElement => {
  const handleSearch = async (value: string | undefined): Promise<void> => {
    setParams({ primaryContact: value });
  };

  return (
    <div className={styles.root}>
      <NameFilter onChange={handleSearch} />
    </div>
  );
};

export default Filters;
