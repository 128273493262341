import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import PageTitle from '../../components/pageTitle/pageTitle';
import InvoiceForm from './invoiceForm/invoiceForm';
import NotFound from '../notFound/notFound';
import useInvoice from './useInvoice';

const EditInvoice = (): ReactElement => {
  const { t } = useTranslation('common');

  const { loading, editInvoiceData, id } = useInvoice({ isEdit: true });

  if (loading)
    return (
      <>
        <LoadingOutlined />
      </>
    );

  if (editInvoiceData === undefined) return <NotFound />;

  return (
    <div>
      <PageTitle title={t('pageTitles.editInvoice')} />
      <InvoiceForm initValues={editInvoiceData} invoiceId={id} />
    </div>
  );
};

export default EditInvoice;
