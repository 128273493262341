import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './reviews.module.css';
import CardTitle from '../../../components/cardTitle/cardTitle';
import ReviewForm from './reviewForm';
import { useUserContext } from '../../../context/user.context';
import { UserRole } from '../../../consts/UserRoleEnum';
import { getReviews } from './utils';
import { ReviewInterface } from './types';
import ListReviews from './listReviews';

const CompanyReviews = ({ id, companyName }: { id: string; companyName: string }): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();

  const [reviews, setReviews] = useState<ReviewInterface[]>([]);

  const showReviewForm = useMemo((): boolean => {
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) return false;
    if (user.id === Number(id)) return false;
    if (reviews.some((e) => e.authorId === user.id)) return false;
    return true;
  }, [user, id, reviews]);

  const setReviewsData = useCallback(async (): Promise<void> => {
    const companies = await getReviews(id);
    setReviews(companies);
  }, [id]);

  useEffect(() => {
    setReviewsData();
  }, [id, setReviewsData, user]);

  return (
    <div className={styles.root}>
      <CardTitle
        title={t('messages.whatMembersSayAbout').replace(':companyName', companyName)}
        type='bold'
        size='big'
      />
      {reviews === undefined || reviews.length === 0 ? (
        <div className={styles.noReviews}>{t('messages.noReviews')}</div>
      ) : (
        <ListReviews reviews={reviews} />
      )}
      {showReviewForm && <ReviewForm id={id} setReviewsData={setReviewsData} />}
    </div>
  );
};

export default CompanyReviews;
