import { Form, FormInstance, message, RadioChangeEvent } from 'antd';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteArticle, postArticle, putArticle } from '../../services/services';
import {
  ArticleExistingPhoto,
  ArticleFormInterface,
  ArticleFormProps,
  ArticlePhoto,
} from './types';
import config from '../../config';

interface ArticleFormHook {
  handleDefaultPhoto: (e: RadioChangeEvent) => void;
  handleEditorChange: (value: string) => void;
  handleSubmit: (values: FormData) => void;
  body: string | null;
  submitErrors: string | null;
  form: FormInstance<any>;
  defaultPhoto: string | null;
  loading: boolean;
  handleDelete: () => void;
}

const useArticleForm = ({ data }: ArticleFormProps): ArticleFormHook => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [body, setBody] = useState<string | null>(null);
  const [defaultPhoto, setDefaultPhoto] = useState<string | null>(null);
  const [submitErrors, setSubmitErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (body === null && (data?.body).length > 0) {
      setBody(data.body);
    }

    if (
      defaultPhoto === null &&
      data?.photos &&
      data?.photos.some((photo: ArticleExistingPhoto) => photo.default === 1)
    ) {
      setDefaultPhoto(data?.photos.find((photo) => photo.default === 1)?.fileId as string);
    }
  }, [body, data, defaultPhoto]);

  const prepareFormValues = useCallback(
    async (values: FormData): Promise<ArticleFormInterface> => {
      const { uploadedPhotos, ...data }: any = { ...values };
      const photos: ArticlePhoto[] = [];

      if (uploadedPhotos !== undefined && Array.isArray(uploadedPhotos.fileList)) {
        uploadedPhotos.fileList.forEach((element: any) => {
          if (typeof element.originFileObj !== 'undefined') {
            photos.push({
              file: element?.originFileObj,
              default: element?.uid === defaultPhoto ? 1 : 0,
            });
          } else {
            photos.push({
              fileId: element?.uid,
              fileName: element?.name,
              location: element.url,
              default: element?.uid === defaultPhoto ? 1 : 0,
            });
          }
        });
      }

      data.photos = photos;

      return data;
    },
    [defaultPhoto],
  );

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        setLoading(true);
        const preparedValues = await prepareFormValues(values);

        if (data.id == null) {
          await postArticle(preparedValues);
        } else {
          await putArticle(data.id, preparedValues);
        }
        setLoading(false);
        navigate(config.routes.allArticles);
        message.success(t('messages.successSave'));
      } catch (error) {
        if (error instanceof AxiosError) {
          setSubmitErrors(t(`errors.${error.response?.data.message}`));
        } else {
          message.error(t('messages.errorTryLater'));
        }
        setLoading(false);
      }
    },
    [data.id, navigate, prepareFormValues, t],
  );

  const handleDelete = useCallback(async () => {
    if (!data.id) return;

    try {
      setLoading(true);
      await deleteArticle(data.id);
      navigate(config.routes.allArticles);
      message.success(t('messages.successDelete'));
      setLoading(false);
    } catch (error) {
      message.error(t('messages.errorTryLater'));
      setLoading(false);
    }
  }, [data.id, navigate, t]);

  const handleEditorChange = (value: string): void => {
    setBody(value);
    form.setFieldValue('body', value);
    form.validateFields(['body']);
  };

  const handleDefaultPhoto = (e: RadioChangeEvent): void => {
    const uid = e.target.value;
    setDefaultPhoto(uid);
  };

  return {
    handleDefaultPhoto,
    handleEditorChange,
    handleSubmit,
    body,
    submitErrors,
    form,
    defaultPhoto,
    loading,
    handleDelete,
  };
};

export default useArticleForm;
