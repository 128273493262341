import { AxiosResponse } from 'axios';
import { axiosInstance } from '../api/AxiosInstance';
import config from '../config';
import { CompaniesFilterParamsType, CompaniesListResponse } from '../pages/companies/types';
import { CompanyInterface } from '../models/companyInterface';
import { InvoiceInitialValues } from '../models/invoiceInterface';
import { NotificationInterface } from '../models/notificationInterface';
import { NotificationParamsInterface } from '../models/notificationParamsInterface';
import {
  ArticleEntity,
  ArticleFormInterface,
  ArticleListResponse,
  ArticlesFilterParamsType,
} from '../pages/articles/types';
import {
  EventEntity,
  EventFormInterface,
  EventListResponse,
  EventsFilterParamsType,
} from '../pages/events/types';
import { ForgotPasswordInterface } from '../models/forgotPasswordInterface';
import { InvoiceListResponse } from '../pages/invoices/types';
import { ResetPasswordInterface } from '../models/resetPasswordInterface';
import { LoginProps } from '../models/loginInterface';
import {
  AdminEntity,
  AdminFormInterface,
  AdminListResponse,
  AdminsFilterParamsType,
} from '../pages/admins/types';

export const getArticleById = async (id: string): Promise<ArticleEntity> => {
  const { data } = await axiosInstance.get(`${config.apiRoutes.articles}/${id}`);
  return data;
};

export const postArticle = async (article: ArticleFormInterface): Promise<void> => {
  await axiosInstance.post(config.apiRoutes.articles, article, {
    headers: {
      ...{ 'Content-Type': 'multipart/form-data' },
    },
  });
};

export const getArticles = async (
  params: ArticlesFilterParamsType,
): Promise<ArticleListResponse> => {
  const { data } = await axiosInstance.get(config.apiRoutes.articles, {
    params,
  });
  return data;
};

export const getInvoices = async (
  params: ArticlesFilterParamsType,
): Promise<InvoiceListResponse | undefined> => {
  try {
    const { data } = await axiosInstance.get(config.apiRoutes.invoices, {
      params,
    });
    return data;
  } catch (error) {
    return undefined;
  }
};

export const postAdmin = async (admin: AdminFormInterface): Promise<void> => {
  await axiosInstance.post(config.apiRoutes.admins, admin);
};

export const putAdmin = async (id: number, admin: AdminFormInterface): Promise<void> => {
  await axiosInstance.put(`${config.apiRoutes.admins}/${id}`, admin);
};

export const putArticle = async (id: number, article: ArticleFormInterface): Promise<void> => {
  await axiosInstance.put(`${config.apiRoutes.articles}/${id}`, article, {
    headers: {
      ...{ 'Content-Type': 'multipart/form-data' },
    },
  });
};

export const deleteArticle = async (id: number): Promise<void> => {
  await axiosInstance.delete(`${config.apiRoutes.articles}/${id}`);
};

export const getCompanies = async (
  params: CompaniesFilterParamsType,
): Promise<CompaniesListResponse | undefined> => {
  try {
    const response = await axiosInstance.get(config.apiRoutes.members, {
      params,
    });
    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const getAdmins = async (
  params: AdminsFilterParamsType,
): Promise<AdminListResponse | undefined> => {
  try {
    const response = await axiosInstance.get(config.apiRoutes.admins, {
      params,
    });
    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const postEvent = async (event: EventFormInterface): Promise<void> => {
  await axiosInstance.post(config.apiRoutes.events, event, {
    headers: {
      ...{ 'Content-Type': 'multipart/form-data' },
    },
  });
};

export const deleteEvent = async (id: number): Promise<void> => {
  await axiosInstance.delete(`${config.apiRoutes.events}/${id}`);
};

export const getEvents = async (params: EventsFilterParamsType): Promise<EventListResponse> => {
  const { data } = await axiosInstance.get(config.apiRoutes.events, {
    params,
  });
  return data;
};

export const putEvent = async (id: number, event: EventFormInterface): Promise<void> => {
  await axiosInstance.put(`${config.apiRoutes.events}/${id}`, event, {
    headers: {
      ...{ 'Content-Type': 'multipart/form-data' },
    },
  });
};

export const getEventById = async (id: string): Promise<EventEntity> => {
  const { data } = await axiosInstance.get(`${config.apiRoutes.events}/${id}`);
  return data;
};

export const deleteUser = async (id: string): Promise<void> => {
  try {
    await axiosInstance.delete(config.apiRoutes.users.replace(':id', id));
  } catch (error) {}
};

export const getUser = async (id: string): Promise<CompanyInterface | AdminEntity | undefined> => {
  try {
    const response = await axiosInstance.get(config.apiRoutes.users.replace(':id', id));
    return response.data;
  } catch (error) {}
};

export const getInvoice = async (id: string): Promise<InvoiceInitialValues | undefined> => {
  try {
    const response = await axiosInstance.get(config.apiRoutes.invoiceId.replace(':id', id));
    if (response.status === 200 && response.data !== '') return response.data;
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getUserCertificate = async (id: string): Promise<CompanyInterface | undefined> => {
  try {
    const response = await axiosInstance.get(config.apiRoutes.certificate.replace(':id', id));
    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const getNotifications = async (
  params?: NotificationParamsInterface,
): Promise<NotificationInterface[]> => {
  const { data } = await axiosInstance.get(config.apiRoutes.notifications, {
    params,
  });
  return data;
};

export const forgotPassword = async (data: ForgotPasswordInterface): Promise<void> => {
  await axiosInstance.post(config.apiRoutes.forgotPassword, data);
};

export const resetPassword = async (token: string, data: ResetPasswordInterface): Promise<void> => {
  await axiosInstance.post(config.apiRoutes.resetPassword, { password: data.password, token });
};

export const loginUser = async (data: LoginProps): Promise<AxiosResponse> => {
  const dataWithoutRemmeber = (({ remember, ...object }) => object)(data);
  const response = await axiosInstance.post(config.apiRoutes.login, dataWithoutRemmeber);
  return response;
};
