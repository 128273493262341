import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import styles from './filters.module.css';
import FilterElementWrap from './filterElementWrap';

const NameFilter = ({
  onChange,
}: {
  onChange: (value: string | undefined) => void;
}): ReactElement => {
  const { t } = useTranslation('common');

  const handleSearch = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
    const companyName = event.currentTarget?.value ?? undefined;
    onChange(companyName);
  };

  return (
    <FilterElementWrap>
      <Input
        onChange={handleSearch}
        placeholder={t('misc.searchByCompanyName') ?? ''}
        className={styles.fullWidth}
      />
    </FilterElementWrap>
  );
};

export default NameFilter;
