import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CompanyInterface } from '../../../models/companyInterface';
import CardTitle from '../../../components/cardTitle/cardTitle';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';
import { PaymentProtectionStatusEnum } from '../../../consts/PaymentProtectionStatusEnum';
import { ReactComponent as ShieldEmptyX } from '../../../assets/shieldEmptyX.svg';
import { ReactComponent as ShieldFill } from '../../../assets/shieldFill.svg';
import styles from './index.module.css';
import { PaymentProtectionEnum } from '../../../consts/PaymentProtectionEnum';

const PaymentProtectionTable = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const aboutData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.paymentProtectionValidityDate)
      prepareData.push({
        name: t('misc.paymentProtectionValidityDate'),
        content: String(company.paymentProtectionValidityDate).substring(0, 10),
      });

    if (company.officesCoveredInPaymentProtection)
      prepareData.push({
        name: t('misc.officesCoveredInPaymentProtection'),
        content: company.officesCoveredInPaymentProtection,
      });

    if (company.paymentGuaranteeStatus) {
      let guaranteeStatus: string = '';
      switch (company.paymentGuaranteeStatus) {
        case PaymentProtectionEnum.SECURED:
          guaranteeStatus = t('paymentProtection.secured');
          break;

        case PaymentProtectionEnum.RENEWAL:
          guaranteeStatus = t('paymentProtection.renewal');
          break;

        case PaymentProtectionEnum.SUSPENDED:
          guaranteeStatus = t('paymentProtection.suspended');
          break;

        default:
          break;
      }

      if (guaranteeStatus)
        prepareData.push({
          name: t('misc.paymentGuaranteeStatus'),
          content: guaranteeStatus,
        });
    }

    return prepareData;
  }, [company, t]);

  if (
    company.paymentProtection !== PaymentProtectionStatusEnum.ACTIVE ||
    !company.paymentProtectionValidityDate ||
    dayjs().isAfter(dayjs(company.paymentProtectionValidityDate))
  )
    return <CardTitle type='bold' title={t('misc.noPaymentProtection')} icon={<ShieldEmptyX />} />;

  return (
    <>
      <CardTitle
        type='bold'
        title={t('misc.paymentProtection')}
        icon={<ShieldFill className={styles.shieldFill} width={15} />}
      />
      <CustomTable data={aboutData} />
    </>
  );
};

export default PaymentProtectionTable;
