import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Input, Button, Space, Row, Col, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import useAdminForm from '../useAdminForm';
import { AdminFormInterface, AdminFormProps } from '../types';

export interface FieldError {
  name: Array<string | number>;
  errors: string[];
  warnings: string[];
}

const AdminForm = ({ data }: AdminFormProps): ReactElement => {
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState<AdminFormInterface>();

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const { form, handleSubmit, submitErrors, loading } = useAdminForm({ data });

  useEffect(() => {
    form.resetFields();
  }, [form, formData]);

  return (
    <div className='panel white-bg'>
      {formData && (
        <Form form={form} initialValues={formData} onFinish={handleSubmit} layout='vertical'>
          <Row gutter={50}>
            <Col span={24}>
              <Form.Item
                label={t('misc.fullName')}
                name='primaryContact'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <Input maxLength={250} />
              </Form.Item>
              <Form.Item
                label={t('misc.username')}
                name='username'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <Input maxLength={250} />
              </Form.Item>
              <Form.Item
                label={t('misc.email')}
                name='email'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <Input maxLength={250} />
              </Form.Item>
              {!data.id && (
                <Form.Item
                  label={t('misc.password')}
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: t('messages.requiredField') ?? '',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Space direction='vertical' className='full-width'>
                {submitErrors !== null && (
                  <Space direction='vertical' key={'errors'}>
                    <Alert message={submitErrors} type='error' showIcon />
                  </Space>
                )}
                <Button
                  type='primary'
                  htmlType='submit'
                  key='button'
                  size='large'
                  loading={loading}
                >
                  {t('buttons.submit')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default AdminForm;
