import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const CustomsClearance = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const customsClearanceData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.customsClearanceUniqueSellingPoint)
      prepareData.push({ name: t('misc.customsClearanceUniqueSellingPoint'), content: company.customsClearanceUniqueSellingPoint });

    if (company.customsClearanceRegionsAndRoutes)
      prepareData.push({ name: t('misc.customsClearanceRegionsAndRoutes'), content: company.customsClearanceRegionsAndRoutes });

    if (company.customsClearanceParticularTypesOfCargo)
      prepareData.push({ name: t('misc.customsClearanceParticularTypesOfCargo'), content: company.customsClearanceParticularTypesOfCargo });

    return prepareData;
  }, [company, t]);

  if (customsClearanceData.length === 0) return <></>;

  return <CustomTable data={customsClearanceData} />;
};

export default CustomsClearance;
