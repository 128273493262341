import React, { ReactElement } from 'react';
import cx from 'classnames';
import styles from './cardTitle.module.css';

const CardTitle = ({
  type,
  title,
  icon,
  size,
  noMargin,
}: {
  type?: 'ghost' | 'bold';
  title: string;
  icon?: ReactElement;
  size?: 'big';
  noMargin?: boolean;
}): ReactElement => {
  return (
    <div
      className={cx(
        styles.root,
        type === 'ghost' && styles.ghost,
        type === 'bold' && styles.bold,
        size === 'big' && styles.big,
        noMargin === true && styles.noMargin,
      )}
    >
      {icon !== undefined && icon}
      <span>{title}</span>
    </div>
  );
};

export default CardTitle;
