import React, { ReactElement } from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Alert,
  Typography,
  Layout,
  Select,
  Badge,
  Upload,
  DatePicker,
  Popconfirm,
} from 'antd';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import useUploadField from '../../../hooks/useUploadField';
import TextEditor from '../../../components/TextEditor/TextEditor';
import useEventForm from '../useEventForm';
import { EventFormProps, EventPhoto, EventPhotos } from '../types';
import styles from './index.module.css';
import { ItemPublished } from '../../../consts/ItemPublishedEnum';

export interface FieldError {
  name: Array<string | number>;
  errors: string[];
  warnings: string[];
}

const EventForm = ({ data }: EventFormProps): ReactElement => {
  const { beforeUploadImage } = useUploadField();
  const { t } = useTranslation('common');

  const {
    form,
    handleSubmit,
    handleEditorChange,
    postSummary,
    submitErrors,
    contextHolder,
    loading,
    handleDelete,
  } = useEventForm({ data });

  const initPhotos: EventPhotos = {
    thumbnail: [],
    mainImage: [],
    sliderImage: [],
    heroImage: [],
    sponsors: [],
    partners: [],
  };
  if (data?.thumbnail) {
    ['thumbnail', 'mainImage'].forEach((key) => {
      initPhotos[key as keyof EventPhotos] = [
        {
          uid: (data[key as keyof EventPhotos] as EventPhoto).fileId as string,
          url: (data[key as keyof EventPhotos] as EventPhoto).location as string,
          thumbUrl: (data[key as keyof EventPhotos] as EventPhoto).location as string,
          name: (data[key as keyof EventPhotos] as EventPhoto).fileName as string,
        },
      ];
    });

    ['sliderImage', 'sponsors', 'partners', 'heroImage'].forEach((key) => {
      data[key as keyof EventPhotos] &&
        (data[key as keyof EventPhotos] as EventPhoto[]).forEach((item) => {
          initPhotos[key as keyof EventPhotos].push({
            uid: item.fileId as string,
            url: item.location as string,
            thumbUrl: item.location as string,
            name: item.fileName as string,
          });
        });
    });
  }

  const formattedData = {
    ...data,
    published: [ItemPublished.FALSE, ItemPublished.NOT_SAVED_AND_DRAFT_IN_PROGRESS].includes(
      data.published,
    )
      ? ItemPublished.FALSE
      : ItemPublished.TRUE,
  };

  return (
    <div className='panel white-bg'>
      {contextHolder}
      <Form
        form={form}
        initialValues={formattedData}
        onFinish={handleSubmit}
        layout='vertical'
        disabled={loading}
      >
        <Row gutter={50}>
          <Col span={25}>
            <Form.Item
              label={t('misc.title')}
              name='name'
              rules={[
                {
                  required: true,
                  message: t('messages.requiredField') ?? '',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('misc.location')}
              name='location'
              rules={[
                {
                  required: true,
                  message: t('messages.requiredField') ?? '',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('misc.date')}
              name='date'
              rules={[
                {
                  required: true,
                  message: t('messages.requiredField') ?? '',
                },
              ]}
            >
              <DatePicker format={config.dateFormat.universalFormat} />
            </Form.Item>
            <Form.Item
              label={t('misc.dateFinal')}
              name='dateFinal'
              rules={[
                {
                  required: true,
                  message: t('messages.requiredField') ?? '',
                },
              ]}
            >
              <DatePicker format={config.dateFormat.universalFormat} />
            </Form.Item>
            <Space direction='vertical' className='full-width'>
              <Space direction='vertical' className='full-width'>
                <Typography.Text>
                  <Typography.Text type='danger' className='vertical-center'>
                    *{' '}
                  </Typography.Text>
                  {t('misc.body')}
                </Typography.Text>
                <TextEditor
                  textareaName='postSummary2'
                  onEditorChange={handleEditorChange}
                  value={postSummary ?? ''}
                />
              </Space>
              <div style={{ marginTop: '-40px' }}>
                <Form.Item
                  name='postSummary'
                  rules={[
                    {
                      required: true,
                      message: t('messages.requiredField') ?? '',
                    },
                  ]}
                />
              </div>
              <Layout className='white-bg'>
                <div className='full-width photos-wrapper'>
                  {data !== null && (
                    <Form.Item
                      name='thumbnail'
                      valuePropName='list'
                      label={t('misc.thumbnail')}
                      tooltip={t('tooltip.onlyImages').replace(
                        ':fileSize',
                        String(config.limits.maxImageSize),
                      )}
                      rules={[
                        {
                          required: true,
                          message: t('messages.requiredField') ?? '',
                        },
                      ]}
                    >
                      <Upload
                        multiple={false}
                        accept={config.acceptedFileFormats.imagesFormats}
                        beforeUpload={beforeUploadImage}
                        defaultFileList={[...(initPhotos.thumbnail ?? [])]}
                        showUploadList={true}
                        listType='picture'
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />} type='primary'>
                          {t('buttons.upload')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </div>
              </Layout>
              <Layout className='white-bg'>
                <div className='full-width photos-wrapper'>
                  {data !== null && (
                    <Form.Item
                      name='mainImage'
                      valuePropName='list'
                      label={t('misc.mainImage')}
                      tooltip={t('tooltip.onlyImages').replace(
                        ':fileSize',
                        String(config.limits.maxImageSize),
                      )}
                      rules={[
                        {
                          required: true,
                          message: t('messages.requiredField') ?? '',
                        },
                      ]}
                    >
                      <Upload
                        multiple={false}
                        accept={config.acceptedFileFormats.imagesFormats}
                        beforeUpload={beforeUploadImage}
                        defaultFileList={[...(initPhotos.mainImage ?? [])]}
                        showUploadList={true}
                        listType='picture'
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />} type='primary'>
                          {t('buttons.upload')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </div>
              </Layout>
              <Layout className='white-bg'>
                <div className='full-width photos-wrapper'>
                  {data !== null && (
                    <Form.Item
                      name='heroImage'
                      valuePropName='list'
                      label={t('misc.heroImage')}
                      tooltip={t('tooltip.onlyImages').replace(
                        ':fileSize',
                        String(config.limits.maxImageSize),
                      )}
                      rules={[
                        {
                          required: true,
                          message: t('messages.requiredField') ?? '',
                        },
                      ]}
                    >
                      <Upload
                        multiple={true}
                        accept={config.acceptedFileFormats.imagesFormats}
                        beforeUpload={beforeUploadImage}
                        defaultFileList={[...(initPhotos.heroImage ?? [])]}
                        showUploadList={true}
                        listType='picture'
                      >
                        <Button icon={<UploadOutlined />} type='primary'>
                          {t('buttons.upload')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </div>
              </Layout>
              <Layout className='white-bg'>
                <div className='full-width photos-wrapper'>
                  {data !== null && (
                    <Form.Item
                      name='sliderImage'
                      valuePropName='list'
                      label={t('misc.sliderImage')}
                      tooltip={t('tooltip.onlyImages').replace(
                        ':fileSize',
                        String(config.limits.maxImageSize),
                      )}
                      rules={[
                        {
                          required: true,
                          message: t('messages.requiredField') ?? '',
                        },
                      ]}
                    >
                      <Upload
                        multiple={true}
                        accept={config.acceptedFileFormats.imagesFormats}
                        beforeUpload={beforeUploadImage}
                        defaultFileList={[...(initPhotos.sliderImage ?? [])]}
                        showUploadList={true}
                        listType='picture'
                      >
                        <Button icon={<UploadOutlined />} type='primary'>
                          {t('buttons.upload')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </div>
              </Layout>
              <Layout className='white-bg'>
                <div className='full-width photos-wrapper'>
                  {data !== null && (
                    <Form.Item
                      name='sponsors'
                      valuePropName='list'
                      label={t('misc.sponsors')}
                      tooltip={t('tooltip.onlyImages').replace(
                        ':fileSize',
                        String(config.limits.maxImageSize),
                      )}
                    >
                      <Upload
                        multiple={true}
                        accept={config.acceptedFileFormats.imagesFormats}
                        beforeUpload={beforeUploadImage}
                        defaultFileList={[...(initPhotos.sponsors ?? [])]}
                        showUploadList={true}
                        listType='picture'
                      >
                        <Button icon={<UploadOutlined />} type='primary'>
                          {t('buttons.upload')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </div>
              </Layout>
              <Layout className='white-bg'>
                <div className='full-width photos-wrapper'>
                  {data !== null && (
                    <Form.Item
                      name='partners'
                      valuePropName='list'
                      label={t('misc.partners')}
                      tooltip={t('tooltip.onlyImages').replace(
                        ':fileSize',
                        String(config.limits.maxImageSize),
                      )}
                    >
                      <Upload
                        multiple={true}
                        accept={config.acceptedFileFormats.imagesFormats}
                        beforeUpload={beforeUploadImage}
                        defaultFileList={[...(initPhotos.partners ?? [])]}
                        showUploadList={true}
                        listType='picture'
                      >
                        <Button icon={<UploadOutlined />} type='primary'>
                          {t('buttons.upload')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </div>
              </Layout>

              <Layout className='white-bg'>
                <Form.Item
                  label={t('misc.status')}
                  name='published'
                  rules={[
                    {
                      required: true,
                      message: t('messages.requiredField') ?? '',
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value={ItemPublished.TRUE}>
                      <Badge color='#52C41A' text={t('status.published')} />
                    </Select.Option>
                    <Select.Option value={ItemPublished.FALSE}>
                      <Badge color='#FF4D4F' text={t('status.draft')} />
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Layout>
              {submitErrors !== null && (
                <Space direction='vertical' key={'errors'}>
                  <Alert message={submitErrors} type='error' showIcon />
                </Space>
              )}

              <Space direction='horizontal' className={styles.buttonsWrap}>
                <Button
                  type='primary'
                  htmlType='submit'
                  key='button'
                  size='large'
                  loading={loading}
                >
                  {t('buttons.submit')}
                </Button>
                {data.id && (
                  <Popconfirm
                    title={t('misc.confirmDeletion')}
                    description={t('messages.areYouSureEvent')}
                    onConfirm={handleDelete}
                    okText={t('buttons.yes')}
                    cancelText={t('buttons.no')}
                  >
                    <Button icon={<DeleteOutlined />} loading={loading}>
                      {t('buttons.delete')}
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EventForm;
