import React, { ReactElement } from 'react';
import {
  FileImageOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

export const getFileIcon = (fileId: string): ReactElement => {
  if (fileId.endsWith('.png') || fileId.endsWith('.jpg')) return <FileImageOutlined />;
  if (fileId.endsWith('.doc') || fileId.endsWith('.docx')) return <FileWordOutlined />;
  if (fileId.endsWith('.xls') || fileId.endsWith('.xlsx')) return <FileExcelOutlined />;
  if (fileId.endsWith('.pdf')) return <FilePdfOutlined />;
  return <FileUnknownOutlined />;
};
