import { message } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from '../api/AxiosInstance';

interface DownloadFileHook {
  downloadFile: (url: string, fileName: string, requestConfig?: Record<string, string>) => void;
  loading: boolean;
}

const useDownloadFile = (): DownloadFileHook => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState<boolean>(false);

  const downloadFile = useCallback(
    async (url: string, fileName: string, requestConfig?: Record<string, string>) => {
      try {
        setLoading(true);
        const response = await axiosInstance(url, requestConfig);
        const href = window.URL.createObjectURL(new Blob([response.data]));
        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        anchorElement.download = fileName;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
        setLoading(false);
      } catch (error) {
        message.error(t('messages.errorTryLater'));
        setLoading(false);
      }
    },
    [t],
  );

  return {
    downloadFile,
    loading,
  };
};

export default useDownloadFile;
