import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import ArticleForm from './components/articleForm';
import PageTitle from '../../components/pageTitle/pageTitle';
import { useParams } from 'react-router-dom';
import { ArticleFormInitialValue } from './types';
import { getArticleById, getNotifications } from '../../services/services';
import { getUploadInitialPhotosFormat } from './utils';
import { NotificationModule } from '../../consts/NotificationModuleEnum';
import { NotificationContext } from '../../context/notification.context';

const EditArticle = (): ReactElement => {
  const { setUpdated } = useContext(NotificationContext);
  const [article, setArticle] = useState<ArticleFormInitialValue | null>(null);
  const { id } = useParams();
  const { t } = useTranslation('common');

  useEffect(() => {
    const initData = async (): Promise<void> => {
      try {
        const data = await getArticleById(id as string);
        await getNotifications({ itemId: id, module: NotificationModule.ARTICLE });
        setUpdated(false);
        setArticle({
          ...data,
          uploadedPhotos: {
            fileList: data.photos ? getUploadInitialPhotosFormat(data.photos) : [],
          },
        });
      } catch (e) {
        message.error(t('messages.errorTryLater'));
      }
    };

    initData();
  }, [id, t]);

  return (
    <div>
      <PageTitle title={'Edit article'} />
      {article && <ArticleForm data={article} />}
    </div>
  );
};

export default EditArticle;
