import axios from 'axios';
import config from '../config';
import { LocalStorageEnum } from '../consts/LocalStorageEnum';

export const axiosInstance = axios.create({
  baseURL: config.backendUrl + 'api/v1',
  timeout: 7000,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const localStorageGeneralToken = localStorage.getItem(LocalStorageEnum.ACCESS_TOKEN);
    const sessionStorageGeneralToken = sessionStorage.getItem(LocalStorageEnum.ACCESS_TOKEN);
    if (localStorageGeneralToken != null) {
      config.headers.set('Authorization', `Bearer ${localStorageGeneralToken}`);
    } else if (sessionStorageGeneralToken != null) {
      config.headers.set('Authorization', `Bearer ${sessionStorageGeneralToken}`);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
      sessionStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
      localStorage.removeItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
      sessionStorage.removeItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
      window.location.replace(config.routes.login);
    }
    return await Promise.reject(error);
  },
);
