import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const Logo = ({ width, iconFormat }: { width?: number; iconFormat?: boolean }): ReactElement => {
  return (
    <div>
      <Link to={config.mainWebsiteUrl} target='_blank'>
        <img
          src={iconFormat ? config.logo.iconSvgLogo : config.logo.fullSvgLogo}
          width={width ?? undefined}
          alt={config.projectName + ' Logo'}
        />
      </Link>
    </div>
  );
};

export default Logo;
