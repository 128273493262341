import React from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Alert, Typography } from 'antd';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from './resetPassword.module.css';
import useResetPassword from '../../hooks/useResetPassword';
import config from '../../config';
import Logo from '../../components/logo/logo';

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  if (searchParams.get('token') === null) {
    navigate(config.routes.login);
  }

  const { handleSubmit, loading, error, success } = useResetPassword(
    searchParams.get('token') as string,
  );
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={styles.logoWrapper}>
        <Logo width={250} />
      </div>
      {!success ? (
        <Form
          name='resetPass'
          className={styles.loginForm}
          onFinish={handleSubmit}
          initialValues={{
            remember: true,
          }}
        >
          {error != null && (
            <Form.Item>
              <Alert message={error} type='error' />
            </Form.Item>
          )}
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: t('login.required.password') ?? '',
              },
              {
                min: config.minPasswordLength,
                message: t('messages.passwordMinLentgh').replace(
                  ':length',
                  String(config.minPasswordLength),
                ),
              },
            ]}
          >
            <Input
              type='password'
              prefix={<LockOutlined className='site-form-item-icon' />}
              placeholder={t('misc.password') ?? ''}
            />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('login.required.confirmPassword') ?? '',
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    await Promise.resolve();
                    return;
                  }
                  return await Promise.reject(new Error(t('login.required.matchPasswords') ?? ''));
                },
              }),
            ]}
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder={t('misc.confirmPassword') ?? ''}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              className={cx('login-form-button', styles.loginBtn)}
              loading={loading}
            >
              {t('login.changePassword')}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>
          <Typography>{t('login.resetPasswordSuccess')}</Typography>
          <Link to={config.routes.login}>
            <Button type='primary' size='large'>
              {t('buttons.goToLogin')}
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
