import React, { ReactElement, useMemo } from 'react';
import { Alert, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import type { TabsProps } from 'antd';
import FormTrucking from './trucking';
import FormWarehousing from './warehousing';
import { MembersEnum, PartnersEnum } from '../../../../consts/UserSubcategories';
import FormForwarder from './forwarder';
import FormCustomsClearance from './customsClearance';
import FormTerminalPorts from './terminalPorts';
import FormOperators from './operators';
import FormConsultancy from './consultancy';
import FormOther from './other';
import { ActiveServicesTabsInterface } from '../../../../models/activeServicesTabs';
import styles from '../formStyles.module.css';
import { CompanyFormInterface } from '../../../../models/companyFormInterface';

const FormServices = ({ activeServicesTabs, selfEditMember, formValues }: {activeServicesTabs: ActiveServicesTabsInterface, selfEditMember?: boolean; formValues: CompanyFormInterface; }): ReactElement => {
  const { t } = useTranslation('common');

  const servicesTabs = useMemo((): TabsProps['items'] => {
    const tabs: TabsProps['items'] = [];

    if (activeServicesTabs.memberCategories?.includes(MembersEnum.FORWARDER))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.forwarder'),
        children: <FormForwarder disabledFields={selfEditMember} />,
        forceRender: true,
      });

    if (activeServicesTabs.memberCategories?.includes(MembersEnum.CUSTOMS_CLEARANCE))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.customsClearance'),
        children: <FormCustomsClearance disabledFields={selfEditMember} />,
        forceRender: true,
      });

    if (activeServicesTabs.memberCategories?.includes(MembersEnum.TRUCKING))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.trucking'),
        children: <FormTrucking disabledFields={selfEditMember} />,
        forceRender: true,
      });

    if (activeServicesTabs.memberCategories?.includes(MembersEnum.WAREHOUSING))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.warehousing'),
        children: <FormWarehousing disabledFields={selfEditMember} />,
        forceRender: true,
      });

    if (activeServicesTabs.partnerCategories?.includes(PartnersEnum.TERMINAL_PORTS))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.terminalPorts'),
        children: <FormTerminalPorts disabledFields={selfEditMember} />,
        forceRender: true,
      });

    if (activeServicesTabs.partnerCategories?.includes(PartnersEnum.OPERATORS))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.operators'),
        children: <FormOperators disabledFields={selfEditMember}  />,
        forceRender: true,
      });

    if (activeServicesTabs.partnerCategories?.includes(PartnersEnum.CONSULTANCY))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.consultancy'),
        children: <FormConsultancy disabledFields={selfEditMember}  />,
        forceRender: true,
      });

    if (activeServicesTabs.partnerCategories?.includes(PartnersEnum.OTHER))
      tabs.push({
        key: String(tabs.length + 1),
        label: t('companyCategories.other'),
        children: <FormOther disabledFields={selfEditMember}  />,
        forceRender: true,
      });

    return tabs;
  }, [activeServicesTabs.memberCategories, activeServicesTabs.partnerCategories, t, selfEditMember]);

  if (
    (!activeServicesTabs.memberCategories || activeServicesTabs.memberCategories.length === 0) &&
    (!activeServicesTabs.partnerCategories || activeServicesTabs.partnerCategories.length === 0)
  )
    return <Alert message={t('messages.selectMemberServicesFirst')} type='warning' showIcon />;

  return (
    <div className='servicesTabs'>
      {selfEditMember && (
        <Alert message={t('messages.onlyAdminCanEditServices')} type='warning' showIcon className={styles.alertEditOnlyAdmin} />
      )}
      <Tabs
        type='card'
        items={servicesTabs}
      />
    </div>
  );
};

export default FormServices;
