import React, { ReactElement } from 'react';
import { Badge } from 'antd';
import { UserStatus } from '../consts/UserStatus';
import config from '../config';
import { MembersEnum, PartnersEnum } from '../consts/UserSubcategories';

export type CloneType = Record<string, object | object[]>;

export const clone = (target: object | object[]): object | object[] => {
  if (typeof target === 'object') {
    if (target === null) return target;

    const cloneTarget: CloneType | object | object[] = Array.isArray(target) ? [] : {};
    for (const key in target) {
      (cloneTarget as CloneType)[key] = clone((target as CloneType)[key]);
    }
    return cloneTarget;
  } else {
    return target;
  }
};

export const truncateText = (text: string, limit: number): string => {
  if (text.length <= limit) return text;
  return text.substring(0, limit).concat('...');
};

export const getUserStatusBadge = ({ t, status }: { t: any; status: number }): ReactElement => {
  if (status === UserStatus.ENABLED)
    return <Badge color={config.userStatusColor.enabled} text={t('status.active')} />;
  if (status === UserStatus.ARCHIVED)
    return <Badge color={config.userStatusColor.archived} text={t('status.archived')} />;
  if (status === UserStatus.HIDDEN)
    return <Badge color={config.userStatusColor.hidden} text={t('status.hidden')} />;
  return <></>;
};

export const getMemberCategoriesLabelsEnum = (t: any): Record<string, string> => {
  const categories: Record<string, string> = {};

  categories[MembersEnum.FORWARDER] = t('companyCategories.forwarder');
  categories[MembersEnum.TRUCKING] = t('companyCategories.trucking');
  categories[MembersEnum.WAREHOUSING] = t('companyCategories.warehousing');
  categories[MembersEnum.CUSTOMS_CLEARANCE] = t('companyCategories.customsClearance');

  return categories;
};

export const getPartnerCategoriesLabelsEnum = (t: any): Record<string, string> => {
  const categories: Record<string, string> = {};

  categories[PartnersEnum.TERMINAL_PORTS] = t('companyCategories.terminalPorts');
  categories[PartnersEnum.OPERATORS] = t('companyCategories.operators');
  categories[PartnersEnum.CONSULTANCY] = t('companyCategories.consultancy');
  categories[PartnersEnum.OTHER] = t('companyCategories.other');

  return categories;
};
