import React, { ReactElement, useCallback } from 'react';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProfileOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { CompanyInterface } from '../../../models/companyInterface';
import config from '../../../config';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';

const AboutButtons = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const addHttp = useCallback((link: string): string => {
    if (link.includes('http')) return link;
    return 'http://' + link;
  }, []);

  if (company.companyPresentation?.fileId === undefined && company.videoLink === undefined)
    return <></>;

  return (
    <Space direction='horizontal' size='middle'>
      {company.companyPresentation?.fileId !== undefined && company.companyPresentation != null && (
        <Link
          to={`${config.fileViewBasePath.replace(
            ':assetId',
            company.companyPresentation.fileId,
          )}?token=${localStorageFileToken ?? sessionStorageFileToken}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button icon={<ProfileOutlined />} type='primary'>
            {t('misc.companyPresentation')}
          </Button>
        </Link>
      )}
      {company.videoLink !== undefined && company.videoLink != null && company.videoLink !== '' && (
        <Link to={addHttp(company.videoLink)} target='_blank' rel='noopener noreferrer'>
          <Button icon={<PlayCircleOutlined />}>{t('misc.videoPresentation')}</Button>
        </Link>
      )}
    </Space>
  );
};

export default AboutButtons;
