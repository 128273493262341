import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const FormTrucking = ({disabledFields} : {disabledFields?: boolean}): ReactElement => {
  const { t } = useTranslation('common');

  const { TextArea } = Input;

  return (
    <div>
      <Form.Item label={t('misc.truckingTypesOfTrucks')} name='truckingTypesOfTrucks'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item label={t('misc.truckingNumberOfTrucks')} name='truckingNumberOfTrucks'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item label={t('misc.truckingRoutesOfService')} name='truckingRoutesOfService'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item label={t('misc.truckingStrongPoints')} name='truckingStrongPoints'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.truckingParticularTypesOfCargo')}
        name='truckingParticularTypesOfCargo'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item label={t('misc.truckingUniqueSellingPoint')} name='truckingUniqueSellingPoint'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
    </div>
  );
};

export default FormTrucking;
