import React, { ReactElement } from 'react';
import EventForm from './components/eventForm';
import PageTitle from '../../components/pageTitle/pageTitle';
import { EventFormInterface } from './types';
import { UserRole } from '../../consts/UserRoleEnum';
import { useUserContext } from '../../context/user.context';
import { ItemPublished } from '../../consts/ItemPublishedEnum';

const AddEvent = (): ReactElement => {
  const { user } = useUserContext();
  const initialValues: EventFormInterface = {
    name: '',
    postSummary: '',
    published:
      user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN
        ? ItemPublished.TRUE
        : ItemPublished.FALSE,
  };

  return (
    <div>
      <PageTitle title={'Add event'} />
      <EventForm data={initialValues} />
    </div>
  );
};

export default AddEvent;
