import React, { ReactElement, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, message } from 'antd';
import styles from './index.module.css';
import { ModalInfoType } from '../../models/invoiceInterface';
import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

const ModalForm = ({
  modalType,
  setOpen,
  modalInfo,
  id,
}: {
  modalType: 'decline' | 'report';
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  modalInfo: ModalInfoType;
  id?: number;
}): ReactElement => {
  const { t } = useTranslation('common');
  const { TextArea } = Input;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const handleCancel = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        setLoading(true);
        const apiRoute =
          modalType === 'decline'
            ? config.apiRoutes.declineInvoice
            : config.apiRoutes.reportInvoice;
        const response = await axiosInstance.post(apiRoute.replace(':id', String(id)), values);

        if (response.status === 201 || response.status === 200) {
          const succesMessage =
            modalType === 'decline' ? t('messages.invoiceDeclined') : t('messages.invoiceReported');
          message.success(succesMessage);
          handleCancel();
          navigate(config.routes.allInvoices);
        }
        setLoading(false);
      } catch (error) {
        message.error(t('messages.errorTryLater'));
        setLoading(false);
      }
    },
    [handleCancel, id, modalType, t, navigate],
  );

  return (
    <>
      <div>
        <Form name='invoiceAction' layout='vertical' onFinish={handleSubmit}>
          <Form.Item
            name='reason'
            className={styles.textareaReason}
            label={modalInfo.label}
            rules={[
              {
                required: true,
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <TextArea
              showCount
              rows={6}
              placeholder={modalInfo.placeholder ?? ''}
              maxLength={200}
            />
          </Form.Item>
          <div className={styles.modalButtons}>
            <Button type='primary' htmlType='submit' loading={loading}>
              {t('buttons.submit')}
            </Button>
            <Button onClick={handleCancel}>{t('buttons.cancel')}</Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ModalForm;
