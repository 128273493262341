import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';

import styles from './logoBoxDisplay.module.css';
import config from '../../config';

const LogoBoxDisplay = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <div className={cx([styles.root, config.features.useProfilePic && styles.profilePic])}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default LogoBoxDisplay;
