import React, { ReactElement } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Badge, Space } from 'antd';
import config from '../../config';
import { TableDataType } from './types';
import { InvoiceStatus } from './invoiceStatus';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.css';

const baseColumns = ({
  t,
  receive,
  isAdmin,
}: {
  t: any;
  receive?: boolean;
  isAdmin?: boolean;
}): ColumnsType<TableDataType> => [
  {
    title: t('misc.value'),
    dataIndex: 'value',
    key: 'value',
    sorter: (a, b) => a.value.localeCompare(b.value),
    render: (value, row) => (
      <>
        {value} {row.currency}
      </>
    ),
  },
  {
    title: t('misc.issuedDate'),
    key: 'issuedDate',
    dataIndex: 'issuedDate',
    sorter: (a, b) => a.issuedDate.localeCompare(b.issuedDate),
    render: (issuedDate) => dayjs(issuedDate).format(config.dateFormat.universalFormat),
  },
  {
    title: t('misc.dueDate'),
    key: 'dueDate',
    dataIndex: 'dueDate',
    sorter: (a, b) => a.dueDate.localeCompare(b.dueDate),
    render: (value, row) => {
      let diffenceDays: number = 0;
      if (
        row.status !== InvoiceStatus.PAID &&
        row.status !== InvoiceStatus.ISSUE_RESOLVED &&
        dayjs().isAfter(dayjs(value))
      )
        diffenceDays = dayjs().diff(value, 'day');
      return (
        <>
          {dayjs(value).format(config.dateFormat.universalFormat)}
          {diffenceDays > 0 && <div className={styles.invoiceOverDue}>({diffenceDays} days)</div>}
        </>
      );
    },
  },
  {
    title: t('misc.status'),
    key: 'status',
    dataIndex: 'status',
    sorter: (a, b) => a.status - b.status,
    render: (_, { status, dueDate }) => getStatusBadge({ t, status, dueDate }),
  },
  {
    title: t('misc.action'),
    key: 'action',
    render: (_, row) => (
      <Space size='middle'>
        <Link to={config.routes.invoiceId.replace(':id', row.key)}>{t('buttons.view')}</Link>
        {isAdmin && (
          <Link to={config.routes.editInvoice.replace(':id', row.key)}>{t('buttons.edit')}</Link>
        )}
      </Space>
    ),
  },
];

export const getCompanyColumns = ({
  t,
  receive,
}: {
  t: any;
  receive?: boolean;
}): ColumnsType<TableDataType> => [
  {
    title: t('misc.invoice'),
    dataIndex: 'invoice',
    key: 'invoice',
    render: (invoice, row) => (
      <Link to={config.routes.invoiceId.replace(':id', row.key)}>{invoice}</Link>
    ),
  },
  {
    title: receive ? t('misc.from') : t('misc.to'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (name, row) => (
      <Link to={config.routes.invoiceId.replace(':id', row.key)}>
        {receive ? row.from : row.to}
      </Link>
    ),
  },
  ...baseColumns({ t, receive }),
];

export const getAdminColumns = ({
  t,
  receive,
}: {
  t: any;
  receive?: boolean;
}): ColumnsType<TableDataType> => [
  {
    title: t('misc.invoice'),
    dataIndex: 'invoice',
    key: 'invoice',
    sorter: (a, b) => a.from.localeCompare(b.from),
    render: (invoice, row) => (
      <Link to={config.routes.invoiceId.replace(':id', row.key)}>{invoice}</Link>
    ),
  },
  {
    title: t('misc.from'),
    dataIndex: 'from',
    key: 'from',
    sorter: false,
    render: (from, row) => (
      <Link to={config.routes.invoiceId.replace(':id', row.key)}>{row.from}</Link>
    ),
  },
  {
    title: t('misc.to'),
    dataIndex: 'to',
    key: 'to',
    sorter: false,
    render: (to, row) => <Link to={config.routes.invoiceId.replace(':id', row.key)}>{row.to}</Link>,
  },
  ...baseColumns({ t, isAdmin: true }),
];

export const getStatusBadge = ({
  t,
  status,
  dueDate,
}: {
  t: any;
  status: number;
  dueDate?: string | Dayjs;
}): ReactElement | undefined => {
  if (status === InvoiceStatus.PAID)
    return <Badge color={config.invoiceStatusColor.paid} text={t('status.paid')} />;
  if (status === InvoiceStatus.DECLINED)
    return <Badge color={config.invoiceStatusColor.declined} text={t('status.declined')} />;
  if (status === InvoiceStatus.REPORTED)
    return <Badge color={config.invoiceStatusColor.cancelled} text={t('status.reported')} />;
  if (status === InvoiceStatus.ISSUE_RESOLVED)
    return <Badge color={config.invoiceStatusColor.declined} text={t('status.issueResolved')} />;
  if (dayjs().isAfter(dayjs(dueDate)) && dayjs().diff(dueDate, 'day') > 0)
    return <Badge color={config.invoiceStatusColor.notPaid} text={t('status.overDue')} />;
  if (status === InvoiceStatus.NOT_PAID)
    return <Badge color={config.invoiceStatusColor.notPaid} text={t('status.notPaid')} />;
};
