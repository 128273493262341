import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const Other = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const otherData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.otherKindOfService)
      prepareData.push({ name: t('misc.otherKindOfService'), content: company.otherKindOfService });

    if (company.otherGeographicalRegionsCovered)
      prepareData.push({ name: t('misc.otherGeographicalRegionsCovered'), content: company.otherGeographicalRegionsCovered });

    if (company.otherWhereAreYourDepotsLocated)
      prepareData.push({ name: t('misc.otherWhereAreYourDepotsLocated'), content: company.otherWhereAreYourDepotsLocated });

    if (company.otherUniqueSellingPoint)
      prepareData.push({ name: t('misc.otherUniqueSellingPoint'), content: company.otherUniqueSellingPoint });

    if (company.otherWhenShouldAMemberContactYou)
      prepareData.push({ name: t('misc.otherWhenShouldAMemberContactYou'), content: company.otherWhenShouldAMemberContactYou });

    if (company.otherExperienceRelatingToBeltAndRoadInitiative)
      prepareData.push({ name: t('misc.otherExperienceRelatingToBeltAndRoadInitiative'), content: company.otherExperienceRelatingToBeltAndRoadInitiative });

    return prepareData;
  }, [company, t]);

  if (otherData.length === 0) return <></>;

  return <CustomTable data={otherData} />;
};

export default Other;
