import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './companiesTable.module.css';
import TableColumns from './columns';
import DataTable from '../../../components/DataTable/dataTable';
import { CompaniesTableDataType } from '../../../models/companyInterface';
import { CompaniesFilterParamsType, CompaniesListResponse } from '../types';

const CompaniesTable = ({
  dataTable,
  handleSetParams,
  companiesData,
}: {
  dataTable: CompaniesTableDataType[];
  handleSetParams: (newParams: CompaniesFilterParamsType) => void;
  companiesData?: CompaniesListResponse;
}): ReactElement => {
  const { t } = useTranslation('common');

  if (companiesData === undefined) return <>{t('messages.noResults')}</>;

  const columns = TableColumns();

  return (
    <div className={styles.root}>
      <DataTable
        columns={columns}
        items={dataTable}
        total={companiesData.meta.totalItems}
        page={companiesData.meta.currentPage}
        itemsPerPage={companiesData.meta.itemsPerPage}
        setParams={handleSetParams}
      />
    </div>
  );
};

export default CompaniesTable;
