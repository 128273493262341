import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getArticles } from '../../services/services';
import {
  ArticleEntity,
  ArticleListResponse,
  ArticlesFilterParamsType,
  TableDataType,
} from './types';
import Filters from './components/filters/filters';
import useDebounce from '../../hooks/useDebounce';
import { getColumns } from './utils';
import DataTable from '../../components/DataTable/dataTable';
import { useUserContext } from '../../context/user.context';
import PageTitle from '../../components/pageTitle/pageTitle';
import { UserRole } from '../../consts/UserRoleEnum';
import config from '../../config';
import useDownloadFile from '../../hooks/useDownloadFile';

const ListArticles = (): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();
  const [articles, setArticles] = useState<ArticleListResponse>();
  const [params, setParams] = useState<ArticlesFilterParamsType>({});
  const debouncedParams = useDebounce(params, 1000);
  const { downloadFile, loading } = useDownloadFile();

  const fetchData = useCallback(async (): Promise<void> => {
    const articlesData = await getArticles(params);
    setArticles(articlesData);
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [debouncedParams, fetchData]);

  const handleSetParams = (newParams: ArticlesFilterParamsType): void => {
    const paramsData = { ...params, ...newParams };
    if (!newParams.page) {
      paramsData.page = 1;
    }
    setParams(paramsData);
  };

  const columns = getColumns(t, user.role as string);

  const dataTable = articles?.items.reduce((res: TableDataType[], article: ArticleEntity) => {
    res.push({
      key: article.id.toString(),
      name: article.name,
      publishDate: article.publishDate ?? '',
      published: article.published,
    });
    return res;
  }, []);

  const exportArticles = (): void => {
    downloadFile(config.apiRoutes.exportArticles, `${t('misc.export')} ${t('pageTitles.articles')}.csv`);
  }

  const TitleButtons = (): ReactElement => {
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN)
      return (
        <Button icon={<ExportOutlined />} onClick={exportArticles} loading={loading}>{t('buttons.exportAll')}</Button>
      );
    return <></>;
  };

  return (
    <div>
      <PageTitle title={t('pageTitles.articles')} buttons={TitleButtons()} />
      <Filters setParams={handleSetParams} />
      {articles != null && dataTable != null && (
        <DataTable
          columns={columns}
          items={dataTable}
          total={articles.meta.totalItems}
          page={articles.meta.currentPage}
          setParams={handleSetParams}
          itemsPerPage={articles.meta.itemsPerPage}
        />
      )}
    </div>
  );
};

export default ListArticles;
