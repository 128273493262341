import React, { ReactElement, useMemo } from 'react';
import { Card, Col, Row, Space } from 'antd';
import { CompanyInterface } from '../../../models/companyInterface';
import styles from './index.module.css';
import AboutTable from './aboutTable';
import AddressTable from './addressTable';
import KeepInTouchTable from './keepInTouchTable';
import ContactsCard from './contactsCard';
import AboutButtons from './aboutButtons';
import PaymentProtectionTable from './paymentProtectionTable';
import config from '../../../config';

const CompanyAboutSection = ({ company }: { company: CompanyInterface }): ReactElement => {
  const existMembers = useMemo((): boolean => {
    if (company.contacts === undefined || company.contacts?.length === 0) return false;
    return true;
  }, [company]);

  return (
    <div className={styles.root}>
      <Row gutter={50}>
        <Col xs={24} lg={existMembers ? 17 : 24}>
          <Card className={styles.card}>
            <Space direction='vertical' size='middle' className={styles.space}>
              <AboutTable company={company} />
              {config.features.paymentProtection && <PaymentProtectionTable company={company} />}
              {!config.features.displayAddressInField && <AddressTable company={company} />}
              <KeepInTouchTable company={company} />
              {(company.companyPresentation !== null || company.videoLink !== null) && (
                <AboutButtons company={company} />
              )}
            </Space>
          </Card>
        </Col>
        {existMembers && (
          <Col xs={24} lg={existMembers ? 7 : 24} className={styles.teamBox}>
            <Card className={styles.card}>
              <Space direction='vertical' size='middle' className={styles.space}>
                <ContactsCard company={company} />
              </Space>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CompanyAboutSection;
