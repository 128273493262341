import React, { ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './filters.module.css';
import { InvoicesFilterParamsType } from '../types';
import StatusFilter from '../../../components/Filters/statusFilter';
import { InvoiceStatus } from '../invoiceStatus';
import NameFilter from '../../companies/filters/nameFilter';

const Filters = ({
  setParams,
  setSearchTerm,
}: {
  setParams: (newParams: InvoicesFilterParamsType) => void;
  setSearchTerm: React.Dispatch<SetStateAction<string | undefined>>;
}): ReactElement => {
  const { t } = useTranslation('common');

  const statusOptions = [
    {
      label: t('status.paid'),
      value: InvoiceStatus.PAID,
    },
    {
      label: t('status.declined'),
      value: InvoiceStatus.DECLINED,
    },
    {
      label: t('status.reported'),
      value: InvoiceStatus.REPORTED,
    },
    {
      label: t('status.overDue'),
      value: InvoiceStatus.OVERDUE,
    },
    {
      label: t('status.notPaid'),
      value: InvoiceStatus.NOT_PAID,
    },
    {
      label: t('status.issueResolved'),
      value: InvoiceStatus.ISSUE_RESOLVED,
    },
  ];

  const handleStatus = (status: number | undefined): void => {
    setParams({ status });
  };

  const handleSearch = async (value: string | undefined): Promise<void> => {
    setSearchTerm(value);
  };

  return (
    <div className={styles.root}>
      <NameFilter onChange={handleSearch} />
      <StatusFilter onChange={handleStatus} options={statusOptions} />
    </div>
  );
};

export default Filters;
