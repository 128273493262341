import React, { ReactElement, useMemo } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import { MembersEnum, PartnersEnum } from '../../../consts/UserSubcategories';
import Trucking from './trucking';
import Warehousing from './warehousing';
import CustomsClearance from './customsClearance';
import Forwarder from './forwarder';
import TerminalPorts from './terminalPorts';
import Operators from './operators';
import Consultancy from './consultancy';
import Other from './other';

const CompanyServicesSection = ({ company }: { company: CompanyInterface }): ReactElement => {

  const { t } = useTranslation('common');

  const servicesTabs = useMemo((): TabsProps['items'] => [
    ... company.memberCategories?.includes(MembersEnum.FORWARDER) ? [{
      key: MembersEnum.FORWARDER,
      label: t('companyCategories.forwarder'),
      children: <Forwarder company={company} />,
      forceRender: true,
    }] : [],
    ... company.memberCategories?.includes(MembersEnum.CUSTOMS_CLEARANCE) ? [{
      key: MembersEnum.CUSTOMS_CLEARANCE,
      label: t('companyCategories.customsClearance'),
      children: <CustomsClearance company={company} />,
      forceRender: true,
    }] : [],
    ... company.memberCategories?.includes(MembersEnum.TRUCKING) ? [{
      key: MembersEnum.TRUCKING,
      label: t('companyCategories.trucking'),
      children: <Trucking company={company} />,
      forceRender: true,
    }] : [],
    ... company.memberCategories?.includes(MembersEnum.WAREHOUSING) ? [{
      key: MembersEnum.WAREHOUSING,
      label: t('companyCategories.warehousing'),
      children: <Warehousing company={company} />,
      forceRender: true,
    }] : [],
    ... company.partnerCategories?.includes(PartnersEnum.TERMINAL_PORTS) ? [{
      key: PartnersEnum.TERMINAL_PORTS,
      label: t('companyCategories.terminalPorts'),
      children: <TerminalPorts company={company} />,
      forceRender: true,
    }] : [],
    ... company.partnerCategories?.includes(PartnersEnum.OPERATORS) ? [{
      key: PartnersEnum.OPERATORS,
      label: t('companyCategories.operators'),
      children: <Operators company={company} />,
      forceRender: true,
    }] : [],
    ... company.partnerCategories?.includes(PartnersEnum.CONSULTANCY) ? [{
      key: PartnersEnum.CONSULTANCY,
      label: t('companyCategories.consultancy'),
      children: <Consultancy company={company} />,
      forceRender: true,
    }] : [],
    ... company.partnerCategories?.includes(PartnersEnum.OTHER) ? [{
      key: PartnersEnum.OTHER,
      label: t('companyCategories.other'),
      children: <Other company={company} />,
      forceRender: true,
    }] : [],
  ], [company, t]);

  if (servicesTabs?.length === 0 ) return <></>;

  return (
    <div className="servicesTabs">
      <Tabs
        type='card'
        items={servicesTabs}
      />
    </div>
  );
};

export default CompanyServicesSection;
