import React, { ReactElement, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import {
  TeamOutlined,
  DeploymentUnitOutlined,
  PhoneOutlined,
  MailOutlined,
  LinkedinOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { CompanyInterface } from '../../../models/companyInterface';
import CardTitle from '../../../components/cardTitle/cardTitle';
import styles from './index.module.css';

const ContactsCard = ({
  company,
}: {
  company: CompanyInterface;
  className?: string;
}): ReactElement => {
  const { t } = useTranslation('common');

  const contactLine = useCallback(
    (label: string, icon: ReactElement, link?: string): ReactElement => {
      return (
        <div className={styles.contactLine}>
          {link !== undefined && link !== '' ? (
            <Link to={link.replace(' ', '')}>
              {icon} {label}
            </Link>
          ) : (
            <>
              {icon} {label}
            </>
          )}
        </div>
      );
    },
    [],
  );

  const emailAll = useMemo((): string => {
    if (company.contacts !== undefined && company.contacts?.length < 2) return '';
    let prepareEmails: string = '';
    company.contacts?.forEach((contact) => {
      if (contact.email !== undefined) prepareEmails += contact.email + ',';
    });
    if (prepareEmails !== '') prepareEmails = 'mailto:' + prepareEmails;
    return prepareEmails;
  }, [company.contacts]);

  return (
    <>
      <CardTitle type='bold' title={t('sidebar.navigation.team')} icon={<TeamOutlined />} />
      <Space direction='vertical' size={10}>
        {company.contacts?.map((contact) => {
          return (
            <div key={contact.name}>
              <Space direction='vertical' size={3}>
                <div>
                  <big>
                    <strong>{contact.name}</strong>
                  </big>
                </div>
                {contact.position && contactLine(contact.position, <DeploymentUnitOutlined />)}
                {contact.email &&
                  contactLine(contact.email, <MailOutlined />, 'mailto:' + contact.email)}
                {contact.phoneNo &&
                  contactLine(contact.phoneNo, <PhoneOutlined />, 'tel:' + contact.phoneNo)}
                {contact.phoneNo2 &&
                  contactLine(contact.phoneNo2, <PhoneOutlined />, 'tel:' + contact.phoneNo2)}
                {contact.linkedin &&
                  contactLine(t('misc.linkedin'), <LinkedinOutlined />, contact.linkedin)}
              </Space>
            </div>
          );
        })}
        {emailAll !== '' && (
          <Button icon={<SendOutlined />} href={emailAll} target='_blank' rel='noopener noreferrer'>
            {t('buttons.emailAll')}
          </Button>
        )}
      </Space>
    </>
  );
};

export default ContactsCard;
