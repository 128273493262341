import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { getEvents } from '../../services/services';
import { EventEntity, EventListResponse, EventsFilterParamsType, TableDataType } from './types';
import Filters from './components/filters/filters';
import useDebounce from '../../hooks/useDebounce';
import { getColumns } from './utils';
import DataTable from '../../components/DataTable/dataTable';
import PageTitle from '../../components/pageTitle/pageTitle';
import { useUserContext } from '../../context/user.context';
import { UserRole } from '../../consts/UserRoleEnum';
import config from '../../config';
import useDownloadFile from '../../hooks/useDownloadFile';

const ListEvents = (): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();
  const [events, setEvents] = useState<EventListResponse>();
  const [params, setParams] = useState<EventsFilterParamsType>({});
  const debouncedParams = useDebounce(params, 1000);
  const { downloadFile, loading } = useDownloadFile();

  const fetchData = useCallback(async (): Promise<void> => {
    const eventsData = await getEvents(params);
    setEvents(eventsData);
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [debouncedParams, fetchData]);

  const handleSetParams = (newParams: EventsFilterParamsType): void => {
    const paramsData = { ...params, ...newParams };
    if (!newParams.page) {
      paramsData.page = 1;
    }
    setParams(paramsData);
  };

  const columns = getColumns(t);

  const dataTable = events?.items.reduce((res: TableDataType[], event: EventEntity) => {
    res.push({
      key: event.id.toString(),
      name: event.name,
      publishDate: event.publishDate ?? '',
      published: event.published,
    });
    return res;
  }, []);

  const exportEvents = (): void => {
    downloadFile(config.apiRoutes.exportEvents, `${t('misc.export')} ${t('pageTitles.events')}.csv`);
  }

  const TitleButtons = (): ReactElement => {
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN)
      return (
        <Button icon={<ExportOutlined />} onClick={exportEvents} loading={loading}>{t('buttons.exportAll')}</Button>
      );
    return <></>;
  };

  return (
    <div>
      <PageTitle title={t('pageTitles.events')} buttons={TitleButtons()} />
      <Filters setParams={handleSetParams} />
      {events != null && dataTable != null && (
        <DataTable
          columns={columns}
          items={dataTable}
          total={events.meta.totalItems}
          page={events.meta.currentPage}
          setParams={handleSetParams}
          itemsPerPage={events.meta.itemsPerPage}
        />
      )}
    </div>
  );
};

export default ListEvents;
