import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { InvoiceInitialValues } from '../../models/invoiceInterface';
import styles from './index.module.css';
import { InvoiceStatus } from '../invoices/invoiceStatus';

const InvoiceMessages = ({ invoiceData }: { invoiceData: InvoiceInitialValues }): ReactElement => {
  const { t } = useTranslation('common');

  if (
    invoiceData.status !== InvoiceStatus.REPORTED &&
    invoiceData.status !== InvoiceStatus.DECLINED
  )
    return <></>;

  return (
    <div className={styles.invoiceMessages}>
      {invoiceData.status === InvoiceStatus.DECLINED && invoiceData.declineReason && (
        <Alert
          message={t('messages.invoiceDeclined')}
          description={invoiceData.declineReason}
          type='error'
          showIcon
        />
      )}
      {invoiceData.status === InvoiceStatus.REPORTED && invoiceData.reportReason && (
        <Alert
          message={t('messages.invoiceReported')}
          description={invoiceData.reportReason}
          type='error'
          showIcon
        />
      )}
    </div>
  );
};

export default InvoiceMessages;
