import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Result status='404' title={t('404.title')} subTitle={t('404.subtitle')} />
      <Row justify='center'>
        <Link to='/'>{t('404.homeButton')}</Link>
      </Row>
    </div>
  );
};

export default NotFound;
