import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import type { SorterResult, FilterValue } from 'antd/es/table/interface';
import type { TablePaginationConfig } from 'antd/es/table';
import styles from './dataTable.module.css';
import { PaginationProps, Table } from 'antd';
import { TableFilterParamsType } from './types';
import config from '../../config';

interface DataTableInterface {
  items: any[];
  total: number;
  page: number;
  setParams: (params: any) => void;
  columns: ColumnsType<any>;
  itemsPerPage: number;
}

const DataTable = ({
  items,
  columns,
  setParams,
  total,
  page,
  itemsPerPage,
}: DataTableInterface): ReactElement => {
  const { t } = useTranslation('common');

  const pageSizeOptions = useMemo(() => {
    return config.tableInfo.pageSizeOptions.filter(
      (size) => size <= Math.ceil(total + 1 / 10) * 10,
    );
  }, [total]);

  if (items.length === 0) return <>{t('messages.noResults')}</>;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | Array<SorterResult<any>>,
  ): void => {
    const sort = JSON.parse(JSON.stringify(sorter));
    let params: TableFilterParamsType = {
      page: pagination.current,
      limit: pagination.pageSize,
    };

    if (typeof sort.order !== 'undefined') {
      params = {
        ...params,
        orderField: sort.field,
        orderType: sort.order === 'ascend' ? 'asc' : 'desc',
      };
    }

    setParams(params);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = async (current, pageSize) => {
    const params: TableFilterParamsType = {
      page: current,
      limit: pageSize,
    };
    setParams(params);
  };

  return (
    <div className={styles.root}>
      <Table
        columns={columns}
        dataSource={items}
        className={styles.table}
        pagination={{
          current: page,
          defaultCurrent: 1,
          pageSize: itemsPerPage,
          onShowSizeChange,
          showSizeChanger: true,
          pageSizeOptions,
          total,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default DataTable;
