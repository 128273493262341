import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const FormCustomsClearance = ({disabledFields} : {disabledFields?: boolean}): ReactElement => {
  const { t } = useTranslation('common');

  const { TextArea } = Input;

  return (
    <div>
      <Form.Item
        label={t('misc.customsClearanceUniqueSellingPoint')}
        name='customsClearanceUniqueSellingPoint'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.customsClearanceRegionsAndRoutes')}
        name='customsClearanceRegionsAndRoutes'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.customsClearanceParticularTypesOfCargo')}
        name='customsClearanceParticularTypesOfCargo'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
    </div>
  );
};

export default FormCustomsClearance;
