import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const Trucking = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const truckingData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.truckingTypesOfTrucks)
      prepareData.push({ name: t('misc.truckingTypesOfTrucks'), content: company.truckingTypesOfTrucks });

    if (company.truckingNumberOfTrucks)
      prepareData.push({ name: t('misc.truckingNumberOfTrucks'), content: company.truckingNumberOfTrucks });

    if (company.truckingRoutesOfService)
      prepareData.push({ name: t('misc.truckingRoutesOfService'), content: company.truckingRoutesOfService });

    if (company.truckingStrongPoints)
      prepareData.push({ name: t('misc.truckingStrongPoints'), content: company.truckingStrongPoints });

    if (company.truckingParticularTypesOfCargo)
      prepareData.push({ name: t('misc.truckingParticularTypesOfCargo'), content: company.truckingParticularTypesOfCargo });

    if (company.truckingUniqueSellingPoint)
      prepareData.push({ name: t('misc.truckingUniqueSellingPoint'), content: company.truckingUniqueSellingPoint });

    return prepareData;
  }, [company, t]);

  if (truckingData.length === 0) return <></>;

  return <CustomTable data={truckingData} />;
};

export default Trucking;
