import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Alert, Typography } from 'antd';
import styles from './forgotPassword.module.css';
import useForgotPassword from '../../hooks/useForgotPassword';
import Logo from '../../components/logo/logo';
import config from '../../config';

const ForgotPassword: React.FC = () => {
  const { handleSubmit, loading, error, success } = useForgotPassword();
  const { t } = useTranslation('common');

  return (
    <div>
      <div className={styles.logoWrapper}>
        <Logo width={250} />
      </div>
      {!success ? (
        <Form name='forgotPass' className={styles.loginForm} onFinish={handleSubmit}>
          {error != null && (
            <Form.Item>
              <Alert message={error} type='error' />
            </Form.Item>
          )}
          <Form.Item
            name='emailOrUsername'
            rules={[
              {
                required: true,
                message: t('login.required.emailOrUsername') ?? '',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder={t('misc.emailOrUsername') ?? ''}
            />
          </Form.Item>
          <Form.Item>
            <div className={styles.spaceBetween}>
              <Button
                type='primary'
                htmlType='submit'
                size='large'
                className={cx('login-form-button', styles.loginBtn)}
                loading={loading}
                disabled={loading}
              >
                {t('login.submit')}
              </Button>
              <Link to={config.routes.login}>
                <Button type='primary' size='large'>
                  {t('buttons.back')}
                </Button>
              </Link>
            </div>
          </Form.Item>
        </Form>
      ) : (
        <>
          <Typography>{t('login.forgotPasswordSuccess')}</Typography>
          <Link to={config.routes.login}>
            <Button type='primary' size='large'>
              {t('buttons.back')}
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
