import React, { ReactElement, useEffect, useState } from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Alert,
  Typography,
  Layout,
  Select,
  Badge,
  Upload,
  Radio,
  UploadFile,
  Popconfirm,
} from 'antd';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import useUploadField from '../../../hooks/useUploadField';
import TextArea from 'antd/es/input/TextArea';
import { UserRole } from '../../../consts/UserRoleEnum';
import TextEditor from '../../../components/TextEditor/TextEditor';
import useArticleForm from '../useArticleForm';
import { useUserContext } from '../../../context/user.context';
import { ArticleFormInterface, ArticleFormProps } from '../types';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';
import styles from './index.module.css';
import { ItemPublished } from '../../../consts/ItemPublishedEnum';

export interface FieldError {
  name: Array<string | number>;
  errors: string[];
  warnings: string[];
}

const ArticleForm = ({ data }: ArticleFormProps): ReactElement => {
  const { beforeUploadImage } = useUploadField();
  const { user } = useUserContext();
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState<ArticleFormInterface>();
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);

  useEffect(() => {
    const formattedData = {
      ...data,
      published: [ItemPublished.FALSE, ItemPublished.NOT_SAVED_AND_DRAFT_IN_PROGRESS].includes(
        data.published,
      )
        ? ItemPublished.FALSE
        : ItemPublished.TRUE,
    };
    setFormData(formattedData);
  }, [data]);

  const {
    form,
    handleSubmit,
    handleEditorChange,
    body,
    submitErrors,
    defaultPhoto,
    handleDefaultPhoto,
    loading,
    handleDelete,
  } = useArticleForm({ data });

  useEffect(() => {
    form.resetFields();
  }, [form, formData]);

  const renderPhotoBlock = (
    originNode: ReactElement,
    file: UploadFile,
    _fileList: UploadFile[],
    actions: { remove: any },
  ): ReactElement => {
    return (
      <div className='uploaded-image-wrapper inline-block' key={file.uid}>
        <img
          src={
            typeof file.originFileObj !== 'undefined'
              ? URL.createObjectURL(file.originFileObj as File)
              : `${file.url}?token=${localStorageFileToken ?? sessionStorageFileToken}`
          }
          className='uploaded-image'
          alt='photo'
        />
        {file.url && (
          <Input
            value={file.url}
            readOnly
            onFocus={(e) => {
              e.target.select();
            }}
          />
        )}
        <Button
          icon={<DeleteOutlined />}
          onClick={actions.remove}
          className='remove-uploaded-image'
        />
        {(user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) && (
          <Radio value={file.uid}>Default</Radio>
        )}
      </div>
    );
  };

  return (
    <div className='panel white-bg'>
      {formData && (
        <Form
          form={form}
          initialValues={formData}
          onFinish={handleSubmit}
          layout='vertical'
          disabled={loading}
        >
          <Row gutter={50}>
            <Col span={24}>
              <Form.Item
                label={t('misc.title')}
                name='name'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <Input maxLength={250} />
              </Form.Item>
              <Form.Item
                label={t('misc.summary')}
                name='postSummary'
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredField') ?? '',
                  },
                ]}
              >
                <TextArea maxLength={95} />
              </Form.Item>
              <Space direction='vertical' className='full-width'>
                <Space direction='vertical' className='full-width'>
                  {(user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) && (
                    <>
                      <Typography.Text>
                        <Typography.Text type='danger' className='vertical-center'>
                          *{' '}
                        </Typography.Text>
                        {t('misc.body')}
                      </Typography.Text>
                      <TextEditor
                        textareaName='body2'
                        onEditorChange={handleEditorChange}
                        value={body ?? ''}
                      />
                    </>
                  )}
                  {user.role === UserRole.MEMBER && (
                    <Form.Item
                      label={t('misc.body')}
                      name='body'
                      rules={[
                        {
                          required: true,
                          message: t('messages.requiredField') ?? '',
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  )}
                </Space>
                <div style={{ marginTop: '-40px' }}>
                  <Form.Item
                    name='body'
                    rules={[
                      {
                        required: true,
                        message: t('messages.requiredField') ?? '',
                      },
                    ]}
                  />
                </div>
                <Layout className='white-bg'>
                  <Radio.Group onChange={handleDefaultPhoto} value={defaultPhoto}>
                    <div className='full-width photos-wrapper'>
                      {data !== null && (
                        <Form.Item
                          name='uploadedPhotos'
                          valuePropName='list'
                          label={t('misc.photos')}
                          tooltip={t('tooltip.onlyImages').replace(
                            ':fileSize',
                            String(config.limits.maxImageSize),
                          )}
                        >
                          <Upload
                            multiple={true}
                            accept={config.acceptedFileFormats.imagesFormats}
                            beforeUpload={beforeUploadImage}
                            defaultFileList={[...(data.uploadedPhotos?.fileList ?? [])]}
                            showUploadList={true}
                            itemRender={renderPhotoBlock}
                          >
                            <Button icon={<UploadOutlined />} type='primary'>
                              {t('buttons.upload')}
                            </Button>
                          </Upload>
                        </Form.Item>
                      )}
                    </div>
                  </Radio.Group>
                </Layout>

                {(user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) && (
                  <>
                    <Layout className='white-bg'>
                      <Form.Item
                        label={t('misc.status')}
                        name='published'
                        rules={[
                          {
                            required: true,
                            message: t('messages.requiredField') ?? '',
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value={ItemPublished.TRUE}>
                            <Badge color='#52C41A' text={t('status.published')} />
                          </Select.Option>
                          <Select.Option value={ItemPublished.FALSE}>
                            <Badge color='#FF4D4F' text={t('status.draft')} />
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Layout>
                  </>
                )}
                {submitErrors !== null && (
                  <Space direction='vertical' key={'errors'}>
                    <Alert message={submitErrors} type='error' showIcon />
                  </Space>
                )}
                <Space direction='horizontal' className={styles.buttonsWrap}>
                  <Button type='primary' htmlType='submit' key='button' loading={loading}>
                    {t('buttons.submit')}
                  </Button>
                  {data.id && (
                    <Popconfirm
                      title={t('misc.confirmDeletion')}
                      description={t('messages.areYouSureArticle')}
                      onConfirm={handleDelete}
                      okText={t('buttons.yes')}
                      cancelText={t('buttons.no')}
                    >
                      <Button icon={<DeleteOutlined />} loading={loading}>
                        {t('buttons.delete')}
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default ArticleForm;
