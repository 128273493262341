import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const FormConsultancy = ({disabledFields} : {disabledFields?: boolean}): ReactElement => {
  const { t } = useTranslation('common');

  const { TextArea } = Input;

  return (
    <div>
      <Form.Item label={t('misc.consultancyTypeOfService')} name='consultancyTypeOfService'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.consultancyUniqueSellingPoint')}
        name='consultancyUniqueSellingPoint'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.consultancyGeographicalAreasAlongTheBeltRoadInitiative')}
        name='consultancyGeographicalAreasAlongTheBeltRoadInitiative'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
    </div>
  );
};

export default FormConsultancy;
