import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StarOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, message } from 'antd';
import styles from './reviews.module.css';
import config from '../../../config';
import { axiosInstance } from '../../../api/AxiosInstance';
import CardTitle from '../../../components/cardTitle/cardTitle';

const ReviewForm = ({
  id,
  setReviewsData,
}: {
  id: string;
  setReviewsData: () => void;
}): ReactElement => {
  const { t } = useTranslation('common');
  const { TextArea } = Input;
  const [showForm, setShowForm] = useState<boolean>(false);

  const onShowForm = (): void => {
    if (showForm) form.resetFields();
    setShowForm(!showForm);
  };

  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        const content = form.getFieldValue('content');
        const response = await axiosInstance.post(config.apiRoutes.reviews, {
          memberId: id,
          content,
        });

        if (response.status === 201 || response.status === 200) {
          message.success(t('messages.successReview'));
          form.resetFields();
          setReviewsData();
        }
      } catch (error) {
        message.error(t('messages.errorTryLater'));
      }
    },
    [form, id, t, setReviewsData],
  );

  return (
    <div className={styles.root}>
      {!showForm ? (
        <Button icon={<StarOutlined />} onClick={onShowForm}>
          {t('buttons.rateCompany')}
        </Button>
      ) : (
        <Card>
          <Form name='review' layout='vertical' form={form} onFinish={handleSubmit}>
            <CardTitle title={t('misc.addReview')} type='bold' size='big' />
            <Form.Item
              name='content'
              className={styles.textareaReason}
              rules={[
                {
                  required: true,
                  message: t('messages.requiredField') ?? '',
                },
              ]}
            >
              <TextArea
                showCount
                rows={6}
                placeholder={t('messages.howWasCollab') ?? ''}
                maxLength={500}
              />
            </Form.Item>
            <Space>
              <Button type='primary' htmlType='submit' icon={<StarOutlined />}>
                {t('buttons.submit')}
              </Button>
              <Button onClick={onShowForm} icon={<CloseOutlined />}>
                {t('buttons.cancel')}
              </Button>
            </Space>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default ReviewForm;
