import { useEffect, useMemo, useState } from 'react';

interface ResponsiveHook {
  isMobileLayout: boolean;
}

const useResponsiveLayout = (): ResponsiveHook => {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleWindowResize = (): void => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isMobileLayout = useMemo((): boolean => {
    if (windowSize[0] < 1000) return true;
    return false;
  }, [windowSize]);

  return {
    isMobileLayout,
  };
};

export default useResponsiveLayout;
