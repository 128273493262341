import React, { Dispatch, createContext, ReactElement, ReactNode, useState } from 'react';

interface NotificationContextType {
  updated: boolean;
  setUpdated: Dispatch<React.SetStateAction<boolean>>;
}

export const NotificationContext = createContext<NotificationContextType>({
  updated: false,
  setUpdated: () => undefined,
});

export const NotificationProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [updated, setUpdated] = useState<boolean>(false);

  return (
    <NotificationContext.Provider value={{ updated, setUpdated }}>
      {children}
    </NotificationContext.Provider>
  );
};
