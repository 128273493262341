import { UploadFile } from 'antd';
import { InvoiceDocumentInterface, InvoiceExistingFiles } from '../../models/invoiceInterface';
import config from '../../config';

export const processDocs = (docs: InvoiceExistingFiles[]): UploadFile[] => {
  const processedDocs: UploadFile[] = docs.map((doc) => ({
    uid: doc.fileId,
    name: doc.fileName,
    url: config.fileViewBasePath.replace(':assetId', doc.fileId),
  }));
  return processedDocs;
};

export const processInvoice = (
  invoice: InvoiceDocumentInterface,
): InvoiceDocumentInterface | undefined => {
  if (invoice.fileId === undefined || invoice.fileName === undefined) return undefined;
  return {
    ...invoice,
    fileList: [
      {
        uid: invoice.fileId,
        name: invoice.fileName,
      },
    ],
  };
};
