import { useCallback, useEffect, useState, useContext } from 'react';
import { InvoiceInitialValues, InvoiceInterface } from '../../models/invoiceInterface';
import dayjs from 'dayjs';
import { processDocs, processInvoice } from './utils';
import { useParams } from 'react-router-dom';
import { getInvoice, getNotifications } from '../../services/services';
import { NotificationModule } from '../../consts/NotificationModuleEnum';
import { NotificationContext } from '../../context/notification.context';
import config from '../../config';

interface InvoiceHook {
  editInvoiceData?: InvoiceInterface;
  invoiceData?: InvoiceInitialValues;
  loading: boolean;
  id?: string;
}

const useInvoice = ({ isEdit }: { isEdit: boolean }): InvoiceHook => {
  const [editInvoiceData, setEditInvoiceData] = useState<InvoiceInterface>();
  const [invoiceData, setInvoiceData] = useState<InvoiceInitialValues>();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const { setUpdated } = useContext(NotificationContext);

  const prepareInvoiceDocs = useCallback(
    async (invoice: InvoiceInitialValues): Promise<InvoiceInterface> => {
      const preparedInvoice = invoice as InvoiceInterface;

      if (!isEdit) return preparedInvoice;

      if (invoice?.documents !== undefined && Array.isArray(invoice.documents)) {
        const docs = invoice.documents;
        preparedInvoice.documents = {};
        preparedInvoice.documents.fileList = processDocs(docs);
      }
      if (invoice?.invoice !== undefined) {
        invoice.invoice = processInvoice(invoice.invoice);
      }
      return preparedInvoice;
    },
    [isEdit],
  );

  const prepareDates = useCallback(
    async (invoice: InvoiceInitialValues): Promise<InvoiceInitialValues> => {
      if (invoice?.dueDate !== undefined)
        invoice.dueDate = dayjs(invoice.dueDate, config.dateFormat.universalFormat);
      if (invoice?.issuedDate !== undefined)
        invoice.issuedDate = dayjs(invoice.issuedDate, config.dateFormat.universalFormat);
      return invoice;
    },
    [],
  );

  const setInvoice = useCallback(
    async (id: string): Promise<void> => {
      setLoading(true);
      const invoice = await getInvoice(id);
      if (invoice === undefined) {
        setLoading(false);
        return;
      }
      const preparedInvoiceDates = await prepareDates(invoice);
      if (isEdit) {
        const preparedInvoiceDocs = await prepareInvoiceDocs(preparedInvoiceDates);
        setEditInvoiceData(preparedInvoiceDocs);
        setLoading(false);
        return;
      }
      setInvoiceData(preparedInvoiceDates);
      await getNotifications({ itemId: id, module: NotificationModule.INVOICE });
      setUpdated(false);
      setLoading(false);
    },
    [isEdit, prepareDates, prepareInvoiceDocs, setUpdated],
  );

  useEffect(() => {
    if (id !== undefined) setInvoice(id);
  }, [id, setInvoice]);

  return {
    editInvoiceData,
    invoiceData,
    loading,
    id,
  };
};

export default useInvoice;
