import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CompanyBox from '../companyBox/companyBox';
import styles from './companyBoxList.module.css';
import { CompaniesListResponse, CompaniesFilterParamsType } from '../types';
import { Pagination, PaginationProps } from 'antd';
import config from '../../../config';

const CompanyBoxList = ({
  companiesData,
  setParams,
}: {
  companiesData?: CompaniesListResponse;
  setParams: React.Dispatch<React.SetStateAction<CompaniesFilterParamsType>>;
}): ReactElement => {
  const { t } = useTranslation('common');

  const pageSizeOptions = useMemo((): {
    showPageSizes: boolean;
    pageSizes?: number[];
  } => {
    if (companiesData === undefined)
      return {
        showPageSizes: false,
      };
    const pageSizes = config.tableInfo.pageSizeOptionsBoxList.filter(
      (size) => size <= Math.ceil(companiesData.meta.totalItems + 1 / 10) * 10,
    );
    return {
      showPageSizes: true,
      pageSizes,
    };
  }, [companiesData]);

  if (companiesData === undefined || companiesData?.items?.length === 0)
    return <>{t('messages.noResults')}</>;

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = async (current, pageSize) => {
    setParams((prevState) => {
      return {
        ...prevState,
        page: current,
        limit: pageSize,
      };
    });
  };

  const onChangePage: PaginationProps['onChange'] = async (page) => {
    setParams((prevState) => {
      return {
        ...prevState,
        page,
      };
    });
  };

  return (
    <>
      <div className={styles.root}>
        {companiesData.items.map((company: CompanyInterface) => (
          <CompanyBox key={company.username} company={company} />
        ))}
      </div>
      <Pagination
        className={styles.pagination}
        current={companiesData.meta.currentPage}
        defaultCurrent={1}
        pageSize={companiesData.meta.itemsPerPage}
        onShowSizeChange={onShowSizeChange}
        showSizeChanger={pageSizeOptions.showPageSizes}
        pageSizeOptions={pageSizeOptions.pageSizes}
        total={companiesData.meta.totalItems}
        onChange={onChangePage}
      />
    </>
  );
};

export default CompanyBoxList;
