import React, { ReactElement } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompanyFormInterface } from '../../../models/companyFormInterface';
import {
  getMemberCategoriesLabelsEnum,
  getPartnerCategoriesLabelsEnum,
} from '../../../utils/utils';
import { UserCategory } from '../../../consts/UserCategoryEnum';

const SubcategorySelect = ({ formValues }: { formValues: CompanyFormInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const memberCategories = getMemberCategoriesLabelsEnum(t);
  const memberCategoriesOptions = Object.keys(memberCategories).map((category) => ({
    label: memberCategories[category],
    value: category,
  }));

  const partnerCategories = getPartnerCategoriesLabelsEnum(t);
  const partnerCategoriesOptions = Object.keys(partnerCategories).map((category) => ({
    label: partnerCategories[category],
    value: category,
  }));

  return (
    <>
      <Form.Item
        label={t('misc.memberCategories')}
        name='memberCategories'
        rules={[
          {
            required: formValues.userCategory === UserCategory.MEMBER ?? false,
            message: t('messages.requiredField') ?? '',
          },
        ]}
      >
        <Select mode='multiple' options={memberCategoriesOptions} />
      </Form.Item>
      <Form.Item
        label={t('misc.partnerCategories')}
        name='partnerCategories'
        rules={[
          {
            required: formValues.userCategory === UserCategory.PARTNER ?? false,
            message: t('messages.requiredField') ?? '',
          },
        ]}
      >
        <Select mode='multiple' options={partnerCategoriesOptions} />
      </Form.Item>
    </>
  );
};

export default SubcategorySelect;
