/* eslint-disable indent */
import React, { ReactElement, SetStateAction } from 'react';
import { Form, Button, Upload, Row, Col, Card, Input, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  PaperClipOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from './formStyles.module.css';
import config from '../../../config';
import useUploadField from '../../../hooks/useUploadField';
import { CompanyFormInterface } from '../../../models/companyFormInterface';
import { truncateText } from '../../../utils/utils';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';

const FormCertificates = ({
  formValues,
  form,
  setFormValues,
}: {
  formValues: CompanyFormInterface;
  form: FormInstance;
  setFormValues: React.Dispatch<SetStateAction<CompanyFormInterface>>;
}): ReactElement => {
  const { t } = useTranslation('common');
  const { beforeUploadDoc } = useUploadField();
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);

  const removeDocumentFile = (index: number): void => {
    if (formValues.documents === undefined) return;
    if (formValues.documents[index].name === undefined) return;
    const documents = formValues.documents;
    documents[index] = {
      name: formValues.documents[index].name,
      fileId: undefined,
      fileName: undefined,
    };
    setFormValues({
      ...formValues,
      documents,
    });
  };

  return (
    <div>
      <Form.List name='documents'>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={20} className={styles.certificatesWrap}>
              {fields.map(({ key, name, ...field }, index) => {
                return (
                  <Col xs={24} md={12} key={key}>
                    <Card className={styles.memberCard}>
                      <Form.Item
                        {...field}
                        name={[name, 'name']}
                        label={t('misc.name')}
                        rules={[
                          {
                            required: true,
                            message: t('messages.requiredField') ?? '',
                          },
                        ]}
                        className={styles.teamInput}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[name, 'file']}
                        label={t('misc.file')}
                        className={styles.teamInput}
                        valuePropName='file'
                        rules={[
                          {
                            required:
                              formValues.documents !== undefined &&
                              formValues.documents[index]?.fileId === undefined,
                            message: t('messages.requiredField') ?? '',
                          },
                        ]}
                      >
                        {formValues.documents !== undefined &&
                        formValues.documents[index]?.fileId === undefined ? (
                          <Upload
                            multiple={false}
                            maxCount={1}
                            accept={config.acceptedFileFormats.docsFormats}
                            beforeUpload={beforeUploadDoc}
                            showUploadList={true}
                          >
                            <Button icon={<UploadOutlined />} type='dashed'>
                              {t('buttons.attachDocument')}
                            </Button>
                          </Upload>
                        ) : (
                          formValues.documents !== undefined && (
                            <div className={styles.initDocRow}>
                              <Link
                                to={`${config.fileViewBasePath.replace(
                                  ':assetId',
                                  String(formValues.documents[index].fileId),
                                )}?token=${localStorageFileToken ?? sessionStorageFileToken}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <Button
                                  icon={<PaperClipOutlined />}
                                  type='ghost'
                                  className={styles.inititalDocFile}
                                >
                                  {formValues.documents[index].fileName !== undefined
                                    ? truncateText(String(formValues.documents[index].fileName), 20)
                                    : t('misc.companyPresentation')}
                                </Button>
                              </Link>
                              <Button
                                icon={<DeleteOutlined />}
                                type='ghost'
                                onClick={() => {
                                  removeDocumentFile(index);
                                }}
                              />
                            </div>
                          )
                        )}
                      </Form.Item>
                      <Button
                        icon={<MinusCircleOutlined />}
                        onClick={() => {
                          remove(name);
                        }}
                      >
                        {t('buttons.remove')}
                      </Button>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            {fields.length < config.limits.maxCertificates ? (
              <Form.Item>
                <Button
                  type='primary'
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  {t('buttons.addNewDoc')}
                </Button>
              </Form.Item>
            ) : (
              <p>
                <InfoCircleOutlined />{' '}
                {t('messages.maxCertificatesAllowed').replace(
                  ':nb',
                  String(config.limits.maxCertificates),
                )}
              </p>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default FormCertificates;
