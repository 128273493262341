import React, { ReactElement, useMemo } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Card, Col, Image, Row } from 'antd';
import { Link } from 'react-router-dom';
import config from '../../config';
import { InvoiceInitialValues } from '../../models/invoiceInterface';
import CardTitle from '../../components/cardTitle/cardTitle';
import styles from './index.module.css';
import { getFileIcon } from '../../utils/getFileIcon';
import InvoiceActions from './invoiceActions';
import InvoiceMessages from './invoiceMessages';
import { truncateText } from '../../utils/utils';
import { useUserContext } from '../../context/user.context';
import { getStatusBadge } from '../invoices/utils';
import { LocalStorageEnum } from '../../consts/LocalStorageEnum';

const InvoiceDisplay = ({ invoiceData }: { invoiceData: InvoiceInitialValues }): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);

  const InvoiceBox = (): ReactElement => {
    if (invoiceData?.invoice?.fileId === undefined) return <></>;
    if (invoiceData.invoice.fileId.endsWith('.jpg') || invoiceData.invoice.fileId.endsWith('.png'))
      return (
        <Image
          src={`${config.fileViewBasePath.replace(':assetId', invoiceData.invoice.fileId)}?token=${
            localStorageFileToken ?? sessionStorageFileToken
          }`}
        />
      );
    if (invoiceData.invoice.fileId.endsWith('.pdf'))
      return (
        <iframe
          src={`${config.fileViewBasePath.replace(':assetId', invoiceData.invoice.fileId)}?token=${
            localStorageFileToken ?? sessionStorageFileToken
          }`}
          width='100%'
          height='800px'
        />
      );
    const fileFormat = invoiceData.invoice.fileId.slice(
      invoiceData.invoice.fileId.lastIndexOf('.') + 1,
    );
    return (
      <div className={styles.fileMessagePlaceholder}>
        {t('messages.invoiceNotShowFormat').replace(':fileFormat', fileFormat)}
      </div>
    );
  };

  const invoiceInfo = useMemo(() => {
    const invoiceInfo = [
      {
        title: t('misc.invoice'),
        info: invoiceData.name,
      },
      {
        title: t('misc.status'),
        info: getStatusBadge({
          t,
          status: Number(invoiceData.status),
          dueDate: invoiceData.dueDate,
        }),
      },
      {
        title: t('misc.from'),
        info: invoiceData.sender?.companyName,
      },
      {
        title: t('misc.to'),
        info: invoiceData.receiver?.companyName,
      },
      {
        title: t('misc.value'),
        info: `${invoiceData.value} ${invoiceData.currency}`,
      },
      {
        title: t('misc.issuedDate'),
        info: dayjs.isDayjs(invoiceData.issuedDate)
          ? invoiceData.issuedDate.format(config.dateFormat.detailedMonth)
          : String(invoiceData.issuedDate),
      },
      {
        title: t('misc.dueDate'),
        info: dayjs.isDayjs(invoiceData.dueDate)
          ? invoiceData.dueDate.format(config.dateFormat.detailedMonth)
          : String(invoiceData.dueDate),
      },
    ];

    if (user.id === invoiceData.receiver?.id) {
      invoiceInfo.splice(3, 1);
    }

    if (user.id === invoiceData.sender?.id) {
      invoiceInfo.splice(2, 1);
    }

    return invoiceInfo;
  }, [invoiceData, t, user]);

  const AdditionalDocuments = (): ReactElement => {
    if (Array.isArray(invoiceData.documents) && invoiceData.documents.length > 0) {
      return (
        <Card>
          <div className={styles.additionalDocs}>
            <CardTitle type='bold' title={t('misc.additionalDocs')} />
            {invoiceData.documents.map((doc) => {
              return (
                <Link
                  to={`${config.fileViewBasePath.replace(':assetId', doc.fileId)}?token=${
                    localStorageFileToken ?? sessionStorageFileToken
                  }`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.oneDoc}
                  key={doc.fileId}
                >
                  <div className={styles.fileIcon}>{getFileIcon(doc.fileId)}</div>
                  <div className={styles.fileName}>{truncateText(doc.fileName, 30)}</div>
                </Link>
              );
            })}
          </div>
        </Card>
      );
    }
    return <></>;
  };

  return (
    <div>
      <InvoiceMessages invoiceData={invoiceData} />
      <Row gutter={50}>
        <Col xs={24} md={15}>
          <Card size='small'>
            <div className={styles.invoiceDisplayWrap}>
              <InvoiceBox />
              {invoiceData.invoice?.fileId && (
                <div className={styles.downloadInvoice}>
                  <Link
                    to={`${config.fileDownloadBasePath.replace(
                      ':assetId',
                      invoiceData.invoice.fileId,
                    )}?token=${localStorageFileToken ?? sessionStorageFileToken}`}
                  >
                    <Button icon={<DownloadOutlined />}>{t('buttons.downloadInvoice')}</Button>
                  </Link>
                </div>
              )}
            </div>
          </Card>
        </Col>
        <Col xs={24} md={9} className={styles.rightCol}>
          <Card>
            <div className={styles.innerRightInfo}>
              {invoiceInfo.map((infoLine) => (
                <div key={`${infoLine.title}${infoLine.info}`} className={styles.infoLine}>
                  <CardTitle title={infoLine.title} type='bold' noMargin={true} />
                  <div className={styles.info}>{infoLine.info}</div>
                </div>
              ))}
              <InvoiceActions invoiceData={invoiceData} />
            </div>
          </Card>
          <AdditionalDocuments />
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceDisplay;
