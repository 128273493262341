import { SelectProps } from 'antd';
import currencies from '../../../data/currencies.json';

export const getCurrencies = (): SelectProps['options'] => {
  const currenciesOptions: SelectProps['options'] = currencies.map((currency) => ({
    label: currency.symbol,
    value: currency.code,
  }));
  return currenciesOptions;
};
