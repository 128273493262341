import React, { ReactElement } from 'react';
import { ReactComponent as PartnerPin } from '../../assets/partnerPin.svg';
import { ReactComponent as MemberPin } from '../../assets/memberPin.svg';
import styles from './map.module.css';
import { UserCategory } from '../../consts/UserCategoryEnum';

const Pin = ({ category }: { category?: string }): ReactElement => {
  if (category === UserCategory.MEMBER) return <MemberPin className={styles.mapPin} />;
  return <PartnerPin className={styles.mapPin} />;
};

export default React.memo(Pin);
