import { Form, FormInstance, message } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../api/AxiosInstance';
import config from '../config';
import { InvoiceDocumentInterface, InvoiceInterface } from '../models/invoiceInterface';

interface CompanyFormHook {
  form: FormInstance<any>;
  formValues: InvoiceInterface;
  setFormValues: React.Dispatch<React.SetStateAction<InvoiceInterface>>;
  handleSubmit: (values: FormData) => void;
  handleFormChange: () => void;
  loading: boolean;
}

const useInvoiceForm = ({
  initValues,
  invoiceId,
}: {
  initValues?: InvoiceInterface;
  invoiceId?: string;
}): CompanyFormHook => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: InvoiceInterface = {
    name: '',
    clientId: undefined,
    value: '',
    currency: 'USD',
    issuedDate: '',
    dueDate: '',
    invoice: undefined,
    documents: undefined,
  };

  const [formValues, setFormValues] = useState<InvoiceInterface>(initValues ?? initialValues);

  const [form] = Form.useForm();

  const handleFormChange = useCallback((): void => {
    setFormValues(form.getFieldsValue());
  }, [form]);

  const prepareFormValues = useCallback(async (values: FormData): Promise<InvoiceInterface> => {
    const preparedValues: any = values;
    if (preparedValues.issuedDate !== undefined && dayjs.isDayjs(preparedValues.issuedDate)) {
      preparedValues.issuedDate = dayjs(preparedValues.issuedDate.$d.toDateString()).format(
        config.dateFormat.universalFormat,
      );
    }
    if (preparedValues.dueDate !== undefined && dayjs.isDayjs(preparedValues.dueDate)) {
      preparedValues.dueDate = dayjs(preparedValues.dueDate.$d.toDateString()).format(
        config.dateFormat.universalFormat,
      );
    }

    const documents: InvoiceDocumentInterface[] = [];

    if (
      preparedValues.documents?.fileList !== undefined &&
      Array.isArray(preparedValues.documents.fileList)
    ) {
      preparedValues.documents.fileList.forEach((element: any) => {
        if (typeof element.originFileObj !== 'undefined') {
          documents.push({
            file: element?.originFileObj,
          });
        } else {
          documents.push({
            fileId: element?.uid,
            fileName: element?.name,
          });
        }
      });
    }

    preparedValues.documents = documents;

    return preparedValues;
  }, []);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        setLoading(true);
        const preparedValues = await prepareFormValues(values);
        let response: Response;
        if (invoiceId === undefined) {
          response = await axiosInstance.post(config.apiRoutes.invoices, preparedValues, {
            headers: {
              ...{ 'Content-Type': 'multipart/form-data' },
            },
          });
        } else {
          response = await axiosInstance.put(
            config.apiRoutes.invoiceId.replace(':id', invoiceId),
            preparedValues,
            {
              headers: {
                ...{ 'Content-Type': 'multipart/form-data' },
              },
            },
          );
        }

        if (response.status === 201 || response.status === 200) {
          navigate(config.routes.allInvoices);
          message.success(t('messages.invoiceSaved'));
        }
        setLoading(false);
      } catch (error) {
        message.error(t('messages.errorTryLater'));
        setLoading(false);
      }
    },
    [prepareFormValues, invoiceId, navigate, t],
  );

  return {
    form,
    formValues,
    setFormValues,
    handleSubmit,
    handleFormChange,
    loading,
  };
};

export default useInvoiceForm;
