import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Badge, Space } from 'antd';
import React from 'react';
import config from '../../config';
import { TableDataType } from './types';
import { ItemPublished } from '../../consts/ItemPublishedEnum';

export const getColumns = (t: any): ColumnsType<TableDataType> => [
  {
    title: t('misc.id'),
    dataIndex: 'key',
    key: 'key',
    render: (id) => <Link to={config.routes.editEvent.replace(':id', id)}>{id}</Link>,
  },
  {
    title: t('misc.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (name, row) => <Link to={config.routes.editEvent.replace(':id', row.key)}>{name}</Link>,
  },
  {
    title: t('misc.publishDate'),
    dataIndex: 'publishDate',
    key: 'publishDate',
    sorter: (a, b) => a.publishDate.localeCompare(b.publishDate),
  },
  {
    title: t('misc.status'),
    key: 'published',
    dataIndex: 'published',
    sorter: (a, b) => a.published - b.published,
    render: (_, { published }) => {
      if (published === ItemPublished.TRUE) {
        return <Badge color={config.articleStatusColor.published} text={t('status.published')} />;
      }
      if (published === ItemPublished.NOT_SAVED_AND_PUBLISH_IN_PROGRESS) {
        return (
          <Badge
            color={config.articleStatusColor.inProgress}
            text={t('status.notSavedAndPublishInProgress')}
          />
        );
      }
      if (published === ItemPublished.NOT_SAVED_AND_DRAFT_IN_PROGRESS) {
        return (
          <Badge
            color={config.articleStatusColor.inProgress}
            text={t('status.notSavedAndDraftInProgress')}
          />
        );
      }
      if (published === ItemPublished.FALSE) {
        return <Badge color={config.articleStatusColor.draft} text={t('status.draft')} />;
      }
    },
  },
  {
    title: t('misc.action'),
    key: 'action',
    render: (_, record) => (
      <Space size='middle'>
        <Link to={record.key}>Edit</Link>
      </Space>
    ),
  },
];
