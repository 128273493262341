import { Form, FormInstance, message } from 'antd';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postAdmin, putAdmin } from '../../services/services';
import { AdminFormProps, AdminFormInterface } from './types';
import config from '../../config';

interface AdminFormHook {
  handleSubmit: (values: FormData) => void;
  submitErrors: string | null;
  form: FormInstance<any>;
  loading: boolean;
}

const useAdminForm = ({ data }: AdminFormProps): AdminFormHook => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [submitErrors, setSubmitErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        setLoading(true);
        if (data.id == null) {
          await postAdmin(values as unknown as AdminFormInterface);
        } else {
          await putAdmin(data.id, values as unknown as AdminFormInterface);
        }
        message.success(t('messages.successSave'));
        navigate(config.routes.admins);

        setLoading(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 409) {
            setSubmitErrors(t('messages.adminAlreadyExists'));
          } else {
            setSubmitErrors(error.response?.data.message);
          }
        } else {
          message.error(t('messages.errorTryLater'));
        }
        setLoading(false);
      }
    },
    [data.id, navigate, t],
  );

  return {
    handleSubmit,
    submitErrors,
    form,
    loading,
  };
};

export default useAdminForm;
