import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import styles from './filters.module.css';
import useAddress from '../../../hooks/useAddress';
import FilterElementWrap from '../../../components/Filters/filterElementWrap';

const CountryFilter = ({
  onChange,
}: {
  onChange: (value: string | undefined) => void;
}): ReactElement => {
  const { t } = useTranslation('common');

  const { countries, loading } = useAddress({});

  const handleCountry = (country: string): void => {
    onChange(country);
  };

  const clearCountry = (): void => {
    onChange(undefined);
  };

  return (
    <FilterElementWrap>
      <Select
        allowClear={true}
        onClear={clearCountry}
        onSelect={handleCountry}
        className={styles.fullWidth}
        placeholder={t('misc.country')}
        options={countries}
        showSearch
        loading={loading}
      />
    </FilterElementWrap>
  );
};

export default CountryFilter;
