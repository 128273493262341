import { axiosInstance } from '../../../api/AxiosInstance';
import config from '../../../config';

export const getReviews = async (id: string): Promise<any | undefined> => {
  try {
    const response = await axiosInstance.get(config.apiRoutes.getReviews.replace(':id', id));
    return response.data;
  } catch (error) {
    return undefined;
  }
};
