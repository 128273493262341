import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './filters.module.css';
import { EventsFilterParamsType } from '../../types';
import { UserRole } from '../../../../consts/UserRoleEnum';
import { useUserContext } from '../../../../context/user.context';
import StatusFilter from '../../../../components/Filters/statusFilter';
import NameFilter from '../../../../components/Filters/nameFilter';
import { ItemPublished } from '../../../../consts/ItemPublishedEnum';

const Filters = ({
  setParams,
}: {
  setParams: (newParams: EventsFilterParamsType) => void;
}): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();

  const handleSearch = async (value: string | undefined): Promise<void> => {
    setParams({ name: value });
  };

  const options = [
    {
      label: t('status.published'),
      value: ItemPublished.TRUE,
    },
    {
      label: t('status.draft'),
      value: ItemPublished.FALSE,
    },
    {
      label: t('status.notSavedAndPublishInProgress'),
      value: ItemPublished.NOT_SAVED_AND_PUBLISH_IN_PROGRESS,
    },
    {
      label: t('status.notSavedAndDraftInProgress'),
      value: ItemPublished.NOT_SAVED_AND_DRAFT_IN_PROGRESS,
    },
  ];

  const handleStatus = (published: number | undefined): void => {
    setParams({ published });
  };

  return (
    <div className={styles.root}>
      <NameFilter onChange={handleSearch} />
      {(user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) && (
        <StatusFilter onChange={handleStatus} options={options} />
      )}
    </div>
  );
};

export default Filters;
