import React, { ReactElement } from 'react';
import { DatePicker, Form, Input, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompanyFormInterface } from '../../../models/companyFormInterface';
import { PaymentProtectionEnum } from '../../../consts/PaymentProtectionEnum';
import config from '../../../config';

const PaymentProtectionSection = ({
  formValues,
}: {
  formValues: CompanyFormInterface;
}): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Form.Item
        label={t('misc.paymentProtection')}
        name='paymentProtection'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      {Boolean(formValues.paymentProtection) && (
        <>
          <Form.Item
            label={t('misc.paymentProtectionValidityDate')}
            name='paymentProtectionValidityDate'
            rules={[
              {
                required: Boolean(formValues.paymentProtection),
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <DatePicker format={config.dateFormat.universalFormat} />
          </Form.Item>
          <Form.Item
            label={t('misc.officesCoveredInPaymentProtection')}
            name='officesCoveredInPaymentProtection'
            rules={[
              {
                required: Boolean(formValues.paymentProtection),
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('misc.paymentGuaranteeStatus')}
            name='paymentGuaranteeStatus'
            rules={[
              {
                required: Boolean(formValues.paymentProtection),
                message: t('messages.requiredField') ?? '',
              },
            ]}
          >
            <Select
              placeholder={t('messages.selectCompanyPlaceholder')}
              options={[
                {
                  label: t('paymentProtection.secured'),
                  value: PaymentProtectionEnum.SECURED,
                },
                {
                  label: t('paymentProtection.renewal'),
                  value: PaymentProtectionEnum.RENEWAL,
                },
                {
                  label: t('paymentProtection.suspended'),
                  value: PaymentProtectionEnum.SUSPENDED,
                },
              ]}
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default PaymentProtectionSection;
