import { Form, FormInstance, message, RadioChangeEvent, UploadFile } from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { JSXElementConstructor, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteEvent, postEvent, putEvent } from '../../services/services';
import { EventFormInterface, EventFormProps, EventPhoto, FormUpload } from './types';
import config from '../../config';
import { LocalStorageEnum } from '../../consts/LocalStorageEnum';

interface EventFormHook {
  handleDefaultPhoto: (e: RadioChangeEvent) => void;
  handleEditorChange: (value: string) => void;
  handleSubmit: (values: FormData) => void;
  postSummary: string | null;
  submitErrors: string | null;
  form: FormInstance<any>;
  defaultPhoto: string | null;
  contextHolder: ReactElement<any, string | JSXElementConstructor<any>>;
  loading: boolean;
  handleDelete: () => void;
}

const useEventForm = ({ data }: EventFormProps): EventFormHook => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [postSummary, setPostSummary] = useState<string | null>(null);
  const [defaultPhoto, setDefaultPhoto] = useState<string | null>(null);
  const [submitErrors, setSubmitErrors] = useState<string | null>(null);
  const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);

  useEffect(() => {
    if (postSummary === null && data && data.postSummary.length > 0) {
      setPostSummary(data.postSummary);
    }
  }, [postSummary, data, defaultPhoto]);

  useEffect(() => {
    if (successSubmit) {
      messageApi.open({
        type: 'success',
        content: t('messages.successSave'),
      });
      setSuccessSubmit(false);
    }
  }, [successSubmit, messageApi, t]);

  const prepareImagesField = useCallback(
    (data: any, key: string): EventPhoto => {
      if (data[key] !== undefined && Array.isArray(data[key].fileList)) {
        if (typeof data[key].fileList[0].originFileObj !== 'undefined') {
          return {
            file: data[key].fileList[0]?.originFileObj,
          };
        }
        return {
          fileId: data[key].fileList[0]?.uid,
          fileName: data[key].fileList[0]?.name,
          location: `${data[key].fileList[0].url}?token=${
            localStorageFileToken ?? sessionStorageFileToken
          }`,
        };
      }

      return data[key];
    },
    [localStorageFileToken, sessionStorageFileToken],
  );

  const prepareFormValues = useCallback(
    async (values: FormData): Promise<EventFormInterface> => {
      const data: any = {
        ...form.getFieldsValue(),
      };

      ['thumbnail', 'mainImage'].forEach((key): void => {
        data[key] = prepareImagesField(data, key);
      });

      ['sliderImage', 'sponsors', 'partners', 'heroImage'].forEach((key): void => {
        if (data[key] && Array.isArray((data[key] as FormUpload).fileList)) {
          const newImages: EventPhoto[] = [];
          ((data[key] as FormUpload).fileList as UploadFile[]).forEach((element: any) => {
            if (typeof element.originFileObj !== 'undefined') {
              newImages.push({
                file: element?.originFileObj,
              });
            } else {
              newImages.push({
                fileId: element?.uid,
                fileName: element?.name,
                location: element.url,
              });
            }
          });

          data[key] = newImages;
        }
      });

      ['date', 'dateFinal'].forEach((key): void => {
        if (data[key] !== undefined) {
          data[key] = dayjs(data[key].$d.toDateString()).format(config.dateFormat.universalFormat);
        }
      });

      return data;
    },
    [form, prepareImagesField],
  );

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        setLoading(true);
        const preparedValues = await prepareFormValues(values);

        if (data.id == null) {
          await postEvent(preparedValues);
        } else {
          await putEvent(data.id, preparedValues);
        }

        setTimeout(() => {
          navigate(config.routes.events);
        }, 2000);
        setSuccessSubmit(true);
        setLoading(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 409) {
            setSubmitErrors(t('messages.eventAlreadyExists'));
          } else {
            setSubmitErrors(error.response?.data.message);
          }
        } else {
          console.log(error);
          message.error(t('messages.errorTryLater'));
        }
        setLoading(false);
      }
    },
    [data.id, navigate, prepareFormValues, t],
  );

  const handleDelete = useCallback(async () => {
    if (!data.id) return;

    try {
      setLoading(true);
      await deleteEvent(data.id);
      navigate(config.routes.events);
      message.success(t('messages.successDelete'));
      setLoading(false);
    } catch (error) {
      message.error(t('messages.errorTryLater'));
      setLoading(false);
    }
  }, [data.id, navigate, t]);

  const handleEditorChange = (value: string): void => {
    setPostSummary(value);
    form.setFieldValue('postSummary', value);
    form.validateFields(['postSummary']);
  };

  const handleDefaultPhoto = (e: RadioChangeEvent): void => {
    const uid = e.target.value;
    setDefaultPhoto(uid);
  };

  return {
    handleDefaultPhoto,
    handleEditorChange,
    handleSubmit,
    postSummary,
    submitErrors,
    form,
    defaultPhoto,
    contextHolder,
    loading,
    handleDelete,
  };
};

export default useEventForm;
