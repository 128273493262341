import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import PageTitle from '../../components/pageTitle/pageTitle';
import NotFound from '../notFound/notFound';
import InvoiceDisplay from './invoiceDisplay';
import useInvoice from './useInvoice';

const InvoicePage = (): ReactElement => {
  const { t } = useTranslation('common');

  const { loading, invoiceData } = useInvoice({ isEdit: false });

  if (loading)
    return (
      <>
        <LoadingOutlined />
      </>
    );

  if (invoiceData === undefined) return <NotFound />;

  return (
    <div>
      <PageTitle title={`${t('misc.invoice')} ${invoiceData.name}`} />
      <InvoiceDisplay invoiceData={invoiceData} />
    </div>
  );
};

export default InvoicePage;
