import { Upload, message } from 'antd';
import { useTranslation } from 'react-i18next';
import config from '../config';

interface UploadField {
  beforeUploadDoc: (file: File) => boolean | string;
  beforeUploadImage: (file: File) => boolean | string;
}

const useUploadField = (): UploadField => {
  const { t } = useTranslation('common');

  const beforeUploadDoc = (file: File): boolean | string => {
    const isDoc = config.acceptedFileFormats.docsMimes.includes(file.type);
    if (!isDoc) {
      message.warning(
        t('messages.onlyFormat').replace(':format', config.acceptedFileFormats.docsFormats),
      );
      return Upload.LIST_IGNORE;
    }
    const lesThan10mb = file.size / 1024 / 1024 < config.limits.maxDocFileSize;
    if (!lesThan10mb) {
      message.warning(
        t('messages.fileMustBeLess').replace(':size', String(config.limits.maxDocFileSize)),
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const beforeUploadImage = (file: File): boolean | string => {
    const isJpgOrPng = config.acceptedFileFormats.imagesMimes.includes(file.type);
    if (!isJpgOrPng) {
      message.warning(
        t('messages.onlyFormat').replace(':format', config.acceptedFileFormats.imagesFormats),
      );
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < config.limits.maxImageSize;
    if (!isLt2M) {
      message.warning(
        t('messages.fileMustBeLess').replace(':size', String(config.limits.maxImageSize)),
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  return {
    beforeUploadDoc,
    beforeUploadImage,
  };
};

export default useUploadField;
