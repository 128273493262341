import React, { ReactElement } from 'react';
import AdminForm from './components/adminForm';
import PageTitle from '../../components/pageTitle/pageTitle';
import { AdminFormInterface } from './types';

const AddAdmin = (): ReactElement => {
  const initialValues: AdminFormInterface = {
    username: '',
    email: '',
    password: '',
    primaryContact: '',
  };

  return (
    <div>
      <PageTitle title={'Add admin'} />
      <AdminForm data={initialValues} />
    </div>
  );
};

export default AddAdmin;
