import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Badge, Space, UploadFile } from 'antd';
import React from 'react';
import config from '../../config';
import { ArticleExistingPhoto, TableDataType } from './types';
import { UserRole } from '../../consts/UserRoleEnum';
import { ItemPublished } from '../../consts/ItemPublishedEnum';

export const getUploadInitialPhotosFormat = (photos: ArticleExistingPhoto[]): UploadFile[] =>
  photos.reduce(
    (res: UploadFile[], item, index) => [
      ...res,
      {
        uid: item.fileId,
        name: item.fileName,
        url: item.location,
        thumbUrl: item.location,
      },
    ],
    [],
  );

export const getColumns = (t: any, userRole: string): ColumnsType<TableDataType> => {
  const columns = [
    {
      title: t('misc.id'),
      dataIndex: 'id',
      key: 'id',
      render: (_: string, row: TableDataType) =>
        userRole === UserRole.ADMIN || userRole === UserRole.SUPER_ADMIN ? (
          <Link to={config.routes.editArticle.replace(':id', row.key)}>{row.key}</Link>
        ) : (
          row.key
        ),
    },
    {
      title: t('misc.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a: TableDataType, b: TableDataType) => a.name.localeCompare(b.name),
      render: (name: string, row: TableDataType) =>
        userRole === UserRole.ADMIN || userRole === UserRole.SUPER_ADMIN ? (
          <Link to={config.routes.editArticle.replace(':id', row.key)}>{name}</Link>
        ) : (
          name
        ),
    },
    {
      title: t('misc.publishDate'),
      dataIndex: 'publishDate',
      key: 'publishDate',
      sorter: (a: TableDataType, b: TableDataType) => a.publishDate.localeCompare(b.publishDate),
    },
    {
      title: t('misc.status'),
      key: 'published',
      dataIndex: 'published',
      width: 130,
      sorter: (a: TableDataType, b: TableDataType) => a.published - b.published,
      render: (_: string, { published }: TableDataType) => {
        if (published === ItemPublished.TRUE) {
          return <Badge color={config.articleStatusColor.published} text={t('status.published')} />;
        }
        if (published === ItemPublished.NOT_SAVED_AND_PUBLISH_IN_PROGRESS) {
          return (
            <Badge
              color={config.articleStatusColor.inProgress}
              text={t('status.notSavedAndPublishInProgress')}
            />
          );
        }
        if (published === ItemPublished.NOT_SAVED_AND_DRAFT_IN_PROGRESS) {
          return (
            <Badge
              color={config.articleStatusColor.inProgress}
              text={t('status.notSavedAndDraftInProgress')}
            />
          );
        }
        if (published === ItemPublished.FALSE) {
          return <Badge color={config.articleStatusColor.draft} text={t('status.draft')} />;
        }
      },
    },
  ];
  if (userRole === UserRole.ADMIN || userRole === UserRole.SUPER_ADMIN) {
    columns.push({
      title: t('misc.action'),
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Link to={`${config.routes.articles}/${record.key}`}>Edit</Link>
        </Space>
      ),
      dataIndex: '',
    });
  }
  return columns;
};
