import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentOutlined } from '@ant-design/icons';
import { CompanyInterface } from '../../../models/companyInterface';
import CardTitle from '../../../components/cardTitle/cardTitle';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const AddressTable = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const adressData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.country !== null && company.country !== undefined)
      prepareData.push({ name: t('misc.country'), content: company.country });

    if (company.county !== null && company.county !== undefined)
      prepareData.push({ name: t('misc.county'), content: company.county });

    if (company.city !== null && company.city !== undefined)
      prepareData.push({ name: t('misc.city'), content: company.city });

    if (company.address !== null && company.address !== undefined)
      prepareData.push({ name: t('misc.addressStreet'), content: company.address });

    if (company.address2 !== null && company.address2 !== undefined)
      prepareData.push({ name: t('misc.addressDetails'), content: company.address2 });

    if (company.postalCode !== null && company.postalCode !== undefined)
      prepareData.push({ name: t('misc.postalCode'), content: company.postalCode });

    return prepareData;
  }, [company, t]);

  if (adressData.length === 0) return <></>;

  return (
    <>
      <CardTitle type='bold' title={t('misc.address')} icon={<EnvironmentOutlined />} />
      <CustomTable data={adressData} />
    </>
  );
};

export default AddressTable;
