import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import FilterElementWrap from './filterElementWrap';
import styles from './filters.module.css';

const StatusFilter = ({
  onChange,
  options,
  disabled,
}: {
  onChange: (value: any) => void;
  options: Array<{ label: string; value: string | number }>;
  disabled?: boolean;
}): ReactElement => {
  const { t } = useTranslation('common');

  const handleCategory = (value: string): void => {
    onChange(value);
  };

  const clearCategory = (): void => {
    onChange(undefined);
  };

  return (
    <FilterElementWrap>
      <Select
        allowClear={true}
        onClear={clearCategory}
        onSelect={handleCategory}
        placeholder={t('misc.status')}
        className={styles.fullWidth}
        options={options}
        disabled={disabled}
      />
    </FilterElementWrap>
  );
};

export default StatusFilter;
