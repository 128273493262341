export enum MainEntity {
  MAIN = 'main',
  ADD = 'add',
  VIEW = 'view',
  EDIT = 'edit',
}

export const memberRoutes = {
  main: '/members',
  add: '/add-member',
  view: '/member/:id',
  edit: '/member/edit/:id',
};

export const companyRoutes = {
  main: '/companies',
  add: '/add-company',
  view: '/company/:id',
  edit: '/company/edit/:id',
};
