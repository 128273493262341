import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const Consultancy = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const consultancyData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.consultancyTypeOfService)
      prepareData.push({ name: t('misc.consultancyTypeOfService'), content: company.consultancyTypeOfService });

    if (company.consultancyUniqueSellingPoint)
      prepareData.push({ name: t('misc.consultancyUniqueSellingPoint'), content: company.consultancyUniqueSellingPoint });

    if (company.consultancyGeographicalAreasAlongTheBeltRoadInitiative)
      prepareData.push({ name: t('misc.consultancyGeographicalAreasAlongTheBeltRoadInitiative'), content: company.consultancyGeographicalAreasAlongTheBeltRoadInitiative });

    return prepareData;
  }, [company, t]);

  if (consultancyData.length === 0) return <></>;

  return <CustomTable data={consultancyData} />;
};

export default Consultancy;
