import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { CompanyInterface } from '../../models/companyInterface';
import { getUser } from '../../services/services';
import NotFound from '../notFound/notFound';
import CompanyAboutSection from './aboutSection';
import styles from './company.module.css';
import CompanyTopSection from './topSection/topSection';
import CompanyCertificates from './certificates';
import { UserStatus } from '../../consts/UserStatus';
import Mapbox from '../../components/map/map';
import CompanyReviews from './reviews/reviews';
import CompanyServicesSection from './servicesTabs';
import config from '../../config';

const PageCompany: React.FC = () => {
  const { id } = useParams();

  const [companyData, setCompanyData] = useState<CompanyInterface>();
  const [loading, setLoading] = useState<boolean>(true);

  const setCompany = useCallback(async (id: string): Promise<void> => {
    setLoading(true);
    const company = (await getUser(id)) as CompanyInterface;
    setCompanyData(company);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (id !== undefined) setCompany(id);
  }, [id, setCompany]);

  const showCertificatesSection = useMemo((): boolean => {
    if (companyData?.status === UserStatus.ENABLED) return true;
    if (companyData?.documents !== undefined && companyData.documents?.length > 0) return true;
    return false;
  }, [companyData]);

  const showMap = useMemo((): boolean => {
    if (companyData?.lat && companyData.lng) return true;
    return false;
  }, [companyData]);

  if (loading)
    return (
      <>
        <LoadingOutlined />
      </>
    );

  if (companyData === undefined) return <NotFound />;

  return (
    <div className={styles.root}>
      {config.features.mapDisplay && showMap && <Mapbox companies={[companyData]} />}
      <CompanyTopSection company={companyData} />
      <CompanyAboutSection company={companyData} />
      {config.features.nsrnCategories && <CompanyServicesSection company={companyData} />}
      {showCertificatesSection && <CompanyCertificates company={companyData} />}
      {companyData.id && (
        <CompanyReviews id={companyData.id} companyName={companyData.companyName} />
      )}
    </div>
  );
};

export default PageCompany;
