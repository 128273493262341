import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const Warehousing = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const warehousingData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.warehousingParticularTypesOfCargo)
      prepareData.push({ name: t('misc.warehousingParticularTypesOfCargo'), content: company.warehousingParticularTypesOfCargo });

    if (company.warehousingLocationsOfWarehousing)
      prepareData.push({ name: t('misc.warehousingLocationsOfWarehousing'), content: company.warehousingLocationsOfWarehousing });

    if (company.warehousingCurrentVolumeCapacity)
      prepareData.push({ name: t('misc.warehousingCurrentVolumeCapacity'), content: company.warehousingCurrentVolumeCapacity });

    if (company.warehousingUniqueSellingPoint)
      prepareData.push({ name: t('misc.warehousingUniqueSellingPoint'), content: company.warehousingUniqueSellingPoint });

    if (company.warehousingAddOnServices)
      prepareData.push({ name: t('misc.warehousingAddOnServices'), content: company.warehousingAddOnServices });

    return prepareData;
  }, [company, t]);

  if (warehousingData.length === 0) return <></>;

  return <CustomTable data={warehousingData} />;
};

export default Warehousing;
