import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import HeaderBar from '../headerBar/headerBar';
import SideBar from '../sideBar/sideBar';
import config from '../../config';
import styles from './privatePage.module.css';
import { useUserContext } from '../../context/user.context';
import useResponsiveLayout from '../../hooks/useResponsiveLayout';

const PrivatePage = ({ children }: { children: ReactNode }): ReactElement => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [collapsed, setCollapsed] = useState(true);
  const { isMobileLayout } = useResponsiveLayout();

  useEffect(() => {
    if (user === null || Object.keys(user).length < 3) {
      navigate(config.routes.login);
    }
  }, [navigate, user]);

  useEffect((): void => {
    if (isMobileLayout) {
      setCollapsed(true);
      return;
    }
    setCollapsed(false);
  }, [isMobileLayout]);

  return (
    <Layout className={cx(styles.root, isMobileLayout && styles.mobile)}>
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className={styles.siteLayout}>
        <HeaderBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content className={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default PrivatePage;
