import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { Alert, Button, FormInstance, Result, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FieldError } from '../companyForm';

const FormSubmit = ({
  form,
  formErrors,
  submitErrors,
  isEditCompany,
  loading,
}: {
  form: FormInstance;
  formErrors: FieldError[] | null;
  submitErrors: any;
  isEditCompany?: boolean;
  loading: boolean;
}): ReactElement => {
  const { t } = useTranslation('common');

  useEffect(() => {
    form.validateFields();
  }, [form]);

  const prepareErrorMessage = (field: string, message: string): string => {
    const fieldSections = field.split(',');
    return t(message)
      .replace(':field', t('misc.' + fieldSections[2]))
      .replace(':nb', String(Number(fieldSections[1]) + 1));
  };

  const getErrorFieldName = (field: string): string => {
    if (field.includes('documents')) {
      return prepareErrorMessage(field, 'messages.certificateNbFieldError');
    }
    if (field.includes('contacts')) {
      return prepareErrorMessage(field, 'messages.contactsNbFieldError');
    }
    return t('misc.' + field);
  };

  const extra = (): ReactNode => {
    if (submitErrors != null)
      return (
        <Space direction='vertical' key={'errors'}>
          <Alert message={submitErrors} type='error' showIcon />
        </Space>
      );

    if (formErrors === null)
      return (
        <Space direction='vertical' key={'errors'}>
          <Alert message={t('messages.completeForm')} type='error' showIcon />
        </Space>
      );

    if (formErrors.length > 0) {
      return (
        <Space direction='vertical' key={'errors'}>
          {formErrors.map((error) => {
            return (
              <Alert
                key={String(error.name)}
                message={t('messages.dynamicFieldRequired').replace(
                  ':field',
                  getErrorFieldName(String(error.name)),
                )}
                type='error'
                showIcon
              />
            );
          })}
        </Space>
      );
    }

    return (
      <Button type='primary' htmlType='submit' key='button' size='large' loading={loading}>
        {t('buttons.submit')}
      </Button>
    );
  };

  const boxTitle = useMemo((): string => {
    if (isEditCompany === true) return t('messages.updateCompany');
    return t('messages.saveCompany');
  }, [isEditCompany, t]);

  const subTitle = useMemo((): string => {
    if (isEditCompany === true) return t('messages.submitToUpdateCompany');
    return t('messages.sumitToSave');
  }, [isEditCompany, t]);

  return (
    <div>
      <Result status='info' icon=' ' title={boxTitle} subTitle={subTitle} extra={[extra()]} />
    </div>
  );
};

export default FormSubmit;
