import React, { ReactElement } from 'react';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { CompanyInterface } from '../../../models/companyInterface';
import { useUserContext } from '../../../context/user.context';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { UserRole } from '../../../consts/UserRoleEnum';
import { getUserStatusBadge } from '../../../utils/utils';
import styles from './companyActions.module.css';

const CompanyActions = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();

  if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN)
    return (
      <Card className={styles.cardRoot} size='small'>
        <div className={styles.adminBox}>
          <div className={styles.status}>
            <strong>{t('misc.status')}</strong>
            {getUserStatusBadge({ t, status: Number(company.status) })}
          </div>

          <Link
            to={config.routes.mainEntity.edit.replace(':id', String(company.id))}
            className={styles.editBtn}
          >
            <Button icon={<EditOutlined />} type='primary'>
              {t('buttons.editEntity')}
            </Button>
          </Link>
        </div>
      </Card>
    );

  if (company.id === user.id)
    return (
      <Link to={config.routes.mainEntity.edit.replace(':id', String(company.id))}>
        <Button icon={<EditOutlined />}>{t('buttons.editEntity')}</Button>
      </Link>
    );

  return <></>;
};

export default CompanyActions;
