import { Row } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import styles from './certificatePage.module.css';

const CertificatePage = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Row justify='center' align='middle' className={styles.root}>
      <div className={styles.innerWrapper}>{children}</div>
    </Row>
  );
};

export default CertificatePage;
