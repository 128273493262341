import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { CompanyInterface } from '../../../models/companyInterface';
import styles from './index.module.css';
import CardTitle from '../../../components/cardTitle/cardTitle';
import config from '../../../config';
import { getFileIcon } from '../../../utils/getFileIcon';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';
import { UserCategory } from '../../../consts/UserCategoryEnum';
interface DocumentInterface {
  fileId: string;
  fileName: string;
  name: string;
}

const CompanyCertificates = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);

  const documents = useMemo((): DocumentInterface[] => {
    if (company.documents === undefined) return [];
    return company.documents as DocumentInterface[];
  }, [company.documents]);

  return (
    <div className={styles.root}>
      <Row gutter={50}>
        <Col span={24}>
          <CardTitle title={t('sidebar.navigation.certificates')} type='bold' size='big' />
          <div className={styles.documents}>
            {company.id !== undefined && (
              <Link
                to={config.routes.memberCertificate.replace(':id', company.id)}
                className={styles.document}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Card>
                  <div className={styles.innerDoc}>
                    <div>
                      <div className={styles.icon}>{getFileIcon('.pdf')}</div>
                      <div className={styles.name}>{company.userCategory === UserCategory.MEMBER ? t('misc.member') : company.userCategory === UserCategory.PARTNER && t('misc.partner') } {t('misc.certificate')}</div>
                    </div>
                    <EyeOutlined />
                  </div>
                </Card>
              </Link>
            )}
            {documents.map((document) => {
              if (document.fileId !== undefined && document.fileId !== null)
                return (
                  <Link
                    key={document.fileId}
                    to={`${config.fileViewBasePath.replace(':assetId', document.fileId)}?token=${
                      localStorageFileToken ?? sessionStorageFileToken
                    }`}
                    className={styles.document}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Card>
                      <div className={styles.innerDoc}>
                        <div>
                          <div className={styles.icon}>{getFileIcon(document.fileId)}</div>
                          <div className={styles.name}>{document.name}</div>
                        </div>
                        <EyeOutlined />
                      </div>
                    </Card>
                  </Link>
                );
              return null;
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyCertificates;
