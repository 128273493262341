import React from 'react';
import { useTranslation } from 'react-i18next';
import CompanyForm from '../../components/companyForm/companyForm';
import PageTitle from '../../components/pageTitle/pageTitle';

const AddCompany: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <div>
      <PageTitle title={t('pageTitles.addEntity')} />
      <CompanyForm />
    </div>
  );
};

export default AddCompany;
