import React, { ReactElement, useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import AdminForm from './components/adminForm';
import PageTitle from '../../components/pageTitle/pageTitle';
import { useParams } from 'react-router-dom';
import { AdminEntity } from './types';
import { getUser } from '../../services/services';

const EditAdmin = (): ReactElement => {
  const [admin, setAdmin] = useState<AdminEntity | null>(null);
  const { id } = useParams();
  const { t } = useTranslation('common');

  useEffect(() => {
    const initData = async (): Promise<void> => {
      try {
        const data = (await getUser(id as string)) as AdminEntity;
        setAdmin({
          ...data,
        });
      } catch (e) {
        message.error(t('messages.errorTryLater'));
      }
    };

    initData();
  }, [id, t]);

  return (
    <div>
      <PageTitle title={'Edit admin'} />
      {admin && <AdminForm data={admin} />}
    </div>
  );
};

export default EditAdmin;
