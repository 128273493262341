import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const Operators = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const operatorsData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.operatorsRegionsAndRoutes)
      prepareData.push({ name: t('misc.operatorsRegionsAndRoutes'), content: company.operatorsRegionsAndRoutes });

    if (company.operatorsTypesOfTrainsVesselsInService)
      prepareData.push({ name: t('misc.operatorsTypesOfTrainsVesselsInService'), content: company.operatorsTypesOfTrainsVesselsInService });

    if (company.operatorsParticularTypesOfCargo)
      prepareData.push({ name: t('misc.operatorsParticularTypesOfCargo'), content: company.operatorsParticularTypesOfCargo });

    if (company.operatorsUniqueSellingPoint)
      prepareData.push({ name: t('misc.operatorsUniqueSellingPoint'), content: company.operatorsUniqueSellingPoint });

    if (company.operatorsAddOnServices)
      prepareData.push({ name: t('misc.operatorsAddOnServices'), content: company.operatorsAddOnServices });

    return prepareData;
  }, [company, t]);

  if (operatorsData.length === 0) return <></>;

  return <CustomTable data={operatorsData} />;
};

export default Operators;
