import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './translations/en/common.json';
import config from './config';

const processTranslations = (translations: any): any => {
  const useMember = config.features.useMember;
  const useInvoicesForMotion = config.features.displayInvoicesForMotion;
  const conditionalRegex = /{{(useMember|useInvoicesForMotion)\s*\?\s*['"](.+)['"]\s*:\s*['"](.+)['"]}}/g;

  const processValue = (value: any): any => {
    if (typeof value === 'string') {
      return value.replace(conditionalRegex, (_, flag, trueValue, falseValue) => {
        if (flag === 'useMember') {
          return useMember ? trueValue : falseValue;
        } else if (flag === 'useInvoicesForMotion') {
          return useInvoicesForMotion ? trueValue : falseValue;
        }
        return value;
      });
    }
    if (typeof value === 'object' && value !== null) {
      return Object.entries(value).reduce(
        (acc, [key, val]) => ({
          ...acc,
          [key]: processValue(val),
        }),
        {},
      );
    }
    return value;
  };

  return processValue(translations);
};

const processedTranslations = processTranslations(common_en);

void i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  resources: {
    en: {
      common: processedTranslations,
    },
  },
});

export default i18n;
