import React, { ReactElement } from 'react';
import { UserProvider } from './context/user.context';
import Pages from './pages/pages';

const App = (): ReactElement => {
  return (
    <div className='App'>
      <UserProvider>
        <Pages />
      </UserProvider>
    </div>
  );
};

export default App;
