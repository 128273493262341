import React, { ReactElement } from 'react';
import styles from './pageTitle.module.css';

const PageTitle = ({ buttons, title }: { buttons?: ReactElement; title: string }): ReactElement => {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
      {buttons !== undefined && <div className={styles.buttonsWrap}>{buttons}</div>}
    </div>
  );
};

export default PageTitle;
