import { ThemeConfig } from 'antd';
import { companyRoutes, MainEntity, memberRoutes } from './consts/MainRoutes';

export interface ConfigType {
  projectName: string;
  backendUrl: string;
  mainWebsiteUrl: string;
  apiRoutes: {
    login: string;
    forgotPassword: string;
    resetPassword: string;
    members: string;
    admins: string;
    updateMembers: string;
    articles: string;
    events: string;
    uploadImage: string;
    users: string;
    invoices: string;
    invoiceId: string;
    pay: string;
    declineInvoice: string;
    reportInvoice: string;
    certificate: string;
    notifications: string;
    resolve: string;
    reviews: string;
    getReviews: string;
    address: {
      countries: string;
      counties: string;
      cities: string;
    };
    exportUsers: string;
    exportInvoices: string;
    exportArticles: string;
    exportEvents: string;
    exportMembers: string;
  };
  routes: {
    login: string;
    resetPassword: string;
    forgotPassword: string;
    mainEntity: Record<MainEntity, string>,
    admins: string;
    addAdmin: string;
    addArticle: string;
    editArticle: string;
    articles: string;
    allArticles: string;
    allInvoices: string;
    addEvent: string;
    editEvent: string;
    events: string;
    editAdmin: string;
    editMyCompany: string;
    invoices: string;
    invoiceId: string;
    addInvoice: string;
    editInvoice: string;
    memberCertificate: string;
  };
  fileViewBasePath: string;
  fileDownloadBasePath: string;
  theme: ThemeConfig;
  logo: {
    fullSvgLogo: string;
    iconSvgLogo: string;
    logoPlaceholder: string;
  };
  acceptedFileFormats: {
    docsFormats: string;
    docsMimes: string[];
    imagesFormats: string;
    imagesMimes: string[];
  };
  tableInfo: {
    pageSizeOptions: number[];
    pageSizeOptionsBoxList: number[];
  };
  limits: {
    maxContacts: number;
    maxCertificates: number;
    maxDocFileSize: number;
    maxImageSize: number;
    maxInvoiceAdditionalFiles: number;
    maxSpecificsLength: number;
  };
  userStatusColor: {
    archived: string;
    hidden: string;
    enabled: string;
  };
  invoiceStatusColor: {
    paid: string;
    declined: string;
    cancelled: string;
    notPaid: string;
  };
  articleStatusColor: {
    published: string;
    draft: string;
    inProgress: string;
  };
  eventStatusColor: {
    published: string;
    draft: string;
  };
  minPasswordLength: number;
  features: {
    paymentProtection: boolean;
    nsrnCategories: boolean;
    establishedInField: boolean;
    invoicesPdf: boolean;
    useMember: boolean;
    mapDisplay: boolean;
    useProfilePic: boolean;
    enableTeam: boolean;
    hidePresentation: boolean;
    hideVatCode: boolean;
    displayAddressInField: boolean;
    disableJoinedInField: boolean;
    disableArticlesForMembers: boolean;
    disableExportForMembers: boolean;
    disableInvoices: boolean;
    displayPositionInField: boolean;
    displayCompanyInField: boolean;
    displayDescriptionInField: boolean;
    displayInvoicesForMotion: boolean;
  };
  dateFormat: {
    year: string;
    universalFormat: string;
    detailedDayMonthTime: string;
    detailedMonth: string;
    certificate: string;
  };
  mapBoxStyle: string;
  invoicePdfUrl: string;
  policiesLinks: {
    privacyPolicy: string;
    imprint: string;
  }
}

const config: ConfigType = {
  projectName: String(process.env.REACT_APP_PROJECT_NAME),
  backendUrl: process.env.REACT_APP_API_URL ?? 'http://localhost:3001/',
  apiRoutes: {
    login: 'auth/login',
    forgotPassword: 'auth/forgot-password',
    resetPassword: 'auth/reset-password',
    members: 'users/members',
    admins: 'users/admins',
    updateMembers: 'users/members/:id',
    articles: 'articles',
    events: 'events',
    uploadImage: 'files',
    users: 'users/:id',
    invoices: 'invoices',
    invoiceId: 'invoices/:id',
    pay: 'invoices/:id/pay',
    resolve: 'invoices/:id/resolve',
    declineInvoice: 'invoices/:id/decline',
    reportInvoice: 'invoices/:id/report',
    certificate: 'users/:id/certificate',
    notifications: 'notifications',
    reviews: 'reviews',
    getReviews: 'users/:id/reviews',
    address: {
      countries: 'address/countries',
      counties: 'address/counties',
      cities: 'address/cities',
    },
    exportUsers: `${process.env.REACT_APP_API_URL}api/v1/users/export`,
    exportInvoices: `${process.env.REACT_APP_API_URL}api/v1/invoices/export`,
    exportArticles: `${process.env.REACT_APP_API_URL}api/v1/articles/export`,
    exportEvents: `${process.env.REACT_APP_API_URL}api/v1/events/export`,
    exportMembers: `${process.env.REACT_APP_API_URL}api/v1/users/export/members`,
  },
  routes: {
    login: '/',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    mainEntity: process.env.REACT_APP_USE_MEMBER ? memberRoutes: companyRoutes,
    admins: '/admins',
    addAdmin: '/add-admin',
    articles: '/articles',
    allArticles: '/articles/index',
    allInvoices: '/invoices/index',
    addArticle: '/articles/add',
    editArticle: '/articles/:id',
    events: '/events',
    addEvent: '/events/add',
    editEvent: '/events/:id',
    editAdmin: '/admin/edit/:id',
    editMyCompany: '/company/edit',
    invoices: '/invoices',
    invoiceId: '/invoice/:id',
    addInvoice: '/invoice/add',
    editInvoice: '/invoices/:id',
    memberCertificate: '/member-certificate/:id',
  },
  fileViewBasePath: `${process.env.REACT_APP_API_URL}api/v1/files/view/:assetId`,
  fileDownloadBasePath: `${process.env.REACT_APP_API_URL}api/v1/files/download/:assetId`,
  theme: {
    token: {
      fontFamily: 'DM Sans, sans-serif',
      colorPrimary: process.env.REACT_APP_PRIMARY_COLOR,
      borderRadius: 2,
      borderRadiusLG: 5,
      borderRadiusOuter: 2,
      borderRadiusSM: 2,
      borderRadiusXS: 2,
      screenLG: 1000,
    },
  },
  logo: {
    fullSvgLogo: String(process.env.REACT_APP_LOGO_URL),
    iconSvgLogo: String(process.env.REACT_APP_LOGO_URL),
    logoPlaceholder: String(process.env.REACT_APP_LOGO_PLACEHOLDER),
  },
  acceptedFileFormats: {
    docsFormats: '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv',
    docsMimes: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
      'image/jpeg',
      'image/png',
    ],
    imagesFormats: '.jpg, .jpeg, .png',
    imagesMimes: ['image/jpeg', 'image/png'],
  },
  limits: {
    maxContacts: 4,
    maxCertificates: 3,
    maxDocFileSize: 10,
    maxImageSize: 1,
    maxInvoiceAdditionalFiles: 9,
    maxSpecificsLength: 50,
  },
  tableInfo: {
    pageSizeOptions: [10, 20, 40, 100],
    pageSizeOptionsBoxList: [12, 24, 48, 120],
  },
  userStatusColor: {
    archived: '#D9D9D9',
    hidden: '#FF4D4F',
    enabled: '#52C41A',
  },
  invoiceStatusColor: {
    paid: '#00c017',
    declined: '#D9D9D9',
    cancelled: '#FF4D4F',
    notPaid: '#823967',
  },
  articleStatusColor: {
    draft: '#FF4D4F',
    published: '#52C41A',
    inProgress: '#D9D9D9',
  },
  eventStatusColor: {
    draft: '#FF4D4F',
    published: '#52C41A',
  },
  minPasswordLength: 10,
  features: {
    paymentProtection: Boolean(process.env.REACT_APP_ENABLE_PAYMENT_PROTECTION),
    nsrnCategories: Boolean(process.env.REACT_APP_ENABLE_NSRN_CATEGORIES),
    establishedInField: Boolean(process.env.REACT_APP_ENABLE_ESTABLISHED_IN_FIELD),
    invoicesPdf: Boolean(process.env.REACT_APP_ENABLE_INVOICES_PDF),
    useMember: Boolean(process.env.REACT_APP_USE_MEMBER),
    mapDisplay: Boolean(process.env.REACT_APP_MAP_DISPLAY),
    useProfilePic: Boolean(process.env.REACT_APP_USE_PROFILE_PIC),
    enableTeam: Boolean(process.env.REACT_APP_ENABLE_TEAM),
    hidePresentation: Boolean(process.env.REACT_APP_HIDE_PRESENTATION_IN_FIELD),
    hideVatCode: Boolean(process.env.REACT_APP_HIDE_VAT_CODE_IN_FIELD),
    displayAddressInField: Boolean(process.env.REACT_APP_DISPLAY_ADDRESS_IN_FIELD),
    disableInvoices: Boolean(process.env.REACT_APP_DISABLE_INVOICES),
    disableArticlesForMembers: Boolean(process.env.REACT_APP_DISABLE_ARTICLES_FOR_MEMBERS),
    disableExportForMembers: Boolean(process.env.REACT_APP_DISABLE_EXPORT_FOR_MEMBERS),
    disableJoinedInField: Boolean(process.env.REACT_APP_DISABLE_JOINED_IN_FIELD),
    displayPositionInField: Boolean(process.env.REACT_APP_DISPLAY_POSITION_IN_FIELD),
    displayCompanyInField: Boolean(process.env.REACT_APP_DISPLAY_COMPANY_IN_FIELD),
    displayDescriptionInField: Boolean(process.env.REACT_APP_DISPLAY_DESCRIPTION_IN_FIELD),
    displayInvoicesForMotion: Boolean(process.env. REACT_APP_DISPALY_INVOICES_FOR_MOTION)

  },
  dateFormat: {
    year: 'YYYY',
    universalFormat: 'YYYY-MM-DD',
    detailedDayMonthTime: 'dddd, MMMM D, YYYY h:mm A',
    detailedMonth: 'DD MMMM YYYY',
    certificate: 'DD.MM.YYYY',
  },
  mapBoxStyle: process.env.REACT_APP_MAPBOX_STYLE ?? 'mapbox://styles/mapbox/streets-v12',
  mainWebsiteUrl: String(process.env.REACT_APP_MAIN_WEBSITE_URL),
  invoicePdfUrl: '/NSRN.Guarantee.Fund.Policy.May.2023.pdf',
  policiesLinks:{
    privacyPolicy: String(process.env.REACT_APP_PRIVACY_POLICY),
    imprint: String(process.env.REACT_APP_IMPRINT)
  }
};

export default config;
