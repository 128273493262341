import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const Forwarder = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const forwarderData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.forwarderMainAreasOfBusinessCargoType)
      prepareData.push({ name: t('misc.forwarderMainAreasOfBusinessCargoType'), content: company.forwarderMainAreasOfBusinessCargoType });

    if (company.forwarderMainAreasOfBusinessTransportMode)
      prepareData.push({ name: t('misc.forwarderMainAreasOfBusinessTransportMode'), content: company.forwarderMainAreasOfBusinessTransportMode });

    if (company.forwarderHaveYouHandledCargoViaRailBefore)
      prepareData.push({ name: t('misc.forwarderHaveYouHandledCargoViaRailBefore'), content: company.forwarderHaveYouHandledCargoViaRailBefore });

    if (company.forwarderCoreStrengths)
      prepareData.push({ name: t('misc.forwarderCoreStrengths'), content: company.forwarderCoreStrengths });

    if (company.forwarderGeographicalAreasAlongTheBeltRoadInitiative)
      prepareData.push({ name: t('misc.forwarderGeographicalAreasAlongTheBeltRoadInitiative'), content: company.forwarderGeographicalAreasAlongTheBeltRoadInitiative });

    if (company.forwarderAddOnServicesConnectedToTheBeltRoadInitiative)
      prepareData.push({ name: t('misc.forwarderAddOnServicesConnectedToTheBeltRoadInitiative'), content: company.forwarderAddOnServicesConnectedToTheBeltRoadInitiative });

    return prepareData;
  }, [company, t]);

  if (forwarderData.length === 0) return <></>;

  return <CustomTable data={forwarderData} />;
};

export default Forwarder;
