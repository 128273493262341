import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { deleteUser, getAdmins } from '../../services/services';
import { AdminEntity, AdminListResponse, AdminsFilterParamsType } from './types';
import Filters from './components/filters/filters';
import useDebounce from '../../hooks/useDebounce';
import { getColumns } from './utils';
import DataTable from '../../components/DataTable/dataTable';
import config from '../../config';

const ListAdmins = (): ReactElement => {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation('common');
  const [admins, setAdmins] = useState<AdminListResponse>();
  const [params, setParams] = useState<AdminsFilterParamsType>({});
  const debouncedParams = useDebounce(params, 1000);

  const fetchData = useCallback(async (): Promise<void> => {
    const adminsData = await getAdmins(debouncedParams);
    setAdmins(adminsData);
  }, [debouncedParams]);

  useEffect(() => {
    fetchData();
  }, [debouncedParams, fetchData]);

  const handleSetParams = (newParams: AdminsFilterParamsType): void => {
    const paramsData = { ...params, ...newParams };
    if (!newParams.page) {
      paramsData.page = 1;
    }
    setParams(paramsData);
  };
  const handleDeleteAdmin = async (id: string): Promise<void> => {
    await deleteUser(id);
    messageApi.open({
      type: 'success',
      content: t('messages.successDelete'),
    });
    setParams({ ...params });
  };

  const columns = getColumns(t, handleDeleteAdmin);

  const dataTable = admins?.items.map((admin: AdminEntity) => ({
    key: (admin.id as number).toString(),
    name: admin.primaryContact,
    createdAt:
      (admin.createdAt &&
        dayjs(admin.createdAt, config.dateFormat.universalFormat).format(
          config.dateFormat.universalFormat,
        )) ??
      '',
  }));

  return (
    <div>
      {contextHolder}
      <Filters setParams={handleSetParams} />
      {admins != null && dataTable != null && (
        <DataTable
          columns={columns}
          items={dataTable}
          total={admins.meta.totalItems}
          page={admins.meta.currentPage}
          setParams={handleSetParams}
          itemsPerPage={admins.meta.itemsPerPage}
        />
      )}
    </div>
  );
};

export default ListAdmins;
