import React, { ReactElement } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const FormOther = ({disabledFields} : {disabledFields?: boolean}): ReactElement => {
  const { t } = useTranslation('common');

  const { TextArea } = Input;

  return (
    <div>
      <Form.Item label={t('misc.otherKindOfService')} name='otherKindOfService'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.otherGeographicalRegionsCovered')}
        name='otherGeographicalRegionsCovered'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.otherWhereAreYourDepotsLocated')}
        name='otherWhereAreYourDepotsLocated'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item label={t('misc.otherUniqueSellingPoint')} name='otherUniqueSellingPoint'>
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.otherWhenShouldAMemberContactYou')}
        name='otherWhenShouldAMemberContactYou'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
      <Form.Item
        label={t('misc.otherExperienceRelatingToBeltAndRoadInitiative')}
        name='otherExperienceRelatingToBeltAndRoadInitiative'
      >
        <TextArea disabled={disabledFields} />
      </Form.Item>
    </div>
  );
};

export default FormOther;
