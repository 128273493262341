import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Popover, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { SendOutlined,CopyOutlined } from '@ant-design/icons';
import { CompanyInterface } from '../../models/companyInterface';

const EmailAllButton = ({ companies }: { companies: CompanyInterface[] }): ReactElement => {
  const { t } = useTranslation('common');

  const emails = useMemo((): string => {
    let prepareEmails: string = '';
    companies.forEach((company) => {
      prepareEmails += company.email + ';';
    });
    return prepareEmails;
  }, [companies]);

  const [copyApiAvailable, setCopyApiAvailable] = useState(false);

  useEffect(() => {
    if (navigator.clipboard) {
      setCopyApiAvailable(true);
    }
  }, []);

  const copyToClipboard = useCallback(async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(emails);
      message.success(t('messages.copySuccess'));
    } catch (err) {
      message.error(t('messages.copyError'));
    }
  }, [emails, t]);

  if (companies.length === 0) return <></>;

  return (
    <Popover
      content={
        <Space direction='vertical'>
          <Button icon={<SendOutlined />} href={'mailto:?bcc=' + emails} target='_blank' rel='noopener noreferrer'>
            {t('buttons.openInMail')}
          </Button>
          <Button icon={<CopyOutlined />} onClick={copyToClipboard} target='_blank' disabled={!copyApiAvailable} >
            {t('buttons.copyEmails')}
          </Button>
        </Space>
      }
      trigger='click'
      placement='bottomRight'
    >
      <Button icon={<SendOutlined />} target='_blank'>
        {t('buttons.emailAll')}
      </Button>
    </Popover>
  );
};

export default EmailAllButton;
