import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CertificatePage from '../components/certificatePage/certificatePage';
import PrivatePage from '../components/privatePage/privatePage';
import PublicPage from '../components/publicPage/publicPage';
import config from '../config';
import { UserRole } from '../consts/UserRoleEnum';
import { useUserContext } from '../context/user.context';
import AddCompany from './addCompany/addCompany';
import AddAdmin from './admins/addAdmin';
import EditAdmin from './admins/editAdmin';
import AddArticle from './articles/addArticle';
import EditArticle from './articles/editArticle';
import ListArticles from './articles/listArticles';
import Companies from './companies/companies';
import PageCompany from './company/[id]';
import EditCompany from './editCompany/[id]';
import AddInvoice from './invoice/addInvoice';
import AddEvent from './events/addEvent';
import EditEvent from './events/editEvent';
import ListEvents from './events/listEvent';
import ListAdmins from './admins/listAdmin';
import Login from './login/login';
import MemberCertificate from './memberCertificate/memberCertificate';
import NotFound from './notFound/notFound';
import ResetPassword from './resetPassword/resetPassword';
import ForgotPassword from './forgotPassword/forgotPassword';
import Invoices from './invoices/invoices';
import EditInvoice from './invoice/editInvoice';
import InvoicePage from './invoice/invoice';
import MemberCertificatePLA from './memberCertificatePLA/memberCertificate';
import MemberCertificateMotion from './memberCertificateMotion/memberCertificate';
import MemberCertificatePulp from './memberCertificatePulp/memberCertificate';

const Pages = (): JSX.Element => {
  const { user } = useUserContext();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <PublicPage>
              <Login />
            </PublicPage>
          }
        />
        <Route
          path={config.routes.resetPassword}
          element={
            <PublicPage>
              <ResetPassword />
            </PublicPage>
          }
        />
        <Route
          path={config.routes.forgotPassword}
          element={
            <PublicPage>
              <ForgotPassword />
            </PublicPage>
          }
        />
        <Route
          path={config.routes.memberCertificate}
          element={
            <CertificatePage>
              {process.env.REACT_APP_CERTIFICATE === 'new-silk-road-network' && (
                <MemberCertificate />
              )}
              {process.env.REACT_APP_CERTIFICATE === 'project-logistics-alliance' && (
                <MemberCertificatePLA />
              )}
              {process.env.REACT_APP_CERTIFICATE === 'motion-logistics-network' && (
                <MemberCertificateMotion />
              )}
              {process.env.REACT_APP_CERTIFICATE === 'pulp-network' && <MemberCertificatePulp />}
            </CertificatePage>
          }
        />
        {user.role === UserRole.SUPER_ADMIN && (
          <>
            <Route
              path={config.routes.admins}
              element={
                <PrivatePage>
                  <ListAdmins />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.addAdmin}
              element={
                <PrivatePage>
                  <AddAdmin />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.editAdmin}
              element={
                <PrivatePage>
                  <EditAdmin />
                </PrivatePage>
              }
            />
          </>
        )}

        <Route
          path={config.routes.mainEntity.main}
          element={
            <PrivatePage>
              <Companies />
            </PrivatePage>
          }
        />

        {(user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) && (
          <Route
            path={config.routes.mainEntity.add}
            element={
              <PrivatePage>
                <AddCompany />
              </PrivatePage>
            }
          />
        )}
        {((user.role === UserRole.MEMBER && !config.features.disableArticlesForMembers) ||
          user.role !== UserRole.MEMBER) && (
          <>
            <Route
              path={config.routes.allArticles}
              element={
                <PrivatePage>
                  <ListArticles />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.addArticle}
              element={
                <PrivatePage>
                  <AddArticle />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.editArticle}
              element={
                <PrivatePage>
                  <EditArticle />
                </PrivatePage>
              }
            />
          </>
        )}

        {(user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) && (
          <>
            <Route
              path={config.routes.events}
              element={
                <PrivatePage>
                  <ListEvents />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.addEvent}
              element={
                <PrivatePage>
                  <AddEvent />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.editEvent}
              element={
                <PrivatePage>
                  <EditEvent />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.editInvoice}
              element={
                <PrivatePage>
                  <EditInvoice />
                </PrivatePage>
              }
            />
          </>
        )}

        <Route
          path={config.routes.mainEntity.view}
          element={
            <PrivatePage>
              <PageCompany />
            </PrivatePage>
          }
        />
        <Route
          path={config.routes.mainEntity.edit}
          element={
            <PrivatePage>
              <EditCompany />
            </PrivatePage>
          }
        />
        <Route
          path={config.routes.editMyCompany}
          element={
            <PrivatePage>
              <EditCompany myCompany={true} />
            </PrivatePage>
          }
        />
        {!config.features.disableInvoices && (
          <>
            <Route
              path={config.routes.allInvoices}
              element={
                <PrivatePage>
                  <Invoices />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.invoices}
              element={
                <PrivatePage>
                  <Invoices />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.invoiceId}
              element={
                <PrivatePage>
                  <InvoicePage />
                </PrivatePage>
              }
            />
            <Route
              path={config.routes.addInvoice}
              element={
                <PrivatePage>
                  <AddInvoice />
                </PrivatePage>
              }
            />
          </>
        )}
        <Route
          path='*'
          element={
            <PrivatePage>
              <NotFound />
            </PrivatePage>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
