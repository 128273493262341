import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import React from 'react';
import config from '../../config';
import { TableDataType } from './types';

export const getColumns = (
  t: any,
  handleDeleteAdmin: (id: string) => Promise<void>,
): ColumnsType<TableDataType> => [
  {
    title: t('misc.id'),
    dataIndex: 'key',
    key: 'key',
    render: (id) => <Link to={config.routes.editAdmin.replace(':id', id)}>{id}</Link>,
  },
  {
    title: t('misc.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (name, row) => <Link to={config.routes.editAdmin.replace(':id', row.key)}>{name}</Link>,
  },
  {
    title: t('misc.createdDate'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
  {
    title: t('misc.action'),
    key: 'action',
    render: (_, record) => (
      <Space size='middle'>
        <Link to={config.routes.editAdmin.replace(':id', record.key)}>{t('buttons.edit')}</Link>
        <Link
          to={'#'}
          onClick={(): void => {
            handleDeleteAdmin(record.key);
          }}
        >
          {t('buttons.delete')}
        </Link>
      </Space>
    ),
  },
];
