import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Button, message, Popover } from 'antd';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BellOutlined, ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styles from './notificationsBox.module.css';
import { useUserContext } from '../../context/user.context';
import { getNotifications } from '../../services/services';
import { NotificationInterface } from '../../models/notificationInterface';
import config from '../../config';
import { NotificationContext } from '../../context/notification.context';
import { NotificationAction } from '../../consts/NotificationActionEnum';
import { NotificationModule } from '../../consts/NotificationModuleEnum';

const NotificationsBox = (): ReactElement => {
  const { updated, setUpdated } = useContext(NotificationContext);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { t } = useTranslation('common');
  const [notifications, setNotifications] = useState<NotificationInterface[]>();
  const [visibleNotifCount, setVisibleNotifCount] = useState<number>(5);
  const [openNotifations, setOpenNotifications] = useState<boolean>(false);

  useEffect(() => {
    const initData = async (): Promise<void> => {
      try {
        let data = await getNotifications();
        data = data.sort((a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1));
        setNotifications(data);
        setUpdated(true);
      } catch (e) {
        message.error(t('messages.errorTryLater'));
      }
    };

    initData();
  }, [setUpdated, t, updated, user.role]);

  const handleOpenChange = (newOpen: boolean): void => {
    if (!newOpen) setVisibleNotifCount(5);
    setOpenNotifications(newOpen);
  };

  const visibleNotifications = useMemo(() => {
    return notifications?.slice(0, visibleNotifCount);
  }, [visibleNotifCount, notifications]);

  const showShowMoreBtn = useMemo((): boolean => {
    if (notifications?.length === undefined) return false;
    if (notifications.length < visibleNotifCount) return false;
    return true;
  }, [notifications, visibleNotifCount]);

  const showMore = useCallback((): void => {
    setVisibleNotifCount(visibleNotifCount + 10);
  }, [visibleNotifCount]);

  const getNotificationText = (notification: NotificationInterface): string => {
    const { action, user } = notification;
    switch (action) {
      case NotificationAction.NEW_ARTICLE:
      case NotificationAction.NEW_INVOICE:
      case NotificationAction.INVOICE_REPORTED:
      case NotificationAction.INVOICE_DECLINED:
        return `${user.companyName} ${t(`messages.${action}`)}`;
    }

    return '';
  };

  const handleNotificationAction = (notification: NotificationInterface): void => {
    const { module, itemId } = notification;
    handleOpenChange(false);

    switch (module) {
      case NotificationModule.ARTICLE:
        navigate(config.routes.editArticle.replace(':id', itemId.toString()));
        break;
      case NotificationModule.INVOICE:
        navigate(config.routes.invoiceId.replace(':id', itemId.toString()));
        break;
    }
  };

  return (
    <Popover
      content={
        <div
          className={cx(styles.notificationsWrapper, visibleNotifCount > 5 && styles.scrollInBox)}
        >
          {visibleNotifications?.length ? (
            visibleNotifications?.map((notification) => (
              <div
                onClick={() => {
                  handleNotificationAction(notification);
                }}
                key={notification.id}
                className={styles.notification}
              >
                <div className={cx(notification.isAdminReaded === 9 && styles.notReadNotif)}>
                  {getNotificationText(notification)}
                </div>
                <div className={styles.notificationTime}>
                  <ClockCircleOutlined />{' '}
                  {dayjs(notification.createdAt).format(config.dateFormat.detailedDayMonthTime)}
                </div>
              </div>
            ))
          ) : (
            <>{t('misc.noNewNotifications')}</>
          )}
          {showShowMoreBtn && (
            <Button type='text' onClick={showMore} className={styles.showMoreBtn}>
              {t('buttons.showMore')}
            </Button>
          )}
        </div>
      }
      trigger='click'
      open={openNotifations}
      onOpenChange={handleOpenChange}
    >
      <Badge count={notifications?.length} offset={[0, -5]}>
        <BellOutlined />
      </Badge>
    </Popover>
  );
};

export default NotificationsBox;
