import React, { ReactElement, SetStateAction } from 'react';
import styles from './companyFilters.module.css';
import { CompaniesFilterParamsType, CompaniesServiceFilter } from '../types';
import NameFilter from '../filters/nameFilter';
import CountryFilter from '../filters/countryFilter';
import { useUserContext } from '../../../context/user.context';
import { UserRole } from '../../../consts/UserRoleEnum';
import StatusFilter from '../filters/statusFilter';
import config from '../../../config';
import ServicesFilter from '../filters/servicesFilter';

const CompanyFilters = ({
  setParams,
  setSearchTerm,
}: {
  setParams: React.Dispatch<React.SetStateAction<CompaniesFilterParamsType>>;
  setSearchTerm: React.Dispatch<SetStateAction<string | undefined>>;
}): ReactElement => {
  const { user } = useUserContext();

  const handleSearch = async (value: string | undefined): Promise<void> => {
    setSearchTerm(value);
  };

  const handleCountry = (country: string | undefined): void => {
    setParams((prevState) => {
      return {
        ...prevState,
        country,
      };
    });
  };

  const handleStatus = (status: string | undefined): void => {
    setParams((prevState) => {
      return {
        ...prevState,
        status,
      };
    });
  };

  const handleServices = (service: CompaniesServiceFilter | undefined): void => {
    if (service?.memberCategory) {
      setParams((prevState) => {
        return {
          ...prevState,
          memberCategory: service?.memberCategory,
          partnerCategory: undefined
        };
      });
      return;
    }
    setParams((prevState) => {
      return {
        ...prevState,
        partnerCategory: service?.partnerCategory,
        memberCategory: undefined
      };
    });
  };

  

  return (
    <div className={styles.root}>
      <NameFilter onChange={handleSearch} />
      <CountryFilter onChange={handleCountry} />
      {(user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) && (
        <StatusFilter onChange={handleStatus} />
      )}
      {config.features.nsrnCategories && <ServicesFilter onChange={handleServices} />}
    </div>
  );
};

export default CompanyFilters;
