import React, { ReactElement, ReactNode, useMemo } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './companyForm.module.css';
import cx from 'classnames';
import config from '../../config';
import TabKeys from '../../consts/CompanyFormTabsEnum';

interface TabWrapperType {
  children: ReactNode;
  activeKey: TabKeys;
  changeKey: (key: TabKeys) => void;
  last?: boolean;
  boxed?: boolean;
}

const TabWrapper = ({
  children,
  activeKey,
  changeKey,
  last,
  boxed,
}: TabWrapperType): ReactElement => {
  const { t } = useTranslation('common');

  const tabSequence = useMemo(() => {
    const sequence = [TabKeys.ACCOUNT, TabKeys.ABOUT];

    if (config.features.nsrnCategories) {
      sequence.push(TabKeys.SERVICES);
    }

    if (config.features.enableTeam) {
      sequence.push(TabKeys.TEAM);
    }

    sequence.push(TabKeys.CERTIFICATES, TabKeys.SUBMIT);

    return sequence;
  }, []);

  const currentIndex = tabSequence.indexOf(activeKey);

  const prevTab = (): void => {
    if (currentIndex > 0) {
      changeKey(tabSequence[currentIndex - 1]);
    }
  };

  const nextTab = (): void => {
    if (currentIndex < tabSequence.length - 1) {
      changeKey(tabSequence[currentIndex + 1]);
    }
  };

  return (
    <div className={cx(styles.tabWrapper, (boxed ?? false) && styles.boxed)}>
      <div>{children}</div>
      <div className={styles.tabsNavigation}>
        <Button icon={<LeftOutlined />} onClick={prevTab} disabled={currentIndex === 0}>
          {t('buttons.back')}
        </Button>
        {!(last ?? false) && (
          <Button type='primary' onClick={nextTab}>
            {t('buttons.next')} <RightOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};

export default TabWrapper;
