import { useTranslation } from 'react-i18next';
import { SelectProps, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  getCitiesByCounty,
  getCountiesByCountry,
  getCountries,
} from '../utils/getCountriesAndCities';

interface LoginHook {
  countries: SelectProps['options'];
  counties: SelectProps['options'];
  cities: SelectProps['options'];
  setCountry: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCounty: React.Dispatch<React.SetStateAction<string | undefined>>;
  loading: boolean;
}

interface AddressProps {
  initCountry?: string;
  initCounty?: string;
}

const useAddress = ({ initCountry, initCounty }: AddressProps): LoginHook => {
  const { t } = useTranslation('common');

  const [countries, setCountries] = useState<SelectProps['options']>([]);
  const [counties, setCounties] = useState<SelectProps['options']>([]);
  const [cities, setCities] = useState<SelectProps['options']>([]);

  const [selectedCountry, setCountry] = useState<string | undefined>(initCountry);
  const [selectedCounty, setCounty] = useState<string | undefined>(initCounty);

  const [loading, setLoading] = useState<boolean>(false);

  const getCountriesApi = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const data = await getCountries();
      setCountries(data);
      setLoading(false);
    } catch (e) {
      message.error(t('messages.errorTryLater'));
      setLoading(false);
    }
  }, [t]);

  useEffect(() => {
    getCountriesApi();
  }, [getCountriesApi]);

  const getCountiesApi = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      let data: SelectProps['options'] = [];
      if (selectedCountry) data = await getCountiesByCountry(selectedCountry);
      else if (initCountry) data = await getCountiesByCountry(initCountry);
      setCounties(data);
      setLoading(false);
    } catch (e) {
      message.error(t('messages.errorTryLater'));
      setLoading(false);
    }
  }, [initCountry, selectedCountry, t]);

  useEffect(() => {
    getCountiesApi();
  }, [getCountiesApi]);

  const getCitiesApi = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      let data: SelectProps['options'] = [];
      if (selectedCounty) data = await getCitiesByCounty(selectedCounty);
      else if (initCounty) data = await getCitiesByCounty(initCounty);
      setCities(data);
      setLoading(false);
    } catch (e) {
      message.error(t('messages.errorTryLater'));
      setLoading(false);
    }
  }, [initCounty, selectedCounty, t]);

  useEffect(() => {
    getCitiesApi();
  }, [getCitiesApi]);

  return {
    countries,
    counties,
    cities,
    setCountry,
    setCounty,
    loading,
  };
};

export default useAddress;
