import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInterface } from '../../../models/companyInterface';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';

const TerminalPorts = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const terminalPortsData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.terminalPortsParticularTypesOfCargo)
      prepareData.push({ name: t('misc.terminalPortsParticularTypesOfCargo'), content: company.terminalPortsParticularTypesOfCargo });

    if (company.terminalPortsUniqueSellingPoint)
      prepareData.push({ name: t('misc.terminalPortsUniqueSellingPoint'), content: company.terminalPortsUniqueSellingPoint });

    if (company.terminalPortsAvailableTransportLinks)
      prepareData.push({ name: t('misc.terminalPortsAvailableTransportLinks'), content: company.terminalPortsAvailableTransportLinks });

    if (company.terminalPortsWhenShouldAMemberContactYou)
      prepareData.push({ name: t('misc.terminalPortsWhenShouldAMemberContactYou'), content: company.terminalPortsWhenShouldAMemberContactYou });

    return prepareData;
  }, [company, t]);

  if (terminalPortsData.length === 0) return <></>;

  return <CustomTable data={terminalPortsData} />;
};

export default TerminalPorts;
