import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { getInvoices } from '../../services/services';
import {
  InvoiceEntity,
  InvoiceListResponse,
  InvoicesFilterParamsType,
  TableDataType,
} from './types';
import Filters from './filters/filters';
import useDebounce from '../../hooks/useDebounce';
import { getAdminColumns, getCompanyColumns } from './utils';
import DataTable from '../../components/DataTable/dataTable';
import { useUserContext } from '../../context/user.context';
import styles from './index.module.css';
import { UserRole } from '../../consts/UserRoleEnum';

enum InvoiceType {
  SENT = 'sent',
  RECEIVED = 'received',
}

const ListInvoices = (): ReactElement => {
  const { t } = useTranslation('common');
  const [invoices, setInvoices] = useState<InvoiceListResponse>();
  const [isReceived, setIsReceived] = useState<boolean>(true);
  const { user } = useUserContext();

  const [params, setParams] = useState<InvoicesFilterParamsType>({
    type: user.role === UserRole.MEMBER ? InvoiceType.RECEIVED : undefined,
  });

  const [searchTerm, setSearchTerm] = useState<string>();

  const debouncedSearch = useDebounce(searchTerm, 1000);

  const finalParams = useMemo((): InvoicesFilterParamsType => {
    return {
      ...params,
      searchTerm: debouncedSearch,
    };
  }, [debouncedSearch, params]);

  const fetchData = useCallback(async (): Promise<void> => {
    const invoicesData = await getInvoices(finalParams);
    setInvoices(invoicesData);
  }, [finalParams]);

  useEffect(() => {
    fetchData();
  }, [finalParams, fetchData]);

  const switchType = (type: string): void => {
    setInvoices(undefined);
    if (type === InvoiceType.RECEIVED) {
      setIsReceived(true);
      handleSetParams({ type: InvoiceType.RECEIVED });
      return;
    }
    setIsReceived(false);
    handleSetParams({ type: InvoiceType.SENT });
  };

  const handleSetParams = (newParams: InvoicesFilterParamsType): void => {
    const paramsData = { ...params, ...newParams };
    if (!newParams.page) {
      paramsData.page = 1;
    }
    setParams(paramsData);
  };

  const columns =
    user.role === UserRole.MEMBER
      ? getCompanyColumns({ t, receive: isReceived })
      : getAdminColumns({ t });

  const dataTable = useMemo((): TableDataType[] => {
    if (invoices?.items === undefined) return [];
    return invoices?.items.map((invoice: InvoiceEntity) => ({
      key: String(invoice.id),
      invoice: invoice.name.toString(),
      name: invoice.name,
      value: invoice.value,
      status: invoice.status,
      issuedDate: invoice.issuedDate,
      dueDate: invoice.dueDate,
      currency: invoice.currency,
      from: invoice.sender.companyName,
      to: invoice.receiver.companyName,
    }));
  }, [invoices?.items]);

  return (
    <div>
      {user.role === UserRole.MEMBER && (
        <div className={styles.switchInvoiceTable}>
          <Button
            onClick={() => {
              switchType(InvoiceType.RECEIVED);
            }}
            type={isReceived ? 'primary' : 'default'}
          >
            {t('buttons.received')}
          </Button>
          <Button
            onClick={() => {
              switchType(InvoiceType.SENT);
            }}
            type={!isReceived ? 'primary' : 'default'}
          >
            {t('buttons.sent')}
          </Button>
        </div>
      )}
      <Filters setParams={handleSetParams} setSearchTerm={setSearchTerm} />
      {invoices != null && dataTable != null && (
        <DataTable
          columns={columns}
          items={dataTable}
          total={invoices.meta.totalItems}
          page={invoices.meta.currentPage}
          setParams={handleSetParams}
          itemsPerPage={invoices.meta.itemsPerPage}
        />
      )}
    </div>
  );
};

export default ListInvoices;
