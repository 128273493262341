import { axiosInstance } from '../api/AxiosInstance';
import config from '../config';

export const editorImageUploadHandler = async (blobInfo: any): Promise<string> =>
  await new Promise<string>((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    axiosInstance
      .post(config.apiRoutes.uploadImage, formData, {
        headers: {
          ...{ 'Content-Type': 'multipart/form-data' },
        },
      })
      .then((res: any): void => {
        resolve(res.data.location as unknown as string);
      })
      .catch((e): void => {
        console.log(e);
      });
  });

export const stripTagsAttrs = (input: string): string => {
  const singleQuoteDetection = "='";
  const doubleQuoteDetection = '="';
  let output = input
    .replaceAll('<p style="text-align: left;">', '<p align="left">')
    .replaceAll('<p style="text-align: center;">', '<p align="center">')
    .replaceAll('<p style="text-align: right;">', '<p align="right">')
    .replaceAll('style="display: block; margin-left: auto; margin-right: auto;"', 'align="center"')
    .replaceAll('style="float: right;"', 'align="right"')
    .replaceAll('style="float: left;"', 'align="left"')
    .replaceAll(
      'style="display: table; margin-left: auto; margin-right: auto;"',
      'align-iframe="center"',
    )
    .replaceAll('class="button"', 'classAllowed="button"');
  if (output.includes(singleQuoteDetection)) {
    output = output.replace(/(style|lang|class)='[^"]*'/g, '');
  }
  if (output.includes(doubleQuoteDetection)) {
    output = output.replace(/(style|lang|class)="[^"]*"/g, '');
  }
  output = output
    .replaceAll('<p align="left">', '<p style="text-align: left;">')
    .replaceAll('<p align="center">', '<p style="text-align: center;">')
    .replaceAll('<p align="right">', '<p style="text-align: right;">')
    .replaceAll('align="center"', 'style="display: block; margin-left: auto; margin-right: auto;"')
    .replaceAll('align="right"', 'style="float: right;"')
    .replaceAll('align="left"', 'style="float: left;"')
    .replaceAll(
      'align-iframe="center"',
      'style="display: table; margin-left: auto; margin-right: auto;"',
    )
    .replaceAll('classAllowed="button"', 'class="button"');
  return output;
};

export const tabToSpace = (text: string): string => {
  if (!text) {
    return '';
  }

  return text.replace(/\t/g, ' ').replace(/  +/g, ' ');
};

export const stripTags = (input: string, allowed: string): string => {
  allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) ?? []).join('');
  const tags = /<\/?([a-z][a-z0-9])\b[^>]>/gi;
  const comments = /<!--[\s\S]*?-->/gi;

  return input.replace(comments, '').replace(tags, function ($0, $1) {
    return allowed.includes(`<${$1.toLowerCase()}>`) ? $0 : '';
  });
};

export const editorAllowedTags =
  '<img><span><iframe><embed><br><div><a><h1><h2><h3><h4><h5><h6><b><strong><i><u><ol><ul><li><table><tbody><thead><tr><td><p>';
