import React, { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { UserInterface } from '../models/userInterface';
import { LocalStorageEnum } from '../consts/LocalStorageEnum';

interface UserContextType {
  setUser: React.Dispatch<React.SetStateAction<UserInterface>>;
  user: UserInterface;
}

const defaultValue = {};
export const UserContext = React.createContext<UserContextType>(defaultValue as UserContextType);

export const UserProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [user, setUser] = useState<UserInterface>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const accessToken = localStorage.getItem(LocalStorageEnum.ACCESS_TOKEN);
    if (typeof accessToken === 'string' && accessToken !== '') {
      const decodedToken: UserInterface = jwt_decode(accessToken);
      setUser({
        role: decodedToken.role,
        name: decodedToken.name,
        username: decodedToken.username,
        id: decodedToken.sub,
      });
    }
    setLoading(false);
  }, []);

  const contextValues: UserContextType = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );
  if (loading) return <></>;
  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  return useContext(UserContext);
};
