import React, { ReactElement } from 'react';
import { CustomTableInterface } from '../../models/customTableInterface';
import styles from './customTable.module.css';

const CustomTable = ({ data }: { data: CustomTableInterface[] }): ReactElement => {
  return (
    <div className={styles.root}>
      {data.length > 0 &&
        data.map((element) => {
          return (
            <div key={element.name} className={styles.row}>
              <div className={styles.cell}>{element.name}</div>
              <div className={styles.cell}>{element.content}</div>
            </div>
          );
        })}
    </div>
  );
};

export default CustomTable;
