import React, { ReactElement } from 'react';
import { Header } from 'antd/es/layout/layout';
import cx from 'classnames';
import { UserOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './headerBar.module.css';
import { useUserContext } from '../../context/user.context';
import NotificationsBox from '../notificationsBox/notificationsBox';
import Logo from '../logo/logo';
import useResponsiveLayout from '../../hooks/useResponsiveLayout';

const HeaderBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const { user } = useUserContext();
  const { isMobileLayout } = useResponsiveLayout();

  return (
    <div className={cx(styles.root, isMobileLayout && styles.mobile)}>
      <Header className={styles.header}>
        {isMobileLayout ? (
          <div className={styles.logo}>
            <Logo width={140} />
          </div>
        ) : (
          <div />
        )}
        <div className={styles.rightElements}>
          <div className={styles.topElement}>
            <div className={styles.iconWrapper}>
              <NotificationsBox />
            </div>
          </div>
          <div className={cx(styles.topElement, styles.userElement)}>
            <div className={styles.userIcon}>
              <UserOutlined />
            </div>
            {user.username}
          </div>
          {isMobileLayout && (
            <div className={styles.topElement}>
              {React.createElement(collapsed ? MenuOutlined : CloseOutlined, {
                className: 'trigger',
                onClick: () => {
                  setCollapsed(!collapsed);
                },
              })}
            </div>
          )}
        </div>
      </Header>
    </div>
  );
};

export default HeaderBar;
