import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Space } from 'antd';
import dayjs from 'dayjs';
import styles from './reviews.module.css';
import { ReviewInterface } from './types';
import config from '../../../config';

const ListReviews = ({ reviews }: { reviews: ReviewInterface[] }): ReactElement => {
  const { t } = useTranslation('common');

  const beautifyDate = (date: string): string => {
    const diffenceDays = dayjs().diff(date, 'day');
    if (diffenceDays === 0) return t('timeAgo.today');
    if (diffenceDays === -1) return t('timeAgo.oneDayAgo');
    if (diffenceDays >= -7 && diffenceDays < 0) {
      const days = String(diffenceDays).replace('-', '');
      return t('timeAgo.daysAgo').replace(':days', days);
    }
    return dayjs(date).format(config.dateFormat.detailedMonth);
  };

  return (
    <Space direction='vertical' className={styles.reviewsRoot}>
      {reviews.map((review: ReviewInterface) => (
        <Card key={review.id} className={styles.review}>
          <Space direction='vertical'>
            <Space direction='horizontal'>
              <div className={styles.author}>{review.author.primaryContact}</div>
              <div className={styles.date}>{beautifyDate(review.createdAt)}</div>
            </Space>
            <div className={styles.content}>{review.content}</div>
          </Space>
        </Card>
      ))}
    </Space>
  );
};

export default ListReviews;
