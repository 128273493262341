import React, { ReactElement } from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  DeploymentUnitOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './formStyles.module.css';
import config from '../../../config';
import useCompanyForm from '../../../hooks/useCompanyForm';

const FormTeam = (): ReactElement => {
  const { t } = useTranslation('common');
  const { phoneNumberValidator } = useCompanyForm({});

  return (
    <div>
      <Form.List name='contacts'>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={20} className={styles.membersWrap}>
              {fields.map(({ key, name, ...field }) => (
                <React.Fragment key={key}>
                  <Col xs={24} md={12}>
                    <Card className={styles.memberCard}>
                      <Form.Item
                        {...field}
                        name={[name, 'name']}
                        rules={[
                          {
                            required: true,
                            message: t('messages.requiredField') ?? '',
                          },
                        ]}
                        className={styles.teamInput}
                      >
                        <Input placeholder={t('misc.name') + '*'} addonBefore={<UserOutlined />} />
                      </Form.Item>
                      <Form.Item {...field} name={[name, 'position']} className={styles.teamInput}>
                        <Input
                          placeholder={t('misc.position') ?? ''}
                          addonBefore={<DeploymentUnitOutlined />}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[name, 'email']}
                        rules={[
                          {
                            type: 'email',
                            message: t('messages.emailValidation') ?? '',
                          },
                        ]}
                        className={styles.teamInput}
                      >
                        <Input placeholder={t('misc.email') ?? ''} addonBefore={<MailOutlined />} />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[name, 'phoneNo']}
                        className={styles.teamInput}
                        rules={[
                          {
                            validator: phoneNumberValidator,
                          },
                        ]}
                      >
                        <Input
                          placeholder={t('misc.phoneNo') ?? ''}
                          addonBefore={<PhoneOutlined />}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[name, 'phoneNo2']}
                        className={styles.teamInput}
                        rules={[
                          {
                            validator: phoneNumberValidator,
                          },
                        ]}
                      >
                        <Input
                          placeholder={t('misc.phoneNo2') ?? ''}
                          addonBefore={<PhoneOutlined />}
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[name, 'linkedin']}
                        className={styles.teamInput}
                        rules={[
                          {
                            type: 'url',
                            message: t('messages.invalidUrl') ?? '',
                          },
                        ]}
                      >
                        <Input placeholder={t('misc.linkedin') ?? ''} addonBefore='in' />
                      </Form.Item>
                      <Button
                        icon={<MinusCircleOutlined />}
                        onClick={() => {
                          remove(name);
                        }}
                      >
                        Remove
                      </Button>
                    </Card>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
            {fields.length < config.limits.maxContacts ? (
              <Form.Item>
                <Button
                  type='primary'
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  {t('buttons.addNewContact')}
                </Button>
              </Form.Item>
            ) : (
              <p>
                <InfoCircleOutlined />{' '}
                {t('messages.maxContactsAllowed').replace(':nb', String(config.limits.maxContacts))}
              </p>
            )}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default FormTeam;
