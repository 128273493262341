import React, { ReactElement } from 'react';
import ArticleForm from './components/articleForm';
import PageTitle from '../../components/pageTitle/pageTitle';
import { ArticleFormInterface } from './types';
import { UserRole } from '../../consts/UserRoleEnum';
import { useUserContext } from '../../context/user.context';
import { ItemPublished } from '../../consts/ItemPublishedEnum';

const AddArticle = (): ReactElement => {
  const { user } = useUserContext();
  const initialValues: ArticleFormInterface = {
    name: '',
    postSummary: '',
    body: '',
    photos: [],
    published:
      user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN
        ? ItemPublished.TRUE
        : ItemPublished.FALSE,
  };

  return (
    <div>
      <PageTitle title={'Add article'} />
      <ArticleForm data={initialValues} />
    </div>
  );
};

export default AddArticle;
