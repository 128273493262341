import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import styles from './filters.module.css';
import FilterElementWrap from '../../../components/Filters/filterElementWrap';
import { getMemberCategoriesLabelsEnum, getPartnerCategoriesLabelsEnum } from '../../../utils/utils';
import { CompaniesServiceFilter } from '../types';

const ServicesFilter = ({
  onChange,
}: {
  onChange: (services: CompaniesServiceFilter | undefined ) => void;
}): ReactElement => {
  const { t } = useTranslation('common');

  const partnerCategories = getPartnerCategoriesLabelsEnum(t);
  const partnerCategoriesOptions = Object.keys(partnerCategories).map((category) => ({
    label: partnerCategories[category],
    value: category,
  }));

  const memberCategories = getMemberCategoriesLabelsEnum(t);
  const memberCategoriesOptions = Object.keys(memberCategories).map((category) => ({
    label: memberCategories[category],
    value: category,
  }));

  const handleChange = (value: string): void => {
    if(memberCategoriesOptions.find((categ) => categ.value === value))
      onChange({memberCategory: value})
    if(partnerCategoriesOptions.find((categ) => categ.value === value))
      onChange({partnerCategory: value})
  };

  const clear = (): void => {
    onChange(undefined);
  };

  return (
    <FilterElementWrap>
      <Select
        allowClear={true}
        onClear={clear}
        onSelect={handleChange}
        placeholder={t('misc.services')}
        className={styles.fullWidth}
        options={[
          {
            label: t('companyCategories.member'),
            options: memberCategoriesOptions,
          },
          {
            label: t('companyCategories.partner'),
            options: partnerCategoriesOptions,
          },
        ]}
      />
    </FilterElementWrap>
  );
};

export default ServicesFilter;
