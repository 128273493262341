import React, { ReactElement, useMemo, useState, useCallback } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InvoiceInitialValues, ModalInfoType } from '../../models/invoiceInterface';
import styles from './index.module.css';
import { useUserContext } from '../../context/user.context';
import ModalForm from './modalForm';
import { InvoiceStatus } from '../invoices/invoiceStatus';
import { UserRole } from '../../consts/UserRoleEnum';
import { axiosInstance } from '../../api/AxiosInstance';
import config from '../../config';

const InvoiceActions = ({ invoiceData }: { invoiceData: InvoiceInitialValues }): ReactElement => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState<'decline' | 'report'>('decline');

  const showModal = (type: 'decline' | 'report'): void => {
    setModalType(type);
    setOpen(true);
  };

  const modalInfo = useMemo((): ModalInfoType => {
    if (modalType === 'decline')
      return {
        title: t('buttons.declineInvoice'),
        label: t('messages.explainDecline'),
        placeholder: t('messages.whyDecline'),
      };
    if (modalType === 'report')
      return {
        title: t('buttons.reportInvoice'),
        label: t('messages.explainReport'),
        placeholder: t('messages.whyReport'),
      };
    return {};
  }, [modalType, t]);

  const markAsPaid = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        config.apiRoutes.pay.replace(':id', String(invoiceData.id)),
      );

      if (response.status === 201 || response.status === 200) {
        message.success(t('messages.invoiceMarkedAsPaid'));
        navigate(config.routes.allInvoices);
      }
    } catch (error) {
      message.error(t('messages.errorTryLater'));
    }
  }, [invoiceData, t, navigate]);

  const markAsResolved = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        config.apiRoutes.resolve.replace(':id', String(invoiceData.id)),
      );

      if (response.status === 201 || response.status === 200) {
        message.success(t('messages.invoiceMarkedAsResolved'));
        navigate(config.routes.allInvoices);
      }
    } catch (error) {
      message.error(t('messages.errorTryLater'));
    }
  }, [invoiceData, t, navigate]);

  const hideModal = (): void => {
    setOpen(false);
  };

  const showMarkAsPAidButton = useMemo((): boolean => {
    if (invoiceData.clientId === user.id) return false;
    if (invoiceData.status === InvoiceStatus.DECLINED) return false;
    if (invoiceData.status === InvoiceStatus.ISSUE_RESOLVED) return false;
    if (invoiceData.status === InvoiceStatus.PAID) return false;
    return true;
  }, [invoiceData, user]);

  const showDeclineButton = useMemo((): boolean => {
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) return false;
    if (user.id !== invoiceData.clientId) return false;
    if (invoiceData.status === InvoiceStatus.ISSUE_RESOLVED) return false;
    if (invoiceData.status === InvoiceStatus.REPORTED) return false;
    if (invoiceData.status === InvoiceStatus.DECLINED) return false;
    if (invoiceData.status === InvoiceStatus.PAID) return false;
    return true;
  }, [invoiceData, user]);

  const showReportButton = useMemo((): boolean => {
    if (user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN) return false;
    if (invoiceData.status === InvoiceStatus.ISSUE_RESOLVED) return false;
    if (invoiceData.status === InvoiceStatus.REPORTED) return false;
    if (invoiceData.status === InvoiceStatus.DECLINED) return false;
    if (invoiceData.status === InvoiceStatus.PAID) return false;
    return true;
  }, [invoiceData, user]);

  const showMarkAsIssueResolved = useMemo((): boolean => {
    if (user.role !== UserRole.ADMIN && user.role !== UserRole.SUPER_ADMIN) return false;
    if (invoiceData.status !== InvoiceStatus.REPORTED) return false;
    return true;
  }, [invoiceData, user]);

  return (
    <div className={styles.actionsWrap}>
      {showMarkAsIssueResolved && (
        <Button icon={<IssuesCloseOutlined />} type='primary' onClick={markAsResolved}>
          {t('buttons.markAsResolved')}
        </Button>
      )}
      {showMarkAsPAidButton && (
        <Button
          icon={<CheckOutlined />}
          type={invoiceData.status === InvoiceStatus.REPORTED ? 'default' : 'primary'}
          onClick={markAsPaid}
        >
          {t('buttons.markAsPaid')}
        </Button>
      )}
      {showDeclineButton && (
        <Button
          icon={<CloseOutlined />}
          onClick={() => {
            showModal('decline');
          }}
        >
          {t('buttons.declineInvoice')}
        </Button>
      )}
      {showReportButton && (
        <Button
          icon={<WarningOutlined />}
          onClick={() => {
            showModal('report');
          }}
        >
          {t('buttons.reportInvoice')}
        </Button>
      )}

      <Modal
        title={modalInfo.title}
        open={open}
        onCancel={hideModal}
        okText={t('buttons.submit')}
        footer={null}
      >
        <ModalForm
          modalType={modalType}
          setOpen={setOpen}
          modalInfo={modalInfo}
          id={invoiceData.id}
        />
      </Modal>
    </div>
  );
};

export default InvoiceActions;
