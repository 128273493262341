import React, { ReactElement, useEffect, useState } from 'react';
import { message } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import EventForm from './components/eventForm';
import PageTitle from '../../components/pageTitle/pageTitle';
import { useParams } from 'react-router-dom';
import { getEventById } from '../../services/services';
import { EventEntity } from './types';
import config from '../../config';

const EditEvent = (): ReactElement => {
  const [event, setEvent] = useState<EventEntity | null>(null);
  const { id } = useParams();
  const { t } = useTranslation('common');

  useEffect(() => {
    const initData = async (): Promise<void> => {
      try {
        const data = await getEventById(id as string);
        data.date = dayjs(data.date, config.dateFormat.universalFormat);
        data.dateFinal = dayjs(data.dateFinal, config.dateFormat.universalFormat);

        setEvent(data);
      } catch (e) {
        message.error(t('messages.errorTryLater'));
      }
    };

    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PageTitle title={'Edit event'} />
      {event && <EventForm data={event} />}
    </div>
  );
};

export default EditEvent;
