import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import styles from './filters.module.css';
import { UserStatus } from '../../../consts/UserStatus';
import FilterElementWrap from '../../../components/Filters/filterElementWrap';

const StatusFilter = ({
  onChange,
}: {
  onChange: (value: string | undefined) => void;
}): ReactElement => {
  const { t } = useTranslation('common');

  const handleCategory = (userCategory: string): void => {
    onChange(userCategory);
  };

  const clearCategory = (): void => {
    onChange(undefined);
  };

  return (
    <FilterElementWrap>
      <Select
        allowClear={true}
        onClear={clearCategory}
        onSelect={handleCategory}
        placeholder={t('misc.status')}
        className={styles.fullWidth}
        options={[
          {
            label: t('status.active'),
            value: UserStatus.ENABLED,
          },
          {
            label: t('status.hidden'),
            value: UserStatus.HIDDEN,
          },
          {
            label: t('status.archived'),
            value: UserStatus.ARCHIVED,
          },
        ]}
      />
    </FilterElementWrap>
  );
};

export default StatusFilter;
